import React from 'react'
import styled from 'styled-components'
import { Card, CardBody, CardText } from "reactstrap";

const CardDiv = styled.div`
  margin-top: 20px;
  display: flex;
  /* flex-direction: column; */
  height: 100%;
`

const StoryImage = styled.div`
  iframe{
    width: 100%;
    height: 300px;
    /* object-fit: cover; */
    margin: 0;  
    border-radius: 5px 5px 0 0;
  }
  img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin: 0;
    /* padding: 4px */
    /* height: 250px; */
    /* display: block; */
    /* margin: 0 auto; */
    border-radius: 5px 5px 0 0;
  }
  p{
    font-size: 16px;
    font-weight: bold;
    /* text-align: center; */
  }
`
const StoryContent = styled(CardBody)`
  display: flex;
  flex-direction: column;
  position: relative;
  /* min-height: 250px; */
  .storytitle{
    color: #235551;
    margin-bottom:10px;
    h6{
      overflow: hidden;
      min-height: 50px;
      margin: 0;
    }
  }
  .storydesc{
    min-height: 50px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 0px;
    box-sizing: border-box;
    white-space: pre-line;
  }
  .storylink{
    color: #235551;
    position:absolute;
    bottom:0
  }
  .storylink:hover{
    color: #17a99e;
    text-decoration: none;
  }
`

const CardSimple = ({ children, className, image, imageURL, title, description, justifytext, centertext}) => {
  return (
    <CardDiv>
    <Card className={className} style={{border: "none", marginBottom: "30px"}}>
      <StoryImage>
        {imageURL ? <img src={imageURL}/> : "" }
        {image}
      </StoryImage>
        <StoryContent style={{flex : 1}}>
          <div className="storytitle">
          <h6 className={CardText}>
              {title}
          </h6>
          </div>
          <p>
            {justifytext ? 
            <span className="storydesc" style={{textAlign:"justify", textJustify:"inter-none"}}> 
            {description}
            </span>
            :
            centertext ?  
            <span className="storydesc" style={{textAlign:"center"}}>
            {description}
            </span>
            :
            <span className="storydesc">
            {description}
            </span>
            }
          </p>
          {children}
        </StoryContent>
    </Card>
    </CardDiv>
  )
}

export default CardSimple

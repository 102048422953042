import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TrainingAMBody from "../components/AMTrainingBody"

import Banner from "../images/training/AMModules/AMOperator/01BANNER-10.png";
import icon from "../images/training/AMModules/AMOperator/ICONIAMQS-02.png";
import image from "../images/training/AMModules/AMOperator/img03.jpg";

const titleBody = ( <h2> INTERNATIONAL OPERATOR  </h2> )
const longtitleBody = ( <p>OPERATOR POWDER BED FUSION LASER BEAM PERSONNEL (I MAM 0-PBF)</p>)
const titleDescBody = ( <p> Focused on the practical skills snd knowledge required to us and apply Metal AM Process, this qualification will provide you with the skills to become a top Operator in this thriving community. Definitely one qualification that will reap dividends throughout your career. </p> )
const insightdetails = [
  "Operate Additive Manufacturing Machines including; fitting, setting up, maintenance and repair",
  "Self-Manage the handling of feestock (approval, storage, contamination, tracebility)",
  "Develop solutions on basic and process specific problems related with AM Machines and Processes."
]
const groupdetails = ( 
  <p>
    These Training programmes address professionals having no experience, knowledge, skills and competence in the fields of AM or candidates having experience but willing to upgrade their professional skills. These qualification address personnel who needs to independently operate AM Machines, following working instructions
  </p>
)
const benefitdetails = [
  "Up-to-date Knowledge, skills and competences regarding Additive Manufacturing Process",
  "Gaining a formal recognition of a qualification to act in the field of Metal Additive Manufacturing using a specific process",
  "An international recognised qualification which increases employability in the labour market, through mobility and skills transparency",
]


const TrainingAMOperatorPage = () => (
  <Layout>
    <SEO title="AM Operator" description="AM Operator (I MAM 0-PBF)" />
    
    <TrainingAMBody
      Banner={Banner}
      Title={titleBody}
      TitleDesc={titleDescBody}
      LongTitle={longtitleBody}
      PositionDetails="These training programmes are ideal for engineers who are willing to specialise and pursue a career in AM, with focus on designing metal AM parts for different processes"
      PositionDetails2="Metal AM Operators are the professionals with the specific knowledge, skills, autonomy and responsibility to operate metal Additive Manufacturing machines"
      PositionImg={image}
      PositionIcon={icon}
      InsightDetails={insightdetails}
      GroupDetails={groupdetails}
      BenefitsDetails={benefitdetails}
    />

  </Layout>
)

export default TrainingAMOperatorPage

import React from "react"
import { Container, Row, Col } from "reactstrap";
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import styled from "styled-components";
import { FaFileUpload, FaArrowRight, FaInfo } from "react-icons/fa";

import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkhrefButtonSecondary from "../components/buttons/LinkhrefButtonSecondary";

import bgimage from "../images/background2.jpg";
import floor from "../images/memphis.png";


const Section = styled.section`
  padding: 60px 0;
  h3{
    color: #17a99e;
  }
  p{
    color: #fff;
  }
`
const UploadBox = styled.div`
  width: 100%;
  height: 600px;
  border: 1px solid #777;
  position: relative;
`

const Icon = styled.div`
  position: absolute;
  display: block;
  left: 45%;
  top: 40%;
`
const ProtocolContent = styled.div`
  p{
    color: black;
  }
  b{
    color: #d02229;
  }
  ol{
    padding: 10px;
  }
  .linkGuide{
    font-weight: bold;
  }
  .info-color{
    color: #0000FF;
  }
`
const TableGuide = styled.table`
  margin: 10px auto;
  width: 80%;
  text-align: center;
  border: #ccc 1px solid;
  th{
    border: #ccc 1px solid;
    background-color: #17a99e;
  }
  td{
    border: #ccc 1px solid;
    text-align: center;
  }
`

const MedicalUploadPage = () => (
  <Layout>
    <SEO title="MedicalUpload" />
    <Section style={{backgroundImage: `url(${floor})`, backgroundPosition: 'center', backgroundRepeat: 'repeat'}}>

    <Container>
      <div style={{backgroundColor: "#fff", padding: "10px"}}>
      <ProtocolContent>

      <h3>
        Implant CT Scan Upload
      </h3>

      <p><b> ! IMPORTANT ! </b> Before proceeding to upload please ensure for file is according to our Protocol for better quality and accuracy.</p>
      <h5>
        PROTOCOL FOR IMAGE ACQUISITION
      </h5>
      <p>
        The quality and accuracy of the final 3D printed object depends upon the data obtained by CT scan or MRI. <br/>
        To achieve the greatest accuracy in producing real structures, it is necessary to adhere to the following protocol:<br/>
      </p>
      <ol>
        <li>The radiographic examination must be uploaded to our ftp site, in DICOM format</li>
        <li>Images should contain the entire volume of this scan</li>
        <li>To ensure reproduction of the area of interest, there must be a minimum margin of 5 mm (top and bottom) around the area to be printed.</li>
        <li>All slices must have the same field of view, the same reconstruction center and same table height.</li>
        <li>Original images should be provided <u>without</u> reformatting.</li>
        <li>The patient must remain still during the scan. If the patient moves during the scan, the scan should be repeated</li>
        <li>Do not use cone-beam CT (as the contrast is insufficient).</li>
        <li>To produce segmented images of the heart, is necessary to use contrast medium in all cavities.</li>
      </ol>

      <TableGuide>
        <tr><th className="titletable" colSpan="3">Anatomical Parameters</th></tr>
        <tr>
          <th>Anatomy</th>
          <th>Slice Thickness</th>
          <th>Distance</th>
        </tr>
        <tr>
          <td>Face</td>
          <td>0.5 mm (or less)</td>
          <td>0.4</td>
        </tr>
        <tr>
          <td>Heart</td>
          <td>1.0 mm (or less)</td>
          <td>0.6</td>
        </tr>
        <tr>
          <td>Spine</td>
          <td>1.0 mm (or less)</td>
          <td>0.6</td>
        </tr>
        <tr>
          <td>Skull</td>
          <td>1.0 mm (or less)</td>
          <td>0.8</td>
        </tr>
        <tr>
          <td>Other Structures</td>
          <td>1.0 mm (or less)</td>
          <td>0.8</td>
        </tr>
      </TableGuide>

      <p>For Guideline on how to upload please refer the following <a className="linkGuide" href="/documents/General-guideline-to-upload-data-to-server.pdf" download="General guideline to upload data to server.pdf">Guide</a></p>
      
      <p><b className="info-color"> ! Info ! </b> Please Contact us at <u>+60378327140</u> or email us at <u>info@3dgens.com</u> to create an Account</p>

      <p style={{color: "#000"}}>For Help, Contact us at +60378327140</p>
      <LinkhrefButtonSecondary href="http://server.3dgens.com">Proceed <FaArrowRight/></LinkhrefButtonSecondary>

      </ProtocolContent>
      </div>

    </Container>
    </Section>
  </Layout>
)

export default MedicalUploadPage
import React from 'react'
import styled from 'styled-components'

const Link = styled.a`
    /* width: fit-content; */
    text-align: center;
    font-size: 16px;
    font-weight: normal;
    color: #fff;
    background-color: #235551;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;

  &:hover,
  &:active {
    background-color: #17a99e;
    text-decoration:none;
    color: #000;
  }

`

const LinkhrefButtonSecondary = ({ children, className, href, style}) => {
  return (
    <Link className={className} href={href} target="_blank" rel="noopener noreferrer" style={{style}}>
          {children}
    </Link>
  )
}

export default LinkhrefButtonSecondary

import React from "react"
import { Container, Row, Col } from "reactstrap";
import { Button } from "reactstrap";
import styled from "styled-components";
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Banner";
import Section from "../components/Section";
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";
import LinkhrefButtonSecondary from "../components/buttons/LinkhrefButtonSecondary";
import BackgroundSection from "../components/BackgroundSection";
import CardSimple from "../components/CardSimple";
import CardBorder from "../components/CardBorder";

import { FaArrowRight, FaYenSign } from "react-icons/fa";
import LinkPlain from "../components/buttons/LinkPlain";

import Popup from "reactjs-popup"

import print from "../images/products/3dPrinting.jpg";
import case1 from "../images/case01.jpg";
import case2 from "../images/case02.jpg";
import case3 from "../images/case03.jpg";
import aligner1 from "../images/products/aligners01.jpg"
import aligner2 from "../images/products/aligners02.jpg"
import veneer1 from "../images/veneer1.jpg";
import veneer2 from "../images/veneer2.jpg";
import veneer3 from "../images/veneer3.jpg";
import design1 from "../images/design01.png";
import design2 from "../images/design02.png";
import design3 from "../images/design03.png";
import design4 from "../images/design04.png";
import design5 from "../images/design05.png";
import design6 from "../images/design06.png";
import ceramic1 from "../images/ceramic01.jpg";
import ceramic2 from "../images/ceramic02.jpg";
import ceramic3 from "../images/ceramic03.jpg";
import ceramic4 from "../images/ceramic04.jpg";
import ceramic5 from "../images/ceramic05.jpg";
import ceramic6 from "../images/ceramic06.jpg";
import ceramic7 from "../images/ceramic07.jpg";
import ceramic8 from "../images/ceramic08.jpg";
import ceramic9 from "../images/ceramic09.jpg";
import implant1 from "../images/implant01.jpg";
import implant2 from "../images/implant02.jpg";
import implant3 from "../images/implant03.jpg";
import partial1 from "../images/partial01.jpg";
import partial2 from "../images/partial02.jpg";
import partial3 from "../images/partial03.jpg";
import ortho1 from "../images/orthodontics01.jpg";
import ortho2 from "../images/orthodontics02.jpg";
import ortho3 from "../images/orthodontics03.jpg";
import ortho4 from "../images/orthodontics04.jpg";
import ortho5 from "../images/orthodontics05.jpg";
import ortho6 from "../images/orthodontics06.jpg";
import device1 from "../images/device01.jpg";
import device2 from "../images/device02.jpg";
import device3 from "../images/device03.jpg";
import guard1 from "../images/guard01.jpg";
import guard2 from "../images/guard02.jpg";
import guard3 from "../images/guard03.jpg";
import metalframe1 from "../images/metal-frame06.jpg";
import metalframe2 from "../images/metal-frame07.jpg";
import metalframe3 from "../images/metal-frame08.jpg";
import metalframe4 from "../images/metal-frame09.jpg";
import metalframe5 from "../images/metal-frame10.jpg";
import acrylic1 from "../images/acrylic01.jpg";
import acrylic2 from "../images/acrylic02.jpg";
import acrylic3 from "../images/acrylic03.jpg";
import attachment1 from "../images/attachment01.jpg";
import attachment2 from "../images/attachment02.jpg";
import attachment3 from "../images/attachment03.jpg";
import pfm1 from "../images/PFM01.jpg";
import pfm2 from "../images/PFM02.jpg";
import pfm3 from "../images/PFM03.jpg";
import model1 from "../images/3dmodel01.jpg";
import model2 from "../images/3dmodel02.jpg";
import model3 from "../images/3dmodel03.jpg";
import model4 from "../images/3dmodel04.jpg";
import model5 from "../images/3dmodel05.jpg";
import model6 from "../images/3dmodel06.jpg";
import model7 from "../images/3dmodel07.jpg";
import dentistbanner from "../images/dentistbanner.jpg";
import bannerdentist from "../images/banner/page-banner/banner-dentists.png";
import digitaldentistry from "../images/digital-dentistry.png";
import comingsoon from "../images/comingsoon.jpg"
import LinkhrefButtonPrimary from "../components/buttons/LinkhrefButtonPrimary";
import MarkdownIt from "markdown-it";




const DentalServices = [
  {
    name : "Digital Case",
    desc : <>
      Making business easier<br/>
      intraoral scan files are accepted to make restorations<br/>
      Simply send your file and instructions, the case will be finished and sent to your<br/>
      Save impression, shipping cost and time.<br/>
    </>,
    images : [
      {
        image: case1,
        title: "",
      }, 
      {
        image: case2,
        title: "",
      }, 
      {
        image: case3,
        title: "",
      }
    ]
  },
  {
    name : "Veneer",
    desc : <>Ultra thin Veneer with no preparation</>,
    images : [
      {
        image: veneer1,
        title: "",
      }, 
      {
        image: veneer2,
        title: "",
      },
      {
        image: veneer3,
        title: "",
      }
    ]
  },
  {
    name : "Lucid Clear Aligner",
    desc : <>
      Simple steps in achieving a good teeth<br/>
      <ol>
        <li>Submit files and photos</li>
        <li>Treatment Plan</li>
        <li>Treatment Confirmation</li>
        <li>Production</li>
        <li>Delivery</li>
      </ol>
      <p>Visit <a href="https://lucid.my/" target="_blank" rel="noopener noreferrer">Lucid.my</a> for more information on the product</p>
    </>,
    images : [
      {
        image: aligner2,
        title: "Lucid clear aligner",
      }, 
      {
        image: aligner1,
        title: "Lucid clear aligner",
      }
    ],
    action : 
    <>
      <p></p>
      <div style={{display:"flex", justifyContent:"center"}}>
        <div style={{width: "fit-content", padding:"0 5px"}}>
          <LinkhrefButtonSecondary href="/documents/OrderForm-LUCID.pdf" download="General guideline to upload data to server.pdf">Download Order Form <FaArrowRight/></LinkhrefButtonSecondary>
        </div>
        <div style={{width: "fit-content", padding:"0 5px"}}>
          <LinkhrefButtonPrimary href="https://lucid.my/">Visit Lucid Website <FaArrowRight/></LinkhrefButtonPrimary>
        </div>
      </div>
      <p></p>
    </>
  },
  {
    name : "Digital Design",
    desc : <>
      We design while you sleep<br/>
      Over-night service<br/>
      Submit stl files when get off work and find the designed work in your box on the next day<br/>
      <ul>
      </ul>
    </>,
    images : [
      {
        image: design1,
        title: "Crown",
      }, 
      {
        image: design2,
        title: "Custom Abutment",
      },
      {
        image: design3,
        title: "Partial",
      },
      {
        image: design4,
        title: "Porcelain Cutbacks",
      },
      {
        image: design5,
        title: "Models",
      },
      {
        image: design6,
        title: "Porcelain Cutbacks",
      },
    ]
  },
  {
    disable : "yes",
    name : "All Ceramic",
    desc : <>
      <ul>
      </ul>
    </>,
    images : [
      {
        image: ceramic1,
        title: "Zirconia",
      }, 
      {
        image: ceramic2,
        title: "Porcelain Layered Zirconia",
      },
      {
        image: ceramic3,
        title: "E.max Veneer",
      },
      {
        image: ceramic4,
        title: "E.max Inlay",
      },
      {
        image: ceramic5,
        title: "Full Zirconia",
      },
      {
        image: ceramic6,
        title: "Full Zirconia",
      },
      {
        image: ceramic7,
        title: "Porcelain Layered Zirconia",
      },
      {
        image: ceramic8,
        title: "E.max Crown",
      },
      {
        image: ceramic9,
        title: "Full Zirconia",
      },
    ]
  },
  {
    name : "Center Implant",
    desc : <>
      <ul>
      </ul>
    </>,
    images : [
      {
        image: implant1,
        title: "Srew-retained Bridge",
      }, 
      {
        image: implant2,
        title: "Custom Abutment",
      },
      {
        image: implant3,
        title: "Cemented Bridge",
      }
    ]
  },
  {
    disable : "yes",
    name : "Flexible Partial",
    desc : <></>,
    images : [
      {
        image: partial1,
        title: "Flexible Partial",
      }, 
      {
        image: partial2,
        title: "Flexible Partial",
      },
      {
        image: partial3,
        title: "Flexible Partial",
      },
    ]
  },
  {
    disable : "yes",
    name : "Orthodontics",
    desc : <>
      <ul>
      </ul>
    </>,
    images : [
      {
        image: ortho1,
        title: "Spring Retainer",
      }, 
      {
        image: ortho2,
        title: "Soldering Retainer",
      },
      {
        image: ortho3,
        title: "Srew Expander",
      },
      {
        image: ortho4,
        title: "Space Maintainer",
      },
      {
        image: ortho5,
        title: "Frankel 2",
      },
      {
        image: ortho6,
        title: "Expander",
      }
    ]
  },
  {
    disable : "yes",
    name : "Anti-Snoring Device",
    desc : <>
      <ul>
      </ul>
    </>,
    images : [
      {
        image: device1,
        title: "Moses Appliance",
      }, 
      {
        image: device2,
        title: "TAP-T Set",
      }, 
      {
        image: device3,
        title: "Silent Nite",
      }
    ]
  },
  {
    disable : "yes",
    name : "Sports Guard",
    desc : <></>,
    images : [
      {
        image: guard1,
        title: "Sports Guard",
      }, 
      {
        image: guard2,
        title: "Sports Guard",
      }, 
      {
        image: guard3,
        title: "Sports Guard",
      }
    ]
  },
  {
    disable : "yes",
    name : "Metal Framework",
    desc : <>
      <ul>
      </ul>
    </>,
    images : [
      {
        image: metalframe2,
        title: "Bego Metal Framework",
      }, 
      {
        image: metalframe1,
        title: "Bego Metal Framework",
      }, 
      {
        image: metalframe3,
        title: "Vitallium Metal Framework",
      }, 
      {
        image: metalframe4,
        title: "Bego Metal-Valplst Combo",
      }, 
      {
        image: metalframe5,
        title: "Bego Metal-Valplst Combo",
      }
    ]
  },
  {
    disable : "yes",
    name : "Acrylic Denture",
    desc : <></>,
    images : [
      {
        image: acrylic1,
        title: "Full Denture",
      }, 
      {
        image: acrylic2,
        title: "Full Denture",
      }, 
      {
        image: acrylic3,
        title: "Full Denture",
      }
    ]
  },
  {
    disable : "yes",
    name : "Attachment",
    desc : <>
      <ul>
      </ul>
    </>,
    images : [
      {
        image: attachment1,
        title: "Precision Attachment",
      }, 
      {
        image: attachment2,
        title: "Precision Attachment",
      }, 
      {
        image: attachment3,
        title: "Ball Attachment",
      }
    ]
  },
  {
    disable : "yes",
    name : "PFM",
    desc : <>
      <ul>
      </ul>
    </>,
    images : [
      {
        image: pfm1,
        title: "PFM Non-precious",
      }, 
      {
        image: pfm2,
        title: "Captek",
      }, 
      {
        image: pfm3,
        title: "PFM Yellow Gold",
      }
    ]
  },
  {
    disable : "yes",
    name : "Medical 3D",
    desc : <>
      Transformed technology product to help with the surgical complementary therapy, is achieved by the 3D print. <br/>
      The Model which is based on the patient’s CT (Compuer Tomography) and MRI (Magnetic resonance imagine) date, imitates the surgery and implant design and processes with high precision 3D print can help with reappearing and rebuilding patient’s organizational structures scientifically and audio-visually, imitating the surgery and the pre-installation of the implant precisely, and even realizing the mechanical analysis of the bone structures and the implant on computer, and finally achieve the three-dimensional reconstruction of the bone and tooth, etc. <br/>
      Our medical 3D model products series can provide clinicians scientific and accurate diagnoses and can be their therapy reference, also can deliver patients superior and convenient medical complementary therapy service.<br/>
      <br/>
      <h6>Chairside application category</h6>
      <ol>
        <li>
          Oral maxillo-facial:
          <ul>
            <li>
              Maxillo-facial tumour and rebuild
            </li>
            <li>
              Maxillo-facial trauma
            </li>
          </ul>
        </li>
        <li>
          Dental: Implant guide plate
        </li>
      </ol>
      <h6>Category</h6>
      <ol>
        <li>Base Model</li>
        <li>Surgery Model</li>
      </ol>
      <div className="imageDesc">
        <p>Skull base tumour design </p>
        <img src={model1}/>
        <p>Implant guide plate</p>
        <img src={model2}/>
        <p>Perone Rebuild and mandible design</p>
        <img src={model3}/>
        <p>Mandible tumour tumor resection</p>
        <img src={model4}/>
      </div>
      <h6>Implant design</h6>
      <p>The large impeccable implant date base allows the design and print of the implant model according to users’ various demands. <br/>
      By following the surgical principle, the authority experts design its location, prefabricate its profile, and assist the surgical treatment with the models, can finally guarantee the treatment effect of the surgery.<br/></p>
      <div className="imageDesc">
        <img src={model5}/>
      </div>

      <h6>Plate design for tumor and its surgery</h6>
      <p>According to the data provided by users, the professional engineering technicians can accomplish the surgical lesion segmentation and imitation, fabricate the post-operation model and the surgical plate. <br/>
      It will help guide the clinical surgery.<br/></p>
      <div className="imageDesc">
        <img src={model6}/>
      </div>

      <h6>Medical data storage</h6>
      <p>Patients can download their data anytime and anywhere which will be stored in our server freely during the fabrication of their models from the internet and change the way of reading CT scans. <br/>
      These data will provide the clinic care a more specific medical imaging reference.<br/></p>

      <h6>Model design process</h6>
      <p>DICOM data collection -- data processing and storage -- model design and fabrication -- model application</p>
      <div className="imageDesc">
        <img src={model7}/>
      </div>

      <h6>Product parameter</h6>
      <p>
      <b>Material Parameter</b><br/>
      Product material ：PLA Photosensitive resin<br/>
      Sterilize method radioactive：rays>5mm Plasma sterilization<br/>
      
      <b>Model Parameter</b><br/>
      Print method：Fusion fiber print Light-curing print<br/>
      Data output：0.05mm-0.25mm 0.02-0.1mm<br/>
      </p>

    </>,
    images : [
      {
        title : "",
        image : comingsoon,
      }
    ]
  },
]

const ServiceBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-items: center;
`
const BoxContent = styled.div`
  /* width: 300px; */
  /* min-height: 300px; */
  flex: 1 1 25%;
  margin: 10px;
  /* border: 1px solid #ccc; */
  padding: 5px;
  @media only screen and (max-width: 600px) {
    flex: 1 1 100%;
  }
  img{
    object-fit: cover;
    object-position: center;
  }
  
  .triggerButton{
    width: 100%;
    border: none;

    :hover{
      background-color: #17a99e;
      color: #fff;
    }
  }
  .popup-style-content{
    padding: 0px !important;
    border: none !important;
    min-width: 50%;
    min-height: 10%;
    overflow: auto;

    @media only screen and (max-width: 600px) {
      width: 100% !important;
      height: 100%;
    }
  }
  .modal {
    font-size: 12px;
    display: block;
    position: relative;
    height: fit-content;
    .header {
      background-color: #17a99e;
      color: #fff;
      width: 100%;
      border-bottom: 1px solid gray;
      font-size: 24px;
      text-align: center;
      padding: 10px;
    }
    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 10px 5px;
    }
    .imageContent{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .textContent{
      padding: 5px 15px;
      margin: 5px;
      /* background-color: #efefef; */
      h5{
        text-align: center;
        color: #235551
      }
    }
    .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: 15px;
      top: 15px;
      font-size: 24px;
      background: #ffffff;
      border-radius: 18px;
      border: 1px solid #cfcece;
    }
    .imageItem{
      flex: 0 0 30%;
      @media only screen and (max-width: 600px) {
        flex: 0 0 100%
      }
    }
    .boxImage{
      width: 300px;
    }
    .mainImage{
      width: 100%;
      height: 300px;
      padding: 5px;
      margin: 0;
      object-fit: cover;
      object-position: center;  
    }
    .imageTitle{
      text-align: center;
      margin: 0;
      margin-top: 24px;
    }
    .imageDesc{
      text-align: center;
      p{
        margin: 0;
      }
      img{
        width: 50%;
        margin-bottom: 24px;
      }
    }
    .actionButton{
      margin-top: 15px;
      text-align: center;
    }
  }
`
const TriggerModal = styled.button`
  background: none;
  padding: 0;
  border: none;
  width: 100%;
  .card-border{
    margin: 0;
    margin-bottom: 0 !important;
  }
  .trigger-allowed{
    &:focus,
    &:hover,
    &:active{
      background-color: rgba(23, 169, 157, 0.5);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
  }
  .trigger-disabled{
    border: grey;
    h6{
      color: grey;
    }
    img{
      filter: grayscale(100%);
    }
  }
`
const md = new MarkdownIt()
const result = md.render('# markdown-it rulezz!');


const DentistPage = ({data}) => (
  <Layout>
    {/* What are you doing here? */}
    <SEO title="Digital-Dentistry" />
      <Banner src={bannerdentist} alt="banner">
          <h3>
            Digital Dentistry
          </h3>
          <p>
            Put on your best smile every day.
          </p>
          <p>
            Provide your 3D scan for Customised Device <br/>Or <br/>Contact us for 3D Scanning
          </p>
          <div style={{display:"flex", justifyContent:"center"}}>
          <LinkhrefButtonSecondary style={{margin:"0 5px"}} href="http://server.3dgens.com">Upload 3D Scan <FaArrowRight/></LinkhrefButtonSecondary>
          <LinkButtonSecondary style={{margin:"0 5px"}} toLink="dental-form">Request for 3D Scanning <FaArrowRight/></LinkButtonSecondary>
          </div>
      </Banner>
    <Section>
      <Container>
        <h3>Digital Dentistry</h3>
        <p>
          We offer a wide variety of implant suprastructures.<br/>
          Partnership with our qualified dental technicians either design your suprastructure up to the smallest detail or directly manufacture your design.<br/>
          This recent and revolutionary technique of 3D printing offers a great design freedom, a high retention level, and a high quality product<br/>
          <i>Click on the product Name to learn more.</i>
        </p>
        <ServiceBox>
          {data.allStrapiDentals.edges.map((item) => (
            <BoxContent>
              <Popup 
              className="popup-style"
              trigger={
                item.node.image.length !== 0 ? 
                  item.node.disable == true ? 
                    <TriggerModal disabled>
                      <CardBorder image={process.env.GATSBY_API_URL+item.node.image[0].url} title={item.node.name} disable className="card-border trigger-disabled">
                        Coming Soon
                      </CardBorder>
                    </TriggerModal>
                  :
                    <TriggerModal>
                      <CardBorder image={process.env.GATSBY_API_URL+item.node.image[0].url} title={item.node.name} className="card-border trigger-allowed">
                        <br/>
                      </CardBorder>
                    </TriggerModal>
                :
                  <TriggerModal>
                    {item.node.name}
                  </TriggerModal>
              }
              modal
              closeOnDocumentClick
              >
                {close => (
                  <div className="modal">
                    <a className="close" onClick={close}>
                      &times;
                    </a>
                    <div className="header"> {item.node.name} </div>
                    <div className="content">
                      <div className="textContent">
                        <div dangerouslySetInnerHTML={{ __html: md.render(item.node.description) }}></div>
                      </div>
                      <div className="imageContent">
                        {item.node.image ?
                        item.node.image.map((image) => (
                          <div className="imageItem">
                            {image.name ? <p className="imageTitle">{image.name}</p> : ""}
                            <div className="boxImage">
                              <img className="mainImage" src={process.env.GATSBY_API_URL+image.url}/>
                            </div>
                          </div>
                        )) 
                        : "" }
                      </div>
                      {item.node.lucid ? (
                        <>
                          <div className="actionButton">
                            <div style={{display:"flex", justifyContent:"center"}}>
                              <div style={{width: "fit-content", padding:"0 5px"}}>
                                <LinkhrefButtonSecondary href="/documents/OrderForm-LUCID.pdf" download="General guideline to upload data to server.pdf">Download Order Form <FaArrowRight/></LinkhrefButtonSecondary>
                              </div>
                              <div style={{width: "fit-content", padding:"0 5px"}}>
                                <LinkhrefButtonPrimary href="https://lucid.my/">Visit Lucid Website <FaArrowRight/></LinkhrefButtonPrimary>
                              </div>
                            </div>
                            <p></p>
                          </div>
                        </>
                      )
                      : 
                        <div className="actionButton">
                          <p>Contact us for more information</p>
                          <LinkButtonSecondary toLink="/about/#contact">Contact Us <FaArrowRight/></LinkButtonSecondary>
                          <p></p>
                        </div>
                        }
                    </div>
                  </div>
                )}
              </Popup>

            </BoxContent>
          ))}
        </ServiceBox>
      </Container>
    </Section>
  </Layout>
)

export default DentistPage

export const query = graphql`
            query DentalQuery {
              allStrapiDentals(sort: {fields: disable, order: ASC}){
                edges{
                  node{
                    id
                    name
                    description
                    image{
                      url
                    }
                    lucid
                    disable
                    createdAt
                  }
                }
              }
            }
`

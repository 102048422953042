// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-consultation-js": () => import("./../../../src/pages/consultation.js" /* webpackChunkName: "component---src-pages-consultation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dental-form-js": () => import("./../../../src/pages/dental-form.js" /* webpackChunkName: "component---src-pages-dental-form-js" */),
  "component---src-pages-digital-dentistry-js": () => import("./../../../src/pages/digital-dentistry.js" /* webpackChunkName: "component---src-pages-digital-dentistry-js" */),
  "component---src-pages-engineering-js": () => import("./../../../src/pages/engineering.js" /* webpackChunkName: "component---src-pages-engineering-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medical-js": () => import("./../../../src/pages/medical.js" /* webpackChunkName: "component---src-pages-medical-js" */),
  "component---src-pages-medical-upload-js": () => import("./../../../src/pages/medical-upload.js" /* webpackChunkName: "component---src-pages-medical-upload-js" */),
  "component---src-pages-metal-print-form-js": () => import("./../../../src/pages/metal-print-form.js" /* webpackChunkName: "component---src-pages-metal-print-form-js" */),
  "component---src-pages-metalmaterial-js": () => import("./../../../src/pages/metalmaterial.js" /* webpackChunkName: "component---src-pages-metalmaterial-js" */),
  "component---src-pages-metalprinter-js": () => import("./../../../src/pages/metalprinter.js" /* webpackChunkName: "component---src-pages-metalprinter-js" */),
  "component---src-pages-polyprinter-js": () => import("./../../../src/pages/polyprinter.js" /* webpackChunkName: "component---src-pages-polyprinter-js" */),
  "component---src-pages-printer-js": () => import("./../../../src/pages/printer.js" /* webpackChunkName: "component---src-pages-printer-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-scanner-js": () => import("./../../../src/pages/scanner.js" /* webpackChunkName: "component---src-pages-scanner-js" */),
  "component---src-pages-software-fusion-js": () => import("./../../../src/pages/software-fusion.js" /* webpackChunkName: "component---src-pages-software-fusion-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-software-simufact-js": () => import("./../../../src/pages/software-simufact.js" /* webpackChunkName: "component---src-pages-software-simufact-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-training-advance-js": () => import("./../../../src/pages/training-advance.js" /* webpackChunkName: "component---src-pages-training-advance-js" */),
  "component---src-pages-training-amdesigner-js": () => import("./../../../src/pages/training-amdesigner.js" /* webpackChunkName: "component---src-pages-training-amdesigner-js" */),
  "component---src-pages-training-amoperator-js": () => import("./../../../src/pages/training-amoperator.js" /* webpackChunkName: "component---src-pages-training-amoperator-js" */),
  "component---src-pages-training-amprocess-js": () => import("./../../../src/pages/training-amprocess.js" /* webpackChunkName: "component---src-pages-training-amprocess-js" */),
  "component---src-pages-training-basic-js": () => import("./../../../src/pages/training-basic.js" /* webpackChunkName: "component---src-pages-training-basic-js" */),
  "component---src-pages-training-build-js": () => import("./../../../src/pages/training-build.js" /* webpackChunkName: "component---src-pages-training-build-js" */),
  "component---src-pages-training-custom-js": () => import("./../../../src/pages/training-custom.js" /* webpackChunkName: "component---src-pages-training-custom-js" */),
  "component---src-pages-training-design-js": () => import("./../../../src/pages/training-design.js" /* webpackChunkName: "component---src-pages-training-design-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}


import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import gens3d from "../images/3DGENS_LOGO.png"
import research2 from "../images/research2.jpg"
import research3 from "../images/research3.jpg"
import bannerresearch from "../images/banner/page-banner/banner-research.png";
import research from "../images/research.jpg"

import Banner from "../components/Banner";

const Section = styled.section`
  padding: 60px 0;
`
const Image = styled.img`
  width: 500px;
  height: auto;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
`
const ResearchText = "One of our focus areas is research and development. We understand the potential of 3D Printing technology and the need of new material and processes that can be used for many applications. A few of our R&D are in the medical, automotives, jewelry industry. Through many collaboration, we bridge the gap between industry and researcher by producing what they need for a better healthcare. We also work with innovative Product Development Centre (IPDC), in the United Kingdom for joint product development which focus in metal 3D Printing that stretches to automotive, Oil & Gas and consumer products."

const ResearchPage = () => (
  <Layout>
    <SEO title="Research" />
    <Banner color src={bannerresearch} alt="banner">
      <h3>
        Advancing the potential of 3D Printing Technology
      </h3>
      <p>
        We bridging the gap between the industry and the researcher <br/>by producing what they need for a better future.
      </p>
    </Banner>
    <Section>
      <Container>
        <Row>
          <Col md="6">
            <h3>Research and Development</h3>
            <p>
              {ResearchText}
            </p>
          </Col>
          <Col md="6">
            <Image src={research}></Image>
          </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
)

export default ResearchPage

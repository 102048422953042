import React from 'react'
import styled from 'styled-components'
import Media from 'react-media'

import { Container, Row, Col } from "reactstrap";


import TrainingBG from "../images/training/BACKGGROUND1-01.png";
import imaqs from "../images/training/AMModules/IAMQS-LOGO-04.png"
import iconinsight from "../images/training/AMModules/ICONMESSANGES-06.png"
import icongroup from "../images/training/AMModules/ICONMESSANGES-07.png"
import iconbenefit from "../images/training/AMModules/ICONMESSANGES-08.png"


const Section = styled.section`
  padding: 60px 0;
`
const BannerSection = styled.div`
  position: relative;
  height: 60vh;
  .bannerContent{
    position: absolute;
    bottom: 0;
    left: 20%;
    .flexbox{
      display: flex;
      align-items: center;
    }
    .textArea{
      flex: 80%;
      height: 100%;
      padding: 30px 80px;
      border-radius: 20px;
      background-color: #1f7ab8;
      color: #fff;
      p{
        margin: 0;
      }
    }
    .logoArea{
      flex: 20%;
      img{
        margin: 0;
      }
    }
  }
  .bannerImage{
    background-size: cover;
    background-position: 100% 50%;
    background-color: #0f0f0f;
    height: 50vh;
  }
`
const IconTextBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  .title{
    p{
      font-size: 30px;
      font-weight: bold;
      line-height: 25px;
      margin: 0;
    }
  }
  img{
    width: 300px;
  }
`
const PositionDeetsBox = styled.div`

`
const DetailsBox = styled.div`
  .iconTitle{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
  }
  .title{
    font-size: 24px;
    font-weight: bold;
  }
  img{
    width: 50px;
  }
  `
  const ReturnButton = styled.div`
  text-align: center;
  a{
    border: 1px solid #0f0f0f;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
    background-color: #00c3f3;
    :hover{
      background-color: #0f0f0f;
      border-color: #00c3f3;
      color: #fff;
    }
  }
  `

const TainingBody = ({ Title, TitleDesc, Banner, LongTitle, PositionTitle, PositionDetails, PositionDetails2, PositionImg, PositionIcon, InsightDetails, GroupDetails, BenefitsDetails} ) => {
  return (
    <div>
      <BannerSection>
        <div className="bannerImage" style={{backgroundImage: `url(${Banner})`}}/>
          <div className="bannerContent">
        <Container>
          <div className='flexbox'>
            <div className="textArea">
              <div>
                {Title}
              </div>
              <div>
                {TitleDesc}
              </div>
            </div>
            <div className='logoArea'>
              <img src={imaqs} />
            </div>
          </div>
        </Container>
          </div>
      </BannerSection>
      <div style={{backgroundImage:`url(${TrainingBG})`, backgroundRepeat:"repeat"}}>
        <Container>
          <Section>
            <IconTextBox>
              <div className='image'>
                { PositionIcon ? (<img src={PositionIcon}/>): null}
              </div>
              <div className='title'>
                {LongTitle}
                <span>TRAINING</span>
              </div>
            </IconTextBox>
            <PositionDeetsBox>
              {/* <h4>{PositionTitle}</h4> */}
              <p>{PositionDetails}</p>
              <ul>
                <li>
                  <p><b>International</b> Metal Additive Manufacturing <span style={{color: '#00c3f3', fontWeight: 'bold'}}>{LongTitle}</span></p>
                </li>
              </ul>
              <p>{PositionDetails2}</p>
              {PositionImg ? (<img src={PositionImg}/>) : null}
              
            </PositionDeetsBox>
          </Section>
          <DetailsBox>
            <Section>
              <div>
                <div className='iconTitle'>
                  <img src={iconinsight} />
                  <p className='title'>
                    <span style={{color: '#293c72'}}>Course</span> <span style={{color: '#00c3f3'}}>Insights</span>
                  </p>
                </div>
                <ul>
                  {InsightDetails.map((detail) => (
                    <li>
                      {detail}
                    </li>
                  ))}
                </ul>
              </div>
            </Section>
            <Section>
              <div>
                <div className='iconTitle'>
                  <img src={icongroup} />
                  <p className='title'>
                    <span style={{color: '#293c72'}}>Target</span> <span style={{color: '#00c3f3'}}>Group</span>
                  </p>
                </div>
                <p>
                  {GroupDetails}
                </p>
              </div>
            </Section>
            <Section>
              <div>
                <div className='iconTitle'>
                  <img src={iconbenefit} />
                  <p className='title'>
                    <span style={{color: '#293c72'}}>Qualification</span> <span style={{color: '#00c3f3'}}>Benefits</span>
                  </p>
                </div>
                <ul>
                  {BenefitsDetails.map((detail) => (
                    <li>
                      {detail}
                    </li>
                  ))}
                </ul>
              </div>
            </Section>
            <Section>
              <ReturnButton>
                <a href='/training/#iamqs'> Return to Page </a>
              </ReturnButton>
            </Section>
          </DetailsBox>
        </Container>

      </div>
    </div>
  )
}

export default TainingBody

import React, { useState } from 'react'
import styled from 'styled-components'

import { FaWhatsapp } from 'react-icons/fa';
import { Container } from 'reactstrap';

const BarStyle = styled.div`
  background-color: #2F979F;
  padding: 80px 0;
  margin: 0;
`

const ContactOptionBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  column-gap: 1em;
  text-align: center;
  font-size: 20px;
  h5{
    margin-bottom: 0;
  }
  .buttonDesign{
    display: inline-block;
    min-width: 200px;
    padding: 10px 20px;
    background-color: #007bff; 
    color: #fff; 
    text-decoration: none; 
    border: none; 
    cursor: pointer; 
    font-size: 16px; 
    // font-family: Arial, sans-serif; 
    border-radius: 5px; 
    transition: background-color 0.3s ease; 
  }
  .contactpage{
    flex: 1;
    .buttonDesign{
      background-color: #007BFF;
      :hover{
        background-color: #0056B3;
      }
    }
  }
  .directemail{
    flex: 1;
    position: relative;
    .buttonDesign{
      background-color: #FFA000;
      :hover{
        background-color: #FFC107;
      }
    }
    .alertBox{
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      text-align: center;
    }
  }
  .contactnumber{
    flex: 1;
    .buttonDesign{
      background-color: #25D366;
      :hover{
        background-color: #4AD582;
      }
    }
  }
`

const ContactBar = () => {
  
  const [alert, setAlert] = useState(null);

  const emailClick = () => {
    const email = "info@3dgens.com";
    navigator.clipboard.writeText(email)
      .then(() =>{
        const alertMessage = 'Email Copied to Clipboard';
        setAlert(alertMessage);

        setTimeout(() => setAlert(null), 3000);
      })
      .catch((error) => console.error('Failed to copy: ', error));
  };

  return (
    <BarStyle style={{backgroundColor: "#"}}>
      
      <Container>
          <h3 style={{textAlign: "center", fontSize: "28px"}}>
            Talk to our experts
          </h3>
          <p style={{textAlign: "center", fontSize: "22px"}}> 
            You can contact us if you have any question or want to consult us on a project you have, <br/>
            Kindly contact us through this options: 
          </p>
          <ContactOptionBox>
            <div className="contactpage">
              <h5>Website Contact Page</h5>
              <p>Contact via the Website</p>
              <a href="/contact" className="buttonDesign">Contact Us</a>
            </div>
            <div className="directemail">
              <h5>Email us directly at</h5>
              <p>3dprint@3dgens.com</p>
              <button onClick={emailClick} className="buttonDesign">Copy Email</button>
              {alert && (
                <div className='alertBox'>
                  {alert}
                </div>
              )}
            </div>
            <div className="contactnumber">
              <h5>Contact/WhatsApp via Phone at</h5>
              <p>+60105583070</p>
              <a href="https://wa.me/60105583070" target="_blank" rel="noopener noreferrer" className="buttonDesign"><FaWhatsapp/> WhatsApp Number</a>
            </div>
          </ContactOptionBox>
        </Container>
    </BarStyle>
  )
}

export default ContactBar

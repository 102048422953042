import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TrainingAMBody from "../components/AMTrainingBody"

import Banner from "../images/training/AMModules/AMProcess/01BANNER-09.png";
import icon from "../images/training/AMModules/AMProcess/ICONIAMQS-01.png";
import image from "../images/training/AMModules/AMProcess/img01.jpg";

const titleBody = ( <h2> INTERNATIONAL PROCESS ENGINEER  </h2> )
const longtitleBody = ( <p>PROCESS ENGINEER POWDER BED FUSION LASER BEAM PERSONNEL (I MAM PE PBF - LB)</p>)
const titleDescBody = ( <p> Experience what it takes to become an Additive Manufacturing expert and become a leader in this high demanding technological field! These qualifications will give you competences for the technical implementation of an AM specific process. </p> )
const insightdetails = [
  "Develop and execute plans inluding validation of design, implementation, pre and post processing operations, assurance of parts conformity and identification of causes and the corrective actions of technical production problems.",
  "Apply a wide variety of engineering techniques, contributing to projects in a teaming environment and compare, investigate, transfer, and adapt procedures, techniques, or methods to new application. ",
  "Evaluate manufacturing suitability for customers request"
]
const groupdetails = ( 
  <p>
    This training programme is ideal for Engineers willing to specialize and pursue a career in Additive Manufacturing
  </p>
)
const benefitdetails = [
  "Up-to-date knowledge, skills and competence regarding Additive Manufacturing processes",
  "Understanding of technical requirements to be applied for solving complex problems and proving quality in request conditions.",
  "Offering the full pitchure of PBF-LB Additive Manufacturing coordination, both from the prespective of decision making and professional development",
  "An International recognised qualification which increases employability in the labour market, through mobility and skills transparency"
]


const TrainingAMProcessPage = () => (
  <Layout>
    <SEO title="AM Process Engineer" description="AM Process Engineer (I MAM PE PBF - LB)" />
    
    <TrainingAMBody
      Banner={Banner}
      Title={titleBody}
      TitleDesc={titleDescBody}
      LongTitle={longtitleBody}
      PositionDetails="These training programmes are ideal for Enggineers willing to specialise and pursue a career in AM specific process:"
      PositionDetails2="Metal Additive Process Engineers are the professionals with the specific knowledge, skills, autonomy and responsibilty to implement Metal Additive Manufacturing chain assuring the eficient production of metal additively manufactured parts."
      PositionImg={image}
      PositionIcon={icon}
      InsightDetails={insightdetails}
      GroupDetails={groupdetails}
      BenefitsDetails={benefitdetails}
    />

  </Layout>
)

export default TrainingAMProcessPage

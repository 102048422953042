import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components";
import { Container } from "reactstrap";
import { Link } from "gatsby";
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";

import bannerSoftware from "../images/banner/bannerSoftware.png"

import simufact1 from "../images/softwareimage/simufact1.png"
import simufactA from "../images/softwareimage/simufact0A.jpg"
import simufactB from "../images/softwareimage/simufact0B.jpg"
import simufact01 from "../images/softwareimage/simufact01.png"
import simufact02 from "../images/softwareimage/simufact02.png"
import simufact03 from "../images/softwareimage/simufact03.png"
import simufact04 from "../images/softwareimage/simufact04.png"
import simufact05 from "../images/softwareimage/simufact05.png"
import simufact06 from "../images/softwareimage/simufact06.png"

import fusion01 from "../images/softwareimage/fusion01.png"
import fusion02 from "../images/softwareimage/fusion02.png"
import fusion03 from "../images/softwareimage/fusion03.png"

import design from "../images/softwareimage/designer.png";
import machine from "../images/softwareimage/machiner.png";
import electric from "../images/softwareimage/electronic.png";
import industry from "../images/softwareimage/industrial.png";
import mechanic from "../images/softwareimage/mechanic.png";

import { FaWhatsapp } from "react-icons/fa";
import ContactBar from "../components/ContactBar";

const BannerSection = styled.div`
  height: 50vh;
  position: relative;
  .bannerImage{
    background-image: url(${bannerSoftware});
    background-size: cover;
    background-color: #0f0f0f;
    height: 100%;
    // position: relative;
  }
  .bannerText{
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`
const Section = styled.div`
  padding: 60px 0;
`
const TitleFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .titlehead{
    flex: 2;
    padding: 20px;
    P{
      line-height: 30px;
    }
    h4{
      text-align: left;
    }
  }
  .titleimage{
    flex: 1;
  }
`
const FeatureBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .textbox{
    padding: 0 20px;
  }
  .imagebox{
    padding: 0 20px;
    img{
      width: 300px;
      height: auto;
      margin-bottom: 50px;
    }
  }
`

const BenefitBox = styled.div`
  h5{
    color: #fff;
    text-align: center;
    font-size: 20px;
  }
  .benefitboxes{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #fff;
    .boxitem{
      flex: 1;
      padding: 15px 5px;
      margin: 5px;
      // border: 1px solid #000;
      border-radius: 5px;
      text-align: center;
      img{
        width: 100px;
        height: auto;
      }
      p{
        margin-bottom: 5px;
        font-weight: bold;
      }
      span{
        font-size: 14px;
      }
    }
  }
`
const ImageBoxes = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .imagediv{
    flex: 1;
  }
`

const simufactImages = [simufact01, simufact02, simufact03, simufact04, simufact05, simufact06]

const FusionPage = () => {
  
  const email = "3dprint@3dgens.com";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    alert("Email Copied to Clipboard")
  };
  return (
    <Layout>
      <SEO title="3D Metal Printer" />
      <BannerSection>
        <div className="bannerImage"></div>
        <div className="bannerText">
          <h3>
            Software
          </h3>
          <p>
            For Your Digital End-to-End Manufacturin Workflow
          </p>
        </div>
      </BannerSection>
      <Section>
        <Container>
          <h3>
            Seamlessly operate your 3D Systems printers with software
          </h3>
          <TitleFlex>
            <div className="titleimage">
              <img src={fusion03} /> 
            </div>
            <div className="titlehead">
              <h4>
                Autodesk Fusion 360
              </h4>
              <p>
                Autodesk Fusion connects your entire manufacturing process by integrating <br/>
                CAD, CAM, CAE, and PCB into a single solution allowing you to design and make anything.
              </p>
            </div>
          </TitleFlex>
          <FeatureBox>
            <div className="textbox">
              <h5>
                Connect your data, people, and processes with Fusion
              </h5>
              <ul>
                <li>
                  Streamline your product development process <br/>
                  Go from design to manufacturing faster with the only all-in-one cloud CAD, CAM, CAE, and PCB platform.
                </li>
                <li>
                  Increase innovation through connected data <br/>
                  Improve your team’s operational efficiency and boost agility with a single source of product data across your organization and connected supply chain.
                </li>
                <li>
                  Eliminate repetitive tasks with automated workflows <br/>
                  Manufacture faster with connected and automated workflows, plus advanced AI capabilities.
                </li>
              </ul>
            </div>
            <div className="imagebox">
              <img src={fusion01}/>
              <img src={fusion02}/>
            </div>
          </FeatureBox>
        </Container>
      </Section>
      <Section style={{backgroundColor:"#224356"}}>
        <Container>
          <BenefitBox>
            <h5>
              Design and manufacturing solutions for everyone
            </h5>
            <div className="benefitboxes">
              <div className="boxitem">
                <img src={design} />
                <p>
                  Design and manufacturing team capabilities and workflows
                </p>
                
              </div>
              <div className="boxitem">
                <img src={machine} />
                <p>
                  Machining capabilities and workflows
                </p>
                
              </div>
              <div className="boxitem">
                <img src={electric} />
                <p>
                  Electronics engineering capabilities and workflows
                </p>
                
              </div>
              <div className="boxitem">
                <img src={industry} />
                <p>
                  Industrial design capabilities and workflows
                </p>
                
              </div>
              <div className="boxitem">
                <img src={mechanic} />
                <p>
                  Mechanical engineering capabilities and workflows
                </p>
                
              </div>
            </div>
          </BenefitBox>
        </Container>
      </Section>
      <Section>
        <ImageBoxes>
          {simufactImages.map((image) => (
            <div className="imagediv">
              <img src={image} />
            </div>
          ))}
        </ImageBoxes>
      </Section>
      <ContactBar/>
    </Layout>
  )
}

export default FusionPage

import React, { useState } from "react"
import emailjs from "emailjs-com";
import { Form, FormGroup, Label, Input, Container, Row, Col, Alert } from "reactstrap";
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import combed from "../images/combed.png";
import floor from "../images/memphis.png"
import docImg from "../images/doc-img.jpg";
import gensfront from "../images/3dgensfront.png";
import gensfront2 from "../images/gensfront.png";

import izharIMG from "../images/Staff/izhar.png";
import umarIMG from "../images/Staff/umar.png";
import hannaIMG from "../images/Staff/hanna.png";
import hosniIMG from "../images/Staff/hosni.png";
import nazIMG from "../images/Staff/naz.png";
import masdieIMG from "../images/Staff/masdie.png";

import { FaRocket, FaEye, FaIndustry, FaTv, FaCube, FaLaptopMedical, FaBriefcase, FaArrowRight } from "react-icons/fa";



import Banner from "../components/Banner"
import BackgroundSection from "../components/BackgroundSection"
import Section from "../components/Section"
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";
import ButtonSecondary from "../components/buttons/ButtonSecondary";
import PageBanner from "../components/PageBanner";

const LeaderTeam = [
  {
    image: izharIMG,
    prefix: "Dr",
    name: "Izhar Abd Aziz",
    position: "Managing Director",
    description: "Oversees operations, sets strategy, leads teams, and ensures organizational success.",
  },
  {
    image: umarIMG,
    prefix: "",
    name: "Umar Ikramullah",
    position: "Executive Director",
    description: "Guides overall vision, strategy, and organizational management.",
  },
  {
    image: hannaIMG,
    prefix: "",
    name: "Hanna Zulika",
    position: "Finance & Human Resource",
    description: "Manage finances and HR operations for organizational effectiveness.",
  },
  {
    image: hosniIMG,
    prefix: "",
    name: "Hosni Mubarak",
    position: "Business Development",
    description: "Drive business growth through strategic development efforts.",
  },
  {
    image: nazIMG,
    prefix: "",
    name: "Naz Ashari",
    position: "Head of Operation",
    description: "Lead operational activities to achieve organizational goals efficiently.",
  },
  {
    image: masdieIMG,
    prefix: "",
    name: "Masdie Ismail",
    position: "Sales Manager",
    description: "Direct sales team to achieve targets and revenue objectives",
  },
]

const AboutDiv = styled.div`
  .aboutImage{
    border: 1px solid #ccc;
    height: 100%;
    img{
      height : inherit;
      object-fit: cover;
      onject-position: 55%;
    }
  }
  .aboutText{
    letter-spacing: 1px;
    margin-bottom: 0;
    H1{
      margin-bottom: 0;
    }
    p{
      text-align: justify;
      margin-bottom: 0;
    }
  }
  .additionalText{
    a{
      text-decoration: none;
      color: #0e864e;
      :hover{
        color: #1ae99e;
      }
    }
  }
`
const LeaderDiv = styled.div`
  .leaderText{

  }
  .leaderContent{
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    .leaderBox{
      flex: 0 0 30%;
      margin: 15px;
      border: 1px solid #ddd;
      box-shadow: 2px 2px 5px;
      .boxImage{
        overflow: hidden;
        img{
          margin: 0;
          object-fit: cover;
          object-position: 0 20%;
        }
      }
      .boxContent{
        padding: 10px 15px;
        h4{
          text-align: left;
          margin-bottom: 0;
        }
        h5{
          margin-bottom: 5px;
        }
        p{
          font-size: 14px;
        }
      }
    }
  }
`
const AttachmentDiv = styled.div`
  a{
    text-decoration: none;
    color: #0e864e;
    :hover{
      color: #1ae99e;
    }
  }
`


const AboutPage = () => {

  return (
  <Layout>
    <SEO title="About" />
      <PageBanner src={gensfront2} />
    <Section>
      <Container>
        <AboutDiv>
          <Row>
            {/* <Col xs="12" md="4">
              <div className="aboutImage">
                <img src={docImg} />
              </div>
            </Col> */}
            <Col xs="12" md="12">
              <div className="aboutText">
                <h1>
                  About Us
                </h1>
                {/* <p>
                3D Gens or 3D Generation were established with the aim to industrialize 3D modeling and 3D Printing.
                With a vision to become the pioneer in digital Manufacturing anchored by a solid foundation in CAD/CAM 
                and Additive Manufacturing Technology.<br/>
                The founder, Dr. Izhar Aziz has resolute courage to bring the 3D Printing technology to a realization in 
                Malaysia and has strong believe that one day 3D Printing will be a new culture.<br/>
                The company started by penetrating Malaysia medical industry tackling niche areas such as surgical planning 
                and customized medical implants and devices.
                The response are overwhelming thus planning to expand services in orthopedics, maxillofacial and dentistry. 
                Applications are not limited to medical, other potential areas are engineering, automotive and 
                aerospace industries.
                </p> */}
                <p>
                  3D Gens or 3D Generation were established with the aim to industrialize 3D modeling and 3D Printing.
                  At 3D Gens, we are dedicated to pioneering the industrialization of Additive Manufacturing Technology. 
                  We envision a future where creativity knows no bounds, and every industry can benefit from the efficiency 
                  and innovation enabled by additive processes. Together, we shape the future of manufacturing.We celebrate 
                  innovators and engineers as pioneers of creativity. Additive Manufacturing facilitates the realization of 
                  their ideas and designs, transforming them from concepts to functional end-use parts. <br/>
                  What was once confined to prototyping is now capable of printing fully functional components. 
                </p>
                <br/>
              </div>
            </Col>
            <Col xs="12" md="12">
              <div className="additionalText">
                <p>
                  Find out more about how we could help by <a href="/contact">contacting us</a>.
                </p>
              </div>
            </Col>
          </Row>
        </AboutDiv>
      </Container>
    </Section>

    <Section>
      <Container>
        <LeaderDiv>
          <div className="leaderText">
            <h2>
              3D Gens Leadership Team
            </h2>
            <p>
              3D Gens leadership team is responsible for the strategic direction of 3D Gens. 
              They have extensive experience within their disciplines and are committed to making 
              3D Gens a centre of excellence for additive manufacturing.
            </p>
          </div>
          <div className="leaderContent">
            {LeaderTeam.map((person) => (
              <div className="leaderBox">
                <div className="boxImage">
                  <img src={person.image}/>
                </div>
                <div className="boxContent">
                  {person.prefix ? (
                    <h4>
                      {person.prefix} {person.name}
                    </h4>
                  )
                  :
                  (
                    <h4>
                      {person.name}
                    </h4>
                  )}
                  <h5>
                    {person.position}
                  </h5>
                  <p>
                    {person.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </LeaderDiv>
      </Container>
    </Section>

    {/* <Section>
      <Container>
        <AttachmentDiv>
          <h3>
            Attachment
          </h3>
          <ul>
            <li>
              <a href="https://cdn.me-qr.com/pdf/7437416.pdf" target="_blank">Training Brochure</a>
            </li>
          </ul>
        </AttachmentDiv>
      </Container>
    </Section> */}

  </Layout>
  )
}

export default AboutPage

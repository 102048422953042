import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components";
import { Container } from "reactstrap";
import { Link } from "gatsby";
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";

import renAM from "../images/RenAM-500E.jpg";
import bannermetal from "../images/banner/bannerMetalmat.png"
import bannermetal2 from "../images/banner/bannerMetal2.png"

import { FaWhatsapp } from "react-icons/fa";
import ContactBar from "../components/ContactBar";

const BannerSection = styled.div`
  height: 50vh;
  position: relative;
  .bannerImage{
    background-image: url(${bannermetal2});
    background-size: cover;
    background-color: #0f0f0f;
    height: 100%;
    // position: relative;
  }
  .bannerText{
    max-width: 550px;
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: rgba(15, 15, 15, 0.5);
    color: #fff;
    p{
      font-weight: bold;
      margin-bottom: 2px;
    }
    span{
      font-size: 16px;
    }
  }
`
const Section = styled.div`
  padding: 60px 0;
`

const IntroInfoFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  // column-gap: 1em;
  color: #fff;
  text-align: center;
  .leftbox{
    flex: 1;
    padding: 100px 200px;
    background-color: #323232;
  }
  .rightbox{
    flex: 1;
    padding: 100px 200px;
    background-color: #234678;
  }
  p{
    text-align: center;
  }
`
const LinkButton = styled.a`
  color: inherit;
  text-decoration: none;
  text-transform: none;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  :hover{
    text-decoration: none;
    background-color: #fff;
    color: #000;
  }
`
const InfoFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  column-gap: 1em;
`

const MaterialFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1em;
  row-gap: 1em;
  .materialBox{
    flex: 0 0 calc(33.33% - 20px); 
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #234578;
    padding: 70px 30px;
    span{
      font-weight: lighter;
      font-size: 12px;
    }
    h5{
      font-size: 40px;
      color: #234578;
    }
    .specs{
      p{
        margin-bottom: 0;
      }
    }
    :hover{
      background-color: #101820;
      color: #fff;
      h5{
        color: #f5f5f5;
      }
    }
  }
`
const materiallist = [
  {
    type: "Stainless Steel",
    base: "Iron-Base",
    list: <ul>
      <li>PowderRange 316L</li>
      <li>PowderRange 17-4</li>
      <li>PowderRange 17-4 AR</li>
      <li>PowderRange 15-5PH</li>
    </ul>,
  },
  {
    type: "High Temperature",
    base: "Nickel-Base",
    list: <ul>
      <li>PowderRange 718</li>
      <li>PowderRange 625</li>
      <li>PowderRange X</li>
    </ul>,
  },
  {
    type: "Tool & Die Steel",
    base: "Iron-Base",
    list: <ul>
      <li>PowderRange H13</li>
      <li>PowderRange M300</li>
      <li>PowderRange M300 LT</li>
    </ul>,
  },
  {
    type: "Titanium",
    base: "",
    list: <ul>
      <li>PowderRange Ti64</li>
      <li>CPTi Grade 1</li>
      <li>CPTi Grade 2</li>
    </ul>,
  },
  {
    type: "Aluminium",
    base: "",
    list: <ul>
      <li>PowderRange AlSi10Mg</li>
      <li>Scalmally</li>
    </ul>,
  },
  {
    type: "Copper",
    base: "",
    list: <ul>
      <li>GRCop-42</li>
      <li>Cu- F (Copper)</li>
    </ul>,
  },
]
const materialInfo = [
  {
    name: "TI64",
    type: "Titanium Powder",
    specification: "ASTM F3001",
    properties: <ul>
      <li>High strength-to-weight ratio</li>
      <li>Wear and corrosion resistant</li>
      <li>Fracture toughness and fatigue resistance</li>
    </ul>,
  },
  {
    name: "TI CP Grade 1",
    type: "Titanium Powder",
    specification: "ASTM F67",
    properties: <ul>
      <li>Low Strength and Best Ductility</li>
      <li>High corrosion resistance</li>
      <li>Excellent biocompatibily</li>
    </ul>,
  },
  {
    name: "TI CP Grade 2",
    type: "Titanium Powder",
    specification: "ASTM B265",
    properties: <ul>
      <li>High tolerable levels of iron and oxygen</li>
      <li>Excellent fomability</li>
      <li>Superior corrosion resistance</li>
    </ul>,
  },
]

const addtionalInfo = [
  {
    title: "Metal Materials",
    description: "Our extensive and versatile portfolio of 3D printing materials addresses the widest range of applications and performance characteristics in additive manufacturing, with plastic, elastomer, composite, wax, metal, bio-compatible and more material types.",
    image: renAM,
    linktitle: "Metal Material",
    link: "/metalmaterial",
  },
  {
    title: "3D Scanning",
    description: "Our extensive and versatile portfolio of 3D printing materials addresses the widest range of applications and performance characteristics in additive manufacturing, with plastic, elastomer, composite, wax, metal, bio-compatible and more material types.",
    image: renAM,
    linktitle: "metal material",
    link: "/metalmaterial",
  },
  {
    title: "Services",
    description: "Our extensive and versatile portfolio of 3D printing materials addresses the widest range of applications and performance characteristics in additive manufacturing, with plastic, elastomer, composite, wax, metal, bio-compatible and more material types.",
    image: renAM,
    linktitle: "metal material",
    link: "/metalmaterial",
  },
]

const MetalMaterialPage = () => {
  
  const email = "3dprint@3dgens.com";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    alert("Email Copied to Clipboard")
  };
  return (
    <Layout>
      <SEO title=" Metal Printer Material" />
      <BannerSection>
        <div className="bannerImage"></div>
        <div className="bannerText">
          <h3>
            Metal Materials
          </h3>
          <p>
            Consistent Quality, Ensured Results
          </p>
          <span>
            3D Gens presents a diverse array of sophisticated metal alloys, including aluminum, maraging steel, 
            steel, various grades of titanium, nickel, and cobalt chrome alloys. Our portfolio is meticulously crafted 
            for Direct Metal Printing (DMP), providing thoroughly tested build parameters tailored to our DMP printer range.
          </span>
        </div>
      </BannerSection>
        <Container>
          <hr style={{marginTop: "30px"}} />
          <h1 style={{textAlign: "center"}}>
            ON-DEMAND METAL POWDERS FOR <br/>ADDITIVE MANUFACTURING
          </h1>
          <hr/>
        </Container>

      <Section>
        <Container>
          <h3>
            Metal Materials Available
          </h3>
          <MaterialFlexBox>
            {materiallist.map((material) => (
              <div className="materialBox">
                <span>
                  {material.base}
                </span>
                <h5>
                  {material.type}
                </h5>
                {material.list}
              </div>
            ))}
          </MaterialFlexBox>
        </Container>
      </Section>

      <Section>
        <IntroInfoFlexBox>
          <div className="leftbox">
            <h4>
              Unlock the Boundless Possibilities of Metal 3D Printing!
            </h4>
            <p>
              Embrace the freedom to design, test, and fabricate metal components beyond the constraints of traditional 
              manufacturing methods. Craft lightweight yet robust structures, unleashing unprecedented capabilities.
            </p>
            {/* <LinkButton href="/product">
              Learn More
            </LinkButton> */}
          </div>
          <div className="rightbox">
            <h4>
              Elevate Your Creations with 3D Metal Printing!
            </h4>
            <p>
              Experience the unparalleled design freedom of Direct Metal Printing (DMP), enabling the creation of lightweight, 
              durable parts that outperform traditional manufacturing methods.
            </p>
            {/* <LinkButton href="/material">
              Learn More
            </LinkButton> */}
          </div>
        </IntroInfoFlexBox>
      </Section>
      
      <hr/>
      <Container>
        {/* <Section>
          <InfoFlexBox>
              {addtionalInfo.map((item) => (
                <div>
                  <img src={item.image}/>
                  <h5>
                    {item.title}
                  </h5>
                  <p>
                    {item.description}
                  </p>
                  <LinkButton href={item.link}>
                    {item.linktitle}
                  </LinkButton>
                </div>
              ))}
          </InfoFlexBox>
        </Section> */}
      </Container>
      <ContactBar/>
    </Layout>
  )
}

export default MetalMaterialPage

import React from 'react'
import { FaArrowRight } from "react-icons/fa";
import styled from 'styled-components'

const ExternalLink = styled.a`
  text-decoration: none;
  color: #235551;

  &:active,
  &:hover {
    text-decoration: none;
    color: #17a99e;
  }
`

const ButtonPlain = ({children, to}) => {
  return <ExternalLink href={to} target="_blank" rel="noopener noreferrer">{children} <FaArrowRight /></ExternalLink>
}

export default ButtonPlain

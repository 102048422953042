import React from "react";
import Carousel from "nuka-carousel";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { Link } from "gatsby"

import lisaPro from "../images/frontpanel/lisaPro.png";
import Unisel1 from "../images/training/Unisel/Unisel8.jpg";
import specificImplant from "../images/products/specificImplant.jpg";
import peekImplant from "../images/peekimplant.jpeg";
import metalproduct from "../images/products/metalProduct.jpg";
import poliproduct from "../images/products/poliProduct.jpg";
import digitalteeth from "../images/digitalteeth.jpg";

import { FaPlay, FaArrowRight, FaFacebookSquare, FaInstagram, FaArrowLeft } from "react-icons/fa";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

const products = [
  {
    image: peekImplant,
    title: <span>Medical</span>,
    link: "/medical"
  },
  {
    image: digitalteeth,
    title: <span>Digital Dentistry</span>,
    link: "/digital-dentistry"
  },
  {
    image: poliproduct,
    title: <span>Engineering</span>,
    link: "/engineering"
  },
  {
    image: Unisel1,
    title: <span>Training & Consultation</span>,
    link: "/training"
  },
  {
    image: metalproduct,
    title: <span>Research & Development</span>,
    link: "/research"
  },
  {
    image: lisaPro,
    title: <span>3D Printers</span>,
    link: "/printer"
  },
]

const ButtonCarousel = styled.button`
  padding: 4px 24px;
  margin: 0;
  /* background-color: rgba(0,0,0,0.5); */
  background-color: transparent;
  color: black;
  border: none;
  font-size: 32px;

  :hover{
    /* background-color: rgba(50,50,50,0.5) */
    color: #17a99e;
  }
`
const CarouselBox = styled.div`
  height: 40vh;
  width: 100%;
  margin: 5px 0;
`
const CarouselLink = styled.a`
width: 100%;
height: 100%;
`
const CarouselContent = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  img{
    width: 100%;
    height: 40vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
  
`
const CarouselContentText = styled.div`
  // position: absolute;
  h5{
    text-align: center;
  }
`
const ContentBox = styled.div`
  height: 40vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
  /* background-color: #000; */
  transition: all .5s;
  h5{
    margin-top: 5px;
  }
  @media only screen and (max-width: 1024px){
    height: 300px;
  }
  @media only screen and (max-width: 800px){
    height: 200px;
  }
`

const ProductItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  h5{
    text-align: center;
  }
  .hoverboxclass{
    display: block;
  }
  .link{
    flex: 1 1 15%;
    margin: 10px 2.5px;
    width: 100%;
    text-decoration: none;
    color: black;
    // border: 2.5px solid #000;
    border-radius: 25px;
  }
  .link:hover{
    img{
      // border: 1px solid #235551;
    }
    h5{
      color: #17A99E;
    }
    .hoverboxclass{
      display: block;
    }
    .producttitleclass{
      background-color: transparent;
    }
    .contentboxclass{
      transform: scaleY(1.2);
    }
  }
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
    .link{
      width: 48%; 
    }
    h5{
      font-size: 100%;
    }
  }
  @media only screen and (max-width: 400px) {
    .link{
      flex: 1 1 45%;    
    }
  }

`
const HoverBox = styled.div`
  // display:none;
  height: 100%;
  width: 100%;
`

const ProductTitle = styled.div`
  display:flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 15px;
  background-color: rgba(255,255,255,0.7);
  width: 100%;
  height: 100%;
  border-radius: 25px;
  h5{
    text-align: center;
    font-weight: bold;
    color: #235551;
    margin: 0;
    font-size: 30px;
  }
`

const ProductCarousel = ({data}) => {
    return (
      <div>
        <Default>
          <CarouselBox>
            <Carousel 
            slidesToShow={3}
            slidesToScroll={1}
            renderCenterLeftControls={({ previousSlide }) => (<ButtonCarousel onClick={previousSlide}><FaArrowLeft/></ButtonCarousel>)} 
            renderCenterRightControls={({ nextSlide }) => (<ButtonCarousel onClick={nextSlide}><FaArrowRight/></ButtonCarousel>)} 
            width="100%" 
            height="100%" 
            dragging={false} 
            pauseOnHover={true}
            withoutControls={false} 
            autoplay={true} 
            wrapAround={true} 
            swiping={true}>
              {/* {products.map((product) => ( 
                <CarouselLink href={product.link}>
                  <CarouselContent>
                    <img src={product.image}/>
                    <CarouselContentText>
                      <h5>
                        {product.title}
                      </h5>
                    </CarouselContentText>
                  </CarouselContent>
                </CarouselLink>
              ))} */}
              {products.map((product, index) => ( 
                <ProductItem>
                <Link className="link" to={product.link}>
                  <ContentBox className="contentboxclass" style={{padding: 0, borderWidth: 0, boxSizing: "border-box", position: "relative", backgroundImage: `url(${product.image})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', }}>
                    <HoverBox className="hoverboxclass">
                      <ProductTitle className="producttitleclass">
                        <h5>
                          {product.title}
                        </h5>
                      </ProductTitle>
                    </HoverBox>
                  </ContentBox>
                </Link>
                </ProductItem>
              ))}
            </Carousel>
          </CarouselBox>
        </Default>

        <Mobile>
          <ProductItem>
              {products.map((product, index) => ( 
                <Link className="link" to={product.link}>
                  <ContentBox className="contentboxclass" style={{padding: 0, borderWidth: 0, boxSizing: "border-box", position: "relative", backgroundImage: `url(${product.image})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', }}>
                      <HoverBox className="hoverboxclass">
                        <ProductTitle className="producttitleclass">
                          <h5>
                            {product.title}
                          </h5>
                        </ProductTitle>
                      </HoverBox>
                  </ContentBox>
                </Link>
              ))}
          </ProductItem>
        </Mobile>
      </div>
    )
}

export default ProductCarousel
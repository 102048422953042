import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from "styled-components";

import Banner from "../components/Banner";
import PhotoCarousel from "../components/PhotoCarousel";

import Layout from "../components/layout";
import SEO from "../components/seo";

import ewf from "../images/logo/EWF.webp"
import twi from "../images/logo/TWI.png"
import wim from "../images/logo/wim.png"
import wimanb from "../images/logo/WIM-ANB-Logo-Long.png"
import hrdf from "../images/logo/hrdcorp-logo-training-provider.png"
import hrdfc from "../images/logo/HRDF-HRD-Corp-Claimable.png"
import iamqs from "../images/logo/IAMQS.png"

import TrainerBanner from "../images/training/BANNER-01.jpg";
import TrainingBG from "../images/training/BACKGGROUND1-01.png";

import img01 from "../images/training/trainingIMG01.jpg"
import img02 from "../images/training/trainingIMG02.jpg"
import img03 from "../images/training/trainingIMG03.jpg"
import course01 from "../images/training/training001.png"
import course02 from "../images/training/training002.png"
import course03 from "../images/training/training003.png"
import course04 from "../images/training/training004.png"
import course05 from "../images/training/training005.jpg"
import AM01 from "../images/training/AM ICON LOGO-01.jpg"
import AM02 from "../images/training/AM ICON LOGO-02.jpg"
import AM03 from "../images/training/AM ICON LOGO-03.jpg"


import { FaArrowRight } from "react-icons/fa";

const Section = styled.section`
  padding: 60px 0;
`
const BannerSection = styled.div`
  height: 50vh;
  position: relative;
  .bannerImage{
    background-image: url(${TrainerBanner});
    background-size: cover;
    background-position: 50% 50%;
    background-color: #0f0f0f;
    height: 100%;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5); 
    }
  }
  .bannerText{
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`
const TitleImageTextBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  .textBox{
    flex-basis: 35%;
    padding: 5px;
    max-height: 300px;
  }
  .imageBox{
    flex-basis: 64%;
    max-height: 300px;
    overflow: hidden;
    img{
      width: 100%;
    }
  }
`
const ImageTextBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  .textBox{
    padding: 15px;
    flex-basis: 35%;
    min-height: 300px;
    background-color: #2a9e9b;
    color: #fff;
    H3{
      text-transform: uppercase;
      font-weight: bold;
      margin-left: 5px;
    }
    p{
      // text-align: justify;
    }
  }
  .imageBox{
    flex-basis: 65%;
    min-height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }
`
const moveArrow = keyframes`
  0% { left: 0}
  100% { left: 90%}
`
const CourseBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px 10px;
  a{
    color: inherit;
    text-decoration: none;
  }
  img{
    margin: 0;
  }
  .courseitem{
    position: relative;
    flex: 0 0 30%;
    background-color: #2a9e9b;
    color: #fff;
    .itemtext{
      padding: 15px 10px;
      font-weight: bold;
      p{
        margin: 0;
      }
    }
    .itemarrow{
      position: absolute;
      left: 90%;
      bottom: 10%;
      transition: transform 0.8s;
    }
    :hover{
      .itemarrow{
        animation: ${moveArrow} 0.8s forwards
      }
    }
  }
`
const QSBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  .boxitemIMG{
    flex-basis: 20%;
  }
  .boxitemTitle{
    display: flex;
    align-items: center;
    h3{
      text-transform: uppercase;
      font-size: 40px;
      font-weight: bold;
    }
  }
`
const AMImage = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 10px;
  .item{
    flex-basis: 25%;
  }
  a{
    :hover{
      img{
        filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.5));
      }
    }
  }
`
const LogoBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  gap: 10px 15px;
  .itemlogo{
    flex-basis: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      // width: 50%;
    }
  }
`

const TrainingLogos =[
  { img: <img src={ewf} /> },
  { img: <img src={wimanb} /> },
  // { img: <img src={wim} /> },
  // { img: <img src={twi} /> },
  { img: <img src={hrdf} /> },
  { img: <img src={hrdfc} /> },
]

const courses = [
  {
    name: "Basic 3D Printing",
    img : <img src={course05} />,
    link: "/training-basic",
  },
  {
    name: "3D Design (Advanced)",
    img : <img src={course02} />,
    link: "/training-design",
  },
  {
    name: "Advanced 3D Printing",
    img : <img src={course04} />,
    link: "/training-advance",
  },
  {
    name: "Build Your Own 3D Printer",
    img : <img src={course01} />,
    link: "/training-build",
  },
  {
    name: "Customize Your AM Experience",
    img : <img src={course03} />,
    link: "/training-custom",
  },
]

const TrainingPage = () => (
  <Layout>
    <SEO title="Training&Consultation" description="Training&Consultation" />
    <BannerSection id="TrainingPage">
      <div className="bannerImage"></div>
      <div className="bannerText">
        <h3>
         Training & Consultation
        </h3>
        <p>
          3D Printing required a set of knowledge from design, data preparation and printing. <br />
          There are so many post processing and equipment to master before can get a good finished products
        </p>
      </div>
    </BannerSection>
    <div style={{backgroundImage:`url(${TrainingBG})`, backgroundRepeat:"repeat"}}>
      <Container>
        <Section>
          <TitleImageTextBox>
            <div className="textBox">
              <h3>Training</h3>
              <p>
                Getting your hands on a 3D printer might seem like a puzzle at times. 
                Navigating the world of 3D printing requires some key knowledge: 
                knowing how to design, prep your data, and actually do the printing.
                With 3D Gens, we're your guides, taking you from the basics to the advanced techniques in 3D printing. 
                Our training is flexible and tailored to your needs. 
                And we don't just stop there—after training, we're here to help with any questions or concerns you might have. 
              </p>
            </div>
            <div className="imageBox">
              <img src={img01} />
            </div>
          </TitleImageTextBox>
        </Section>
        <Section>
          <ImageTextBox>
            <div className="textBox">
              <h3>Who Should Attend</h3>
              <p style={{fontSize: '22px'}}>
                Designers or engineers working on or investigating 3D printing applications who would like design experience 
                and guidance to fully unlock the potential of AM. 
              </p>
            </div>
            <div className="imageBox" style={{backgroundImage: `url(${img02})`}}>
              {/* <img src={img01} /> */}
            </div>
          </ImageTextBox>
          <br/>
          <ImageTextBox>
            <div className="imageBox" style={{backgroundImage: `url(${img03})`}}>
              {/* <img src={img01} /> */}
            </div>
            <div className="textBox">
              <h3>Learning goals</h3>
              <ul>
                <li>
                  Discover methodologies and workflows of design for AM and apply them to your own projects
                </li>
                <li>
                  Receive feedback from designers and engineers on your own designs
                </li>
                <li>
                  Look at your designs through the eyes of an engineer with experience in design for AM
                </li>
              </ul>
            </div>
          </ImageTextBox>
        </Section>
        <Section id="TrainingCourse">
          <h2>
            TRAINING COURSES
          </h2>
          <CourseBox>
            {courses.map((course) => (
              <div className="courseitem">
                <a href={course.link}>
                  {course.img}
                  <div className="itemtext">
                    Training
                    <p>{course.name}</p>
                  </div>
                  <div className="itemarrow">
                    <FaArrowRight/>
                  </div>
                </a>
              </div>
            ))}
          </CourseBox>
        </Section>
        <Section id="iamqsTraining">
          <QSBox>
            <div className="boxitemIMG">
              <img src={iamqs} />
            </div>
            <div className="boxitemTitle">
              <h3>
                Training International <br/>
                Additive Manufacturing <br/>
                Qualification System 
              </h3>
            </div>
          </QSBox>
          <AMImage>
            <div className="item">
              <a href="/training-amprocess">
                <img src={AM01} />
              </a>
            </div>
            <div className="item">
              <a href="/training-amoperator">
                <img src={AM02} />
              </a>
            </div>
            <div className="item">
              <a href="/training-amdesigner">
                <img src={AM03} />
              </a>
            </div>
          </AMImage>
        </Section>
        <Section>
          <LogoBox>
            {TrainingLogos.map((logo) => (
              <div className="itemlogo">
                {logo.img}
              </div>
            ))}
          </LogoBox>
        </Section>
      </Container>

    </div>
  </Layout>
)

export default TrainingPage

import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from "reactstrap";

const Section = styled.div`
  position: relative;
  padding: 60px 0;
`

const Banner = ({ children, className, id}) => {
  return (
    <Section id={id} className={className}>
      {children}
    </Section>
  )
}

export default Banner

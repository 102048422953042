import React from "react"
import { Link } from "gatsby"
import { useRef } from "react";
import styled from "styled-components";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "nuka-carousel";
import Popup from "reactjs-popup"
import Media from "react-media";
import Moment from "react-moment";


import LinkButtonPrimary from "../components/buttons/LinkButtonPrimary";
import LinkhrefButtonPrimary from "../components/buttons/LinkhrefButtonPrimary";
import BackgroundSection from "../components/BackgroundSection";
import Video from "../components/Video";

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "reactstrap"


import { FaPlay, FaArrowRight, FaFacebookSquare, FaInstagram, FaArrowLeft } from "react-icons/fa";

import video1 from "../images/video 1.jpg"
import video2 from "../images/video 2.jpg"
import video3 from "../images/video 3.jpg"
import videohomepage from "../images/video/videohomepage.mp4"

import combed from "../images/combed.png";
import CardSimple from "../components/CardSimple";

import lisaPro from "../images/frontpanel/lisaPro.png";
import Unisel1 from "../images/training/Unisel/Unisel8.jpg";
import specificImplant from "../images/products/specificImplant.jpg";
import metalproduct from "../images/products/metalProduct.jpg";
import poliproduct from "../images/products/poliProduct.jpg";
import digitalteeth from "../images/digitalteeth.jpg";
import design from "../images/design.png";
import work from "../images/engineeringStory1.jpg";
import work2 from "../images/engineeringStory2.jpg";
import training from "../images/training/training.jpg";
import { useMediaQuery } from "react-responsive";

import ISO9001 from "../images/ISO9001.jpeg"
import ISO13485 from "../images/ISO13485.jpeg"
import gmp from "../images/CAREGMP.png"
import mda from "../images/mda_logo.png";
import mdanew from "../images/MDA(new).png";
import gdplong from "../images/gdpmdlong.png";
import gens3dlogo from "../images/3DGENS_LOGO.png"

import ProductCarousel from "../components/ProductCarousel";
import EventCarousel from "../components/EventCarousel";
import memphis from "../images/memphis.png";
import Logo3dgens from "../images/3DGENS_LOGO.png"
import VerticalSLider from "../components/VerticalSlider";




const Section = styled.section`
  padding: 60px 0;
`
const StyledPopup = styled(Popup)`
  &-content{
    width: 95%;
    height: fit-content;

  }
`
const ButtonCarousel = styled.button`
  padding: 4px 24px;
  margin: 0;
  /* background-color: rgba(0,0,0,0.5); */
  background-color: transparent;
  color: white;
  border: none;
  font-size: 32px;

  :hover{
    /* background-color: rgba(50,50,50,0.5) */
    color: #17a99e;
  }
`
const StoriesBox = styled.div`
  margin: 0 10px;
`
const StoriesFlexBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  .flex-item{
    flex: 1 1 33%;
  }
  @media only screen and (max-width:800px) {
    flex-wrap: wrap;
    .flex-item{
      flex: 1 1 100%;
    }
  }

`
const LinkDiv = styled.div`
  margin: 20px;
`
const MediaContainer = styled.div`
  width: 100%;
  height: 65vh;
  position: relative;
  .videobox{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .eventbox{
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 5%;
    left: 0;
    z-index: 1;
    .image{
      // flex: 1 1;
      img{
        margin-bottom: 10px;
        width: 300px;
        height: auto;
        // transform: scale(0.5);
      }
    }
    .sliderComponent{
      position: absolute;
      top: 0;
      right: 1%;
    }
    .eventdetails{
      // flex: 1 1;
      width: 100%;
      padding: 20px 0;
      border-radius: 15px;
      background-color: rgba(39, 199, 216, 0.5)
    }
  }
`
const ContextBox = styled.div`
  h2{
    text-align: center;
    letter-spacing: 3px;
  }
  p{
    font-size: 15px;
    text-align: center;
    letter-spacing: 1px;
  }
  a{
    color: #0e864e;
    text-decoration: none;
    font-weight: bold;
    :hover{
      color: #1ae99e;
    }
  }
  hr{
    border: 2px solid #1ae99e;
  }
`
const NewsBox = styled.div`
  h2{
    text-align: center;
  }
  .carouselDiv{

  }
  .newsItem{
    margin: 3px;
    padding: 5px;
    img{
      width: 350px;
      height: 250px;
      object-fit: cover;
      margin-bottom: 5px;
    }
    p{
      height: 80px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .datext{
      text-align: right;
      font-size: 10px;
      margin-right: 2px;
    }
    .linkDiv{
      text-decoration: none;
      color: inherit;
      :hover{
        p{
          color: #1ae99e;
        }
      }
    }
  }
  .newsButton{
    text-align: center;
    button{
      border: none;
      background-color: white;
      font-size: 30px;
      margin: 0 150px;
    }
  }

`
const IntroFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  column-gap: 1em;
  .box{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 20px;
    border: solid #ccc;
    color: #fff;
    font-weight: bold;
    font-size: 22px;
  }
  #box1{
    background-color: #2F4F80;
  }
  #box2{
    background-color: #1EAA9F;
  }
  #box3{
    background-color: #27AAE1;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`
const TitleFlexBox = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  column-gap: 1em;
  
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 1em;
  }
`
const TitleFlexBoxContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #323D3E;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  img{
    width: 100%;
    height: 300px;
    object-fit cover;
  }
  a{
    color: unset;
    text-decoration: unset;
    flex: 1;
  }
  p{
    padding: 0 20px;
    font-size: 22px;
    // font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 50px;
  }
  .arrowPos{
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    padding: 0 30px 15px 30px;
    font-size: 24px;
    color: #323D3E;
    margin-top: auto;
    align-self: flex-end;
  }
  :hover{
    background-color: #1EAA9F;
    .arrowPos{
      color: #fff;
    }
  }

  @media (max-width: 768px) {
    flex: 0 1 25%;
    border: 1px solid #ccc;
  }
`
const ResourceFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 1em;
  .resourceContent{
    background-color: #323232;
    color: #fff;
    flex: 1;
    img{
      height: 300px;
      object-fit: cover;
      margin-bottom: 0;
    }
    .contentText{
      padding: 40px 20px;
      a{
        color: unset;
        text-transform: unset;
        text-decoration: none;
        padding: 10px 20px;
        border: 1px solid #fff;
        border-radius: 10px;
        margin-bottom: 10px;
        :hover{
          background-color: #fff;
          color: #323232;
        }
      }
      p{
        // text-align: justify;
      }
    }

  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 1em;
  }

`
const NewsFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 1em;
  .newsContent{
    display: flex;
    flex-direction: column;
    background-color: #323232;
    color: #fff;
    flex: 1;
    img{
      height: 220px;
      margin-bottom: 5px;
    }
    p{
      padding: 10px;
      font-weight: bold;
      font-size: 22px;
      height: 55px;
    }
    span{
      padding: 10px;
      height: 200px;
      overflow: hidden;
      margin-bottom: 20px;
    }
    .morebutton{
      padding: 10px 10px;
      text-align: center;
      text-decoration: none;
      color: #fff;
      background-color: #000;
      :hover{
        color: #323232;
        background-color: #cdcdcd;
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    row-gap: 1em;
  }

`
const CareerDiv = styled.div`
  text-align: center;
  color: #fff;
  h4{
    margin: 50px;
  }
  p{
    margin:50px;
  }
  a{
    margin:50px;
    color: inherit;
    text-decoration: none;
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 10px;
    :hover{
      color: #000;
      background-color: #fff;
    }
  }
`

const titleBoxContent = [
  {
    title : "3D Printers",
    image : lisaPro,
    link : "/printer",
  },
  {
    title : "3D Printing Materials",
    image : poliproduct,
    link : "/metalmaterial",

  },
  {
    title : "Industrial Solutions",
    image : metalproduct,
    link : "/engineering",
  },
  {
    title : "Medical Solutions",
    image : specificImplant,
    link : "/medical",
  },
]

const Resource3dprinting = [
  {
    title : "Training and Consultation",
    image : training,
    description: "Explore our comprehensive training programs led by industry experts. Whether you're a beginner or seasoned professional, our consultations ensure you're equipped with the knowledge and skills to excel in the world of 3D printing.",
    linktitle : "Training Module Sample",
    link : "/resources"
  },
  {
    title : "New to 3D Printing",
    image : design,
    description: "Dive into the exciting world of 3D printing with our beginner-friendly resources. From step-by-step guides to introductory workshops, we'll help you navigate the fundamentals and unleash your creativity.",
    linktitle : "3D Printing Guide",
    link : "/resources"
  },
  {
    title : "Tips & Tricks",
    image : work2,
    description: "Enhance your 3D printing journey with our expert tips and tricks. From troubleshooting common issues to optimizing print settings, discover valuable insights to elevate your projects and achieve exceptional results.",
    linktitle : "Learn More",
    link : "/resources"
  },
]


  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
  }
  
  const IndexPage = ({data}) => {

    const name = (e) => {console.log("click")}
    
    const state = { ytVideo: 'https://www.youtube.com/embed/m1rLWHqXmwA?rel=0&showinfo=1&controls=0' }

    const carouselRef = useRef();

    const nextCarousel = event => {
      event.preventDefault();
      carouselRef.current.nextSlide();
    }
    const prevCarousel = event => {
      event.preventDefault();
      carouselRef.current.previousSlide();
    }

    const sortedNews = data.allStrapiStories.edges.sort((a,b) => b.node.createdAt - a.node.createdAt);
    const latestNews = sortedNews.slice(0, 3);

    return ( 
    <Layout>
    <SEO 
      title="3D Printing Malaysia" 
      description="3D Gens & 3D Printing Technology - with the aim to industrialize 3D printing technology in Malaysia"
    />
    <MediaContainer>
      <div className="videobox">
        <Video src={videohomepage} />
      </div>
      <Default>
        <div className="eventbox">
          <Container className="flexboxjustify">
            {/* <div className="image">
              <img src={Logo3dgens}></img>
            </div> */}
            <div className="eventdetails">
              <h2 style={{textAlign: "center", marginBottom: "20px"}}>Upcoming Events</h2>
              <EventCarousel />
            </div>
          </Container>
        </div>
      </Default>
    </MediaContainer>
    <Mobile>
      <BackgroundSection>
        <div className="eventbox">
          <Container className="flexboxjustify">
            <div className="image">
              <img src={Logo3dgens}></img>
            </div>
            <div className="eventdetails">
              <h2 style={{textAlign: "center", marginBottom: "20px"}}>Upcoming Events</h2>
              <EventCarousel />
            </div>
          </Container>
        </div>
      </BackgroundSection>
    </Mobile>

    <Section>
      <Container>
        <ContextBox>
          <h2>
            Industrialized Additive Manufacturing Technology
          </h2>
          {/* <p>
            Discover unparalleled expertise, comprehensive AM solutions, commitment to quality, 
            customer-centric approach, and innovation.
          </p> */}
          <p>
            At 3D Gens, we are dedicated to pioneering the industrialization of Additive Manufacturing Technology. 
            We envision a future where creativity knows no bounds, and every industry can benefit from the efficiency 
            and innovation enabled by additive processes. We celebrate innovators and engineers as pioneers of creativity. 
            Additive Manufacturing facilitates the realization of their ideas and designs, transforming them from concepts to functional end-use parts. 
            What was once confined to prototyping is now capable of printing fully functional components.
          </p>
          <IntroFlexBox>
            <div className="box" id="box1">
              Speed Up Time-to-Market
            </div>
            <div className="box" id="box2">
              Unlock Design Creativity
            </div>
            <div className="box" id="box3">
              Maximize Cost Efficiency
            </div>
          </IntroFlexBox>
        </ContextBox>
        <TitleFlexBox>
          { titleBoxContent.map((itemContent) => (
          <TitleFlexBoxContent>
            <a href={itemContent.link}>
              <img src={itemContent.image}/>
              <p>{itemContent.title}</p>
              <div className="arrowPos">
                <FaArrowRight/>
              </div>
            </a>
          </TitleFlexBoxContent>
          ))}
        </TitleFlexBox>
      </Container>
    </Section>
    <Section>
      <Container>
        <h2>
          Latest News from 3D Gens
        </h2>
        <NewsFlexBox>
          {latestNews.map((story) => (
            <div className="newsContent">
              <img src={story.node.media.publicURL}/>
              <p>
                {story.node.title}
              </p>
              <span>
                {story.node.summary}
              </span>
              <Link to={`/${story.node.id}`} className="morebutton">
                Read More
              </Link>
            </div>
          ))}
        </NewsFlexBox>
      </Container>
    </Section>
    
    <Section>
      <Container>
        <h2>
          Explore 3D Printing Resources
        </h2>
        <ResourceFlexBox>
          {Resource3dprinting.map((content) => (
            <div className="resourceContent">
              <img src={content.image}/>
              <div className="contentText">
                <h3>
                  {content.title}
                </h3>
                <p>
                  {content.description}
                </p>
                {/* <a href={content.link}>{content.linktitle}</a> */}
              </div>
            </div>
          ))}
        </ResourceFlexBox>
      </Container>
    </Section>
    <Section style={{backgroundColor: "#234678"}}>
      <Container>
        <CareerDiv>
          <h4>
            Join Our Dynamic Team and Shape the Future of Additive Manufacturing
          </h4>
          <p>
            Explore exciting opportunities and grow professionally with the leading additive manufacturing solutions partner.
          </p>
          <a href="/career">
            Careers at 3D Gens
          </a>
        </CareerDiv>
      </Container>
    </Section>

    {/* <Section style={{ backgroundColor: "#fff" }}>
      <Container>
        <NewsBox>
          <h2>
            Latest News fron 3D Gens
          </h2>
          {data.allStrapiStories.edges.filter(stories => stories.node.show == true).length <= 3 ? (
            <div>
              {data.allStrapiStories.edges.filter(stories => stories.node.show == true).map(story => (
                <div>
                  <h3>
                    {story.node.title}
                  </h3>
                </div>
              ))}
            </div>
          ):(
            <div className="carouselDiv">
              <Carousel 
              ref={carouselRef}
              slidesToShow={3}
              slidesToScroll={1}
              // renderCenterLeftControls={({ previousSlide }) => (<ButtonCarousel onClick={previousSlide}><FaArrowLeft/></ButtonCarousel>)} 
              // renderCenterRightControls={({ nextSlide }) => (<ButtonCarousel onClick={nextSlide}><FaArrowRight/></ButtonCarousel>)} 
              width="100%" 
              height="100%" 
              dragging={false} 
              pauseOnHover={true}
              withoutControls={true} 
              autoplay={true} 
              wrapAround={true} 
              swiping={true}>
                  {data.allStrapiStories.edges.filter(stories => stories.node.show == true).map(story => (
                    <div className="newsItem">
                      <Link className="linkDiv" to={`/${story.node.id}`}>
                        <div className="newsImage">
                          {story.node.media ? 
                            <img src={story.node.media.publicURL}/>
                            :
                            // <iframe className="ytvideo" width="100%" height="100%" style={{display: "block", margin: "0px auto"}} src="https://www.youtube.com/embed/1FAQHo7OvQw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            <img src={gens3dlogo}/>
                          }
                        </div>
                        <div className="datext">
                          <Moment format="MM/YYYY" date={story.node.createdAt} />
                        </div>
                        <p>
                          {story.node.title}
                        </p>
                      </Link>
                    </div>
                  ))}
              </Carousel> 
            
          </div>
          )}
        </NewsBox>
      </Container>
    </Section> */}

{/* Success Stories */}
    {/* <Section style={{ backgroundColor:"#fff"}}>
      <Container>
        <h3 style={{textAlign: "center", marginBottom: "40px"}}>Success Stories</h3>
        {data.allStrapiStories.edges.filter(stories => stories.node.show == true).length <= 3 ? (
          <StoriesFlexBox>
            {data.allStrapiStories.edges.filter( stories => stories.node.show == true ).map(story => (
                <div className="flex-item">
                  {story.node.media ? 
                    <CardSimple justifytext imageURL={story.node.media.publicURL} title={story.node.title} description={story.node.summary ? story.node.summary : story.node.description.substr(0, 600)}>
                      <Link className="storylink" to ={`/${story.node.id}`}>Read more <FaArrowRight/></Link>
                    </CardSimple>
                  :
                    <CardSimple justifytext image={<iframe className="ytvideo" width="100%" height="100%" style={{display: "block", margin: "0px auto"}} src="https://www.youtube.com/embed/1FAQHo7OvQw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>} title={story.node.title} description={story.node.summary ? story.node.summary : story.node.description}>
                      <Link className="storylink" to ={`/${story.node.id}`}>Read more <FaArrowRight/></Link>
                    </CardSimple>
                  }
                </div>
              ))
            }
          </StoriesFlexBox>
        ):(
          <Media query="(max-width: 800px)">
          {matches => matches ? (
            // mobile
            <Carousel 
            renderCenterLeftControls={({ previousSlide }) => (<ButtonCarousel onClick={previousSlide}><FaArrowLeft/></ButtonCarousel>)} 
            renderCenterRightControls={({ nextSlide }) => (<ButtonCarousel onClick={nextSlide}><FaArrowRight/></ButtonCarousel>)} 
            width="100%" 
            height="100%"
            slidesToShow={1} 
            dragging={true} 
            pauseOnHover={true}
            withoutControls={false} 
            autoplay={true} 
            wrapAround={true} 
            swiping={true}>
              {data.allStrapiStories.edges.map(document => (
                <StoriesBox>
                  <div>
                    {document.node.media ? 
                      <CardSimple justifytext imageURL={document.node.media.publicURL} title={document.node.title} description={document.node.summary ? document.node.summary : document.node.description}>
                        <Link className="storylink" to ={`/${document.node.id}`}>Read more <FaArrowRight/></Link>
                      </CardSimple>
                    :
                    <CardSimple justifytext image={<iframe className="ytvideo" width="100%" height="100%" style={{display: "block", margin: "0px auto"}} src="https://www.youtube.com/embed/1FAQHo7OvQw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>} title={document.node.title} description={document.node.summary ? document.node.summary : document.node.description}>
                        <Link className="storylink" to ={`/${document.node.id}`}>Read more <FaArrowRight/></Link>
                      </CardSimple>
                    }
                  </div>
                </StoriesBox>
              ))}
            </Carousel>
          ):(
            // desktop
            <Carousel 
            renderCenterLeftControls={({ previousSlide }) => (<ButtonCarousel onClick={previousSlide}><FaArrowLeft/></ButtonCarousel>)} 
            renderCenterRightControls={({ nextSlide }) => (<ButtonCarousel onClick={nextSlide}><FaArrowRight/></ButtonCarousel>)} 
            width="100%" 
            height="100%" 
            slidesToShow={3} 
            dragging={false} 
            pauseOnHover={true}
            withoutControls={false} 
            autoplay={true} 
            wrapAround={true} 
            swiping={true}>
              {data.allStrapiStories.edges.map(document => (
                <StoriesBox>
                  <div>
                    {document.node.media ? 
                      <CardSimple justifytext imageURL={document.node.media.publicURL} title={document.node.title} description={document.node.summary ? document.node.summary : document.node.description}>
                        <Link className="storylink" to ={`/${document.node.id}`}>Read more <FaArrowRight/></Link>
                      </CardSimple>
                    :
                    <CardSimple justifytext image={<iframe className="ytvideo" width="100%" height="100%" style={{display: "block", margin: "0px auto"}} src="https://www.youtube.com/embed/1FAQHo7OvQw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>} title={document.node.title} description={document.node.summary ? document.node.summary : document.node.description}>
                        <Link className="storylink" to ={`/${document.node.id}`}>Read more <FaArrowRight/></Link>
                      </CardSimple>
                    }
                  </div>
                </StoriesBox>
              ))}
            </Carousel>
          )}
          </Media>
        )}
        <LinkDiv>
          <LinkButtonPrimary toLink="/stories">More Stories <FaArrowRight/></LinkButtonPrimary>
        </LinkDiv>
      </Container>
    </Section> */}

    {/* <Section>
      <Container>
        <ContextBox>
          <h2>
            PIONEERING ADDITIVE MANUFACTURING
          </h2>
          <p>
            Discover the world of transformative Additive Manufacturing (AM) with 3D Gens, your ultimate partner for innovation. <br/>
            At 3D Gens, we are dedicated to advancing AM technology and conquering challenges across the AM value chain, bringing you a suite of 
            unparalleled solutions.
            Our accomplished team, comprising engineers and technical specialists, stands ready to illuminate every facet of Additive Manufacturing. 
            Our cutting-edge facilities house an impressive array of 3D printers, encompassing a spectrum from polymers to metals. 
            We proudly offer industry-leading brands such as Renishaw, Ermaksan, Eplus3D (showcasing the impressive Large Metal Printer), Meltio 
            (celebrating WAAM technology), Sinterit, 3DGence, Prusa, Meltio, and more. 
            This includes a range of desktop and educational printers, catering to every level of expertise.<br/>
            <br/>
            Immerse yourself in our digital factory environment, fortified with state-of-the-art software that ensures a seamless and risk-free 
            AM adoption journey. 
            With 3D Gens, you not only gain access to cutting-edge technology but also to our extensive expertise that guides you through 
            the entire process. 
            Embracing a holistic approach to Additive Manufacturing (AM), 3D Gens goes beyond technology offerings to provide a comprehensive 
            training and certification program. 
            As a forward-looking organization, we are actively striving to become an authorized training body 
            for the prestigious International Additive Manufacturing Qualification System (IAMQS), in collaboration with the 
            European Welding Federation (EWF), Welding Institute Malaysia (WIM), and TWI. 
            This initiative reflects our commitment to empowering 
            individuals and industries with the skills and knowledge necessary to master the intricacies of AM.
            Through this program, we aim to equip participants with internationally recognized certifications, 
            ensuring they are well-prepared to excel in the dynamic landscape of additive manufacturing. 
            With 3D Gens, your journey into AM is not just about technology, but also about acquiring the expertise that 
            drives success in this innovative field.
            Enterprises with vision can harness the power of 3D Gens. Dive into the world of AM alongside us—whether it's shaping your debut 
            prototype or embarking on an initial production run. Our adept professionals will guide you, alleviating the need for immediate 
            capital investments.
            <br/><br/>
            Uncover the boundless potential that 3D Gens offers. Experience firsthand how we can reshape your AM ambitions.<br/>
            <a href="/contact">Contact us</a> today and open the door to a future defined by additive innovation and triumph.
          </p>
          <br/>
          <br/>
          <br/>
          <hr/>
        </ContextBox>
      </Container>
    </Section> */}


  </Layout>
)}

export default IndexPage

export const pageQuery = graphql`
                query IndexQuery  {
                  allStrapiStories(sort: {fields: [createdAt], order: DESC}) {
                    edges {
                      node {
                        id
                        title
                        description
                        summary
                        show
                        media{
                          publicURL
                        }
                        createdAt
                      }
                    }
                  }
                  allStrapiBanners{
                    edges{
                      node{
                        title
                        priority
                        delete
                        link
                        externalLink
                        image{
                          publicURL
                        }
                        imageMobile{
                          publicURL
                        }
                        actionButton
                        buttonPosition
                      }
                    }
                  }
                }
`

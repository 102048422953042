import React from 'react'
import styled from 'styled-components'

const Background = styled.div`
  padding: 60px 0;
  background-position: center;
  background-repeat: repeat;
`

const BackgroundSection = ({ children, className, image, color, id}) => {
  return (
    <div style={{backgroundColor: color}}>
      <Background id={id} className={className} style={{backgroundImage: `url(${image})`}}>
        {children}
      </Background>
    </div>
  )
}

export default BackgroundSection

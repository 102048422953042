import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TrainingBody from "../components/TrainingBody"

import TrainingBG from "../images/training/BACKGGROUND1-01.png";
import Banner from "../images/training/Modules/Module05/img01.png";

import img01 from "../images/training/Modules/Module05/img02.png";
import img02 from "../images/training/Modules/Module05/img03.jpg";
import img03 from "../images/training/Modules/Module05/img04.jpg";

const benefitlist = [
  "Tailored Expertise: Customize Training to meet industry-specific needs",
  "Enhanced Efficiency: Streamline operations for improved productivity",
  "Cost-Effective SOlutions: Invest wisely with targered training",
  "Competitive Edge: Stay ahead with advanced skills and innovation",
  "international AM Training option with our AM Industrial strategic partner in Europe, US and ASIA",
  "International Recognition Certification Available",
]

const moduledetails01 = [
  "Leverage our expertise in Addtive Manufacturing (AM) for Medical and Engineering fields.",
  "Gain firsthand insights from our experts that have implement successful 3D Printing Solutions, adresssing unique challenges and innovative applications."
]
const moduledetails02 = [
  "Discover practical insights and case studies from 3D Gens' expertise in AM applications", 
  "Learn how 3D Printing transforms medical deveice manufacturing, prosthetics, and intricate engineering components.",
  "Gain valurable, hands-on knowledge for real-world impact in these industries" 
]
const moduledetails03 = [
  "Through interactive sessions, participants will develop practical skills in 3D Design and troubleshooting.", 
  "This includes honing their ability to create intricate designs and effectively address common challenges encountered in the 3D Printing process.", 
]
const moduledetails04 = [
  "Our training offers comprehensive understanding of digital processes crucial to 3D Printing.", 
  "Empowering participants to efficiently navigate and optimize digital workflows beyond basic concepts", 
]
const moduledetails05 = [
  "Through interactive sessions, participants will develop practical skills in 3D designs and troubleshooting.", 
  "This includes honing their ability to create intricate designs and effectively address common challenges encounteres in the 3D Printing process.", 
]
const trainingDescription = "Experience tailored AM training with industry-relevant content. Our flexible program allows customization to meet evolving sector demands, covering diverse 3D printing technologies and techniques to keep you ahead."


const TrainingAdvancePage = () => (
  <Layout>
    <SEO title="Customize Your AM Training Experience" description="Customize Your AM Training Experience" />
    
    <TrainingBody
      Banner={Banner}
      Title="Customize Your AM Training Experience"
      TitleDesc="Experience the flexibility of Customized AM Training: Embrace Industry-Relevant Learning. Our adaptable training program offers participants the freedom to tailor their learning journey according to industry demands. Explore diverse 3D Printing Technologies and Techniques, ensuring alignment with the evolving needs of your sector. Embrace innovation and stay ahead with our dynamic AM training program."
      BenefitList={benefitlist}
      ModuleList01="Expertise in AM Applications in Medical and Engineering"
      ModuleList02="Case Studies and Practical Insights"
      ModuleList03="Practical Skills in 3D Design and Troubleshooting"
      ModuleList04="In-depth Knowledge of Digital Processes in 3D Printing"
      ModuleList05="Hands-on experience with cutting-edge 3D Printing Technology"
      ModuleListDetails01={moduledetails01}
      ModuleListDetails02={moduledetails02}
      ModuleListDetails03={moduledetails03}
      ModuleListDetails04={moduledetails04}
      ModuleListDetails05={moduledetails05}
      ModuleImg01={img01}
      ModuleImg02={img03}
      ModuleImg03={img02}
      trainingName="Tailored 3D Printing Training Solutions"
      trainingDay="1-5"
      trainingDesc={trainingDescription}
      trainingBrochure="https://drive.google.com/file/d/1h-C_9mr3_8PBei8YIkQKbjzLzwgrBo9U/view?usp=drive_link"
      trainingRegister="https://forms.gle/xRKwFKfzCEpQo3Jk9"
    />

  </Layout>
)

export default TrainingAdvancePage

import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout' 
import { Container } from 'reactstrap'
import styled from 'styled-components'
import Moment from 'react-moment'
import youtube from "../images/youtube-thumbnail.jpg";
import MarkdownIt from 'markdown-it'

const Section = styled.section`
  padding: 60px 0;
`
const Storybox = styled.section`
  min-height: 80vh;
  display: flex;
  flex-wrap: nowrap;
  flex-flow: row;
  .mainbox{
    flex: 1 1 70%;
    margin: 0 20px;
    h1{
      margin: 0;
      color: #235551;
    }
    .imagebox{
      margin: 30px 0;
      display: flex;
      justify-content: center;
      width: 100%;
      height: auto;
      img{
        margin: 0 auto;
      }
    }
    span{
      color: #a0a0a0;
      /* float: right; */
    }
    p{
      white-space: pre-line;
    }
    .contentfooter{
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      padding: 10px;
      .author{
      }
      .date{
        justify-items: end;
      }
    }
  }
  h3{
    text-align: center;
  }
  .morebox{
    flex: 1 1 30%;
    border-top: 5px solid #ccc;
    margin: 0 30px;
    padding: 10px 0;
    .otherbox{
      margin: 20px 5px;
      padding: 5px;
      color: #000;
      text-align: center;
      img{
        width: 300px;
        height: auto;
        object-fit: contain;
        object-position: center;
        margin: 0 auto;
      }
      p{
        padding: 10px;
        margin: 0;
        color: #000;
        text-align: center; 
      }    
      :hover{
        background-color: #17a99e;
        /* border: 1px solid #000;  */
        color: #fff;
      }
    }
  }
  @media only screen and (max-width:800px){
    flex-wrap: wrap;
    .morebox{
      margin-top: 60px;
    }
  }

`
const StoryLink = styled(Link)`
  color: none;
  :hover{
    color: none;
    text-decoration: none;
    text-transform: none;
    p{
      color: #fff;
    }
  }
`

// const md = window.markdownit()
const md = new MarkdownIt()
// const result = md.render('# markdown-it rulezz!');

const StoryTemplate = ({ data }) => (
  <Layout>
    <Section>
      <Container>
        <Storybox>
          <div className="mainbox">
            <h1>{data.strapiStories.title}</h1>
            <div className="imagebox">
              {data.strapiStories.media ? (
                <img src={data.strapiStories.media.publicURL}/>
              ):(
                <iframe className="ytvideo" width="100%" height="500px" style={{display: "block", margin: "0px auto"}} src="https://www.youtube.com/embed/1FAQHo7OvQw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              )}
            </div>
            <div className="contentfooter">
              <span className="author">
                <i>by {data.strapiStories.author}</i>
              </span>
              <span className="date">
                <Moment format="DD/MM/YYYY">
                  {data.strapiStories.createdAt}
                </Moment>
              </span>
            </div>
            <div dangerouslySetInnerHTML={{ __html: md.render(data.strapiStories.description) }}></div>
          </div>
          <div className="morebox">
            <h3>
              Read More
            </h3>
            {data.allStrapiStories.edges.filter(stories => stories.node.id !== data.strapiStories.id).slice(0, 3).map(story => (
                <StoryLink to={`/${story.node.id}`}>
              <div className="otherbox">
                {story.node.media ? (
                  <img src={story.node.media.publicURL} /> 
                ):(
                  <img src={youtube} alt="https://www.youtube.com/embed/1FAQHo7OvQw" />
                  // <iframe className="ytvideo" width="100%" height="100%" style={{display: "block", margin: "0px auto"}} src="https://www.youtube.com/embed/1FAQHo7OvQw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                )}
                <p>
                  {story.node.title}    
                </p>
              </div>
                </StoryLink>
            ))}
          </div>
        </Storybox>
      </Container>
    </Section>
  </Layout>
)

export default StoryTemplate

export const query = graphql`
  query StoryTemplate($id: String!) {
    strapiStories(id: {eq: $id}) {
        id
        title
        description
        show
        createdAt
        author
          media{
          publicURL
        }
    }
    allStrapiStories{
      edges {
        node {
          id
          title
          description
          show
          createdAt
          author
          media{
            publicURL
          }
        }
      }
    }
  }
`


import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TrainingBody from "../components/TrainingBody"

import TrainingBG from "../images/training/BACKGGROUND1-01.png";
import Banner from "../images/training/Modules/Module04/img01.png";

import img01 from "../images/training/Modules/Module04/img02.png";
import img02 from "../images/training/Modules/Module04/img03.png";

const benefitlist = [
  "Hands-On experience on building 3D Printing machine",
  "Personalization of 3D Printing machine",
  "Able to install, troubleshoot and in-house maintenance of 3D Printer",
  "Professionally handling all the technology of 3D Printing machine correctly"
]

const moduledetails01 = [
  "Fundamental of 3D Printer", 
  "Calibrating the 3D Printer", 
  "Installomg the 3D Printer"
]
const moduledetails02 = [
  "Actual case study", 
  "Problem solving on common faulty", 
]
const trainingDescription = "Unleash your creativity: build your own FDM 3D printer with expert guidance. Join our comprehensive course to construct a unique 3D printer, combining printing and electronic engineering skills. Take home your creation and explore limitless possibilities."


const TrainingAdvancePage = () => (
  <Layout>
    <SEO title="Build Your Own 3D Printer" description="Build Your Own 3D Printer" />
    
    <TrainingBody
      Banner={Banner}
      Title="Build Your Own 3D Printer"
      TitleDesc="Unleash Your Inner Maker: Build your own FDM 3D Printer with expert guidance. Join our comprehensive training course and learn to construct your one-of-a-kind 3D Printer. Merge 3D printing expertise with electronic engineering knowledge to bring your ideas to life. Take home your creation and embark on a journey of endless possibilies."
      BenefitList={benefitlist}
      ModuleList01="Installing the 3D Printer"
      ModuleList02="Troubleshooting and Repair the 3D Printer"
      ModuleListDetails01={moduledetails01}
      ModuleListDetails02={moduledetails02}
      ModuleImg01={img01}
      ModuleImg02={img02}
      trainingName="Build Your Own 3D Printer Training"
      trainingDay="2"
      trainingDesc={trainingDescription}
      trainingBrochure="https://drive.google.com/file/d/1UHuoWq92aZS5Ykl_k_01RZAl9S7W4EdX/view?usp=drive_link"
      trainingRegister="https://forms.gle/w23pfnoFafQE2E2z8"
    />

  </Layout>
)

export default TrainingAdvancePage

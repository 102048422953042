import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';

const Button = styled.div`
    /* width: fit-content; */
    text-align: center;
  
  .link{
    color: #fff;
    background-color: #FB222D;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;

  &:hover,
  &:active {
    background-color: #17a99e;
    text-decoration:none;
    color: #000;
  }
  }
`

const LinkButtonPrimary = ({ children, className, toLink }) => {
  return (
    <Button className={className}>
      <Link className="link" to={toLink} >
        {children}
      </Link>
    </Button>
  )
}

export default LinkButtonPrimary

import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components";
import { Container } from "reactstrap";
import { Link } from "gatsby";
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";

const ErrorDiv = styled.div`
  height: 100vh;
  text-align: center;
  padding: 30px 0;
  h1{
    font-size: 150px;
  }
  
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <ErrorDiv>
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <LinkButtonSecondary toLink="/" >Back to Homepage</LinkButtonSecondary>
      </ErrorDiv>
    </Container>
  </Layout>
)

export default NotFoundPage

import React, { useRef } from "react";
import Carousel from "nuka-carousel";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { Link } from "gatsby"

import { FaPlay, FaArrowRight, FaFacebookSquare, FaInstagram, FaArrowLeft } from "react-icons/fa";
import Moment from "react-moment";
import moment from "moment";

import Unisel1 from "../images/training/Unisel/Unisel1.jpg";
import Unisel2 from "../images/training/Unisel/Unisel8.jpg";
import kuskop1 from "../images/training/Program Teknologi Adaptasi KUSKOP-MTDC/training1.jpeg"
import kuskop2 from "../images/training/Program Teknologi Adaptasi KUSKOP-MTDC/training2.jpeg"
import metal1 from "../images/training/Advance Metal Printing Training/metaltraining1.png"
import metal2 from "../images/training/Advance Metal Printing Training/metaltraining2.png"


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

const TrainingPhotos = [
  {
      image: Unisel1,
      title: "3D Printing Technology Training Unisel"
  },
  {
      image: metal1,
      title: "Advance Metal Printing Training"
  },
  {
      image: metal2,
      title: "Advance Metal Printing Training"
  },
]

const ButtonCarousel = styled.button`
  padding: 4px 24px;
  margin: 0;
  /* background-color: rgba(0,0,0,0.5); */
  background-color: transparent;
  color: black;
  border: none;
  font-size: 32px;

  :hover{
    /* background-color: rgba(50,50,50,0.5) */
    color: #17a99e;
  }
`
const CarouselBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 5px 0;
`

const EventItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  // margin: 0 10px;
`

const ContentBox = styled.div`
  flex-direction: column;
  flex: 1;
  width: 100%;
  transition: all .5s;
  img{
    margin: 0;
  }
  p{
    margin: 0;
    text-align: center;
  }
`

const MobileCarouselButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  background-color: #1EAA9F; 
  // background-color: transparent;
  border-radius: 15px;
  button{
    flex: 1;
  }
`
const MobileButtonCarousel = styled.button`
  padding: 4px 24px;
  margin: 0;
  background-color: #1EAA9F; 
  // background-color: #ccc;
  border-radius: 15px;
  color: black;
  border: none;
  font-size: 32px;

  :hover{
    background-color: rgba(50,50,50,0.5);
    color: #17a99e;
  }
`


const EventCarousel = ({}) => {

  const carouselRef = useRef();

  const nextCarousel = event => {
    event.preventDefault();
    carouselRef.current.nextSlide();
  }
  const prevCarousel = event => {
    event.preventDefault();
    carouselRef.current.previousSlide();
  }

    return (
      <div>
        <Default>
          <CarouselBox>
            {/* <ButtonCarousel onClick={prevCarousel}><FaArrowLeft/></ButtonCarousel> */}
            <Carousel 
            ref={carouselRef}
            style={{flex: 1}}
            slidesToShow={1}
            slidesToScroll={1}
            width="100%" 
            height="100%" 
            heightMode="max"
            cellSpacing={10}
            dragging={false} 
            pauseOnHover={true}
            withoutControls={true} 
            autoplay={true} 
            wrapAround={true} 
            swiping={true}>
              {TrainingPhotos.filter(item => item.show !== false).sort(function(a, b){ return (moment(a.date).format('X') - moment().format('X'))-(moment(b.date).format('X') - moment().format('X'))}).map((item, index) => (
                <EventItem>
                  <ContentBox>
                    <img src={item.image}/>
                  </ContentBox>
                </EventItem>
              ))}
            </Carousel>
            {/* <ButtonCarousel onClick={nextCarousel}><FaArrowRight/></ButtonCarousel> */}
          </CarouselBox>
        </Default>


        {/* <Mobile>
          <CarouselBox>
            <Carousel 
            ref={carouselRef}
            style={{flex: 1}}
            slidesToShow={1}
            slidesToScroll={1}
            width="100%" 
            height="100%" 
            heightMode="max"
            dragging={true} 
            pauseOnHover={true}
            withoutControls={true} 
            autoplay={true} 
            wrapAround={true} 
            swiping={true}>
              {TrainingPhotos.filter(item => item.show !== false).sort(function(a, b){ return (moment(a.date).format('X') - moment().format('X'))-(moment(b.date).format('X') - moment().format('X'))}).map((item, index) => (
                <EventItem>
                  <ContentBox>
                    <DateBox>
                      <p className="dayDate">
                        <Moment format="DD" date={item.date} />
                      </p>
                      <p className="monthDate">
                        <Moment format="MMMM" date={item.date} />
                      </p>
                    </DateBox>
                    <TextBox>
                      <p className="eventTitle">{item.title}</p>
                      <p className="eventDetail"><Moment format="DD" date={item.date} />{ item.endDate ? (<> - <Moment format="DD" date={item.endDate} /></>) : ("") }<Moment format=" MMMM YYYY" date={item.date} /></p>
                      <p className="eventDetail">{item.time}</p>
                      <p className="eventDetail">@ {item.venue}</p>
                    </TextBox>
                  </ContentBox>
                </EventItem>
              ))}
            </Carousel>
          </CarouselBox>
          <MobileCarouselButtons>
            <MobileButtonCarousel onClick={prevCarousel}><FaArrowLeft/></MobileButtonCarousel>
            <MobileButtonCarousel onClick={nextCarousel}><FaArrowRight/></MobileButtonCarousel>
          </MobileCarouselButtons>
        </Mobile> */}
      </div>
    )
}

export default EventCarousel
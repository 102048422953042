import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';

const ButtonLink = styled(Link)`
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  background-color: #235551;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover,
  &:active{
    background-color: #17a99e;
    text-decoration: none;
    color: #000;
  }
`

const LinkButtonSecondary = ({ children, className, toLink, href, style}) => {
  return (
    // <Button className={className}></Button>
    <ButtonLink className={className} to={toLink} href={href} style={style}>
        {children}
    </ButtonLink>
  )
}

export default LinkButtonSecondary

import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";

import Banner from "../components/Banner"
import PhotoCarousel from "../components/PhotoCarousel"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Unisel1 from "../images/training/Unisel/Unisel1.jpg";
import Unisel2 from "../images/training/Unisel/Unisel2.jpg";
import Unisel3 from "../images/training/Unisel/Unisel3.jpg";
import Unisel4 from "../images/training/Unisel/Unisel4.jpg";
import Unisel5 from "../images/training/Unisel/Unisel5.jpg";
import Unisel6 from "../images/training/Unisel/Unisel6.jpg";
import Unisel7 from "../images/training/Unisel/Unisel7.jpg";
import Unisel8 from "../images/training/Unisel/Unisel8.jpg";
import Consult3 from "../images/training/consult2.png";
import Consult from "../images/banner/consultbanner.png";

import TrainerBanner from "../images/training/training.jpg"


const Section = styled.section`
  padding: 60px 0;
`
const BannerSection = styled.div`
  height: 50vh;
  position: relative;
  .bannerImage{
    background-image: url(${Consult});
    background-size: cover;
    background-color: #0f0f0f;
    height: 100%;
    // position: relative;
  }
  .bannerText{
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`
const TnC2 = styled.div`
  padding: 20px 0;
  margin: 100px 10px;
`

const trainings = [
  Unisel1,Unisel2,Unisel3,Unisel4,Unisel5,Unisel6,Unisel7,
]

const ConsultPage = () => (
  <Layout>
    <SEO title="Project Consultation" description="Project Consultation" />
    <BannerSection>
      <div className="bannerImage"></div>
      <div className="bannerText">
        <h3>
        3D Printing Project Consultation
        </h3>
        <p>
          "3D Printing required a set of knowledge from design, data preparation and printing.<br/>
          There are so many post processing and equipment to master before can get a good finished products"
        </p>
      </div>
    </BannerSection>

    <Section>
      <Container>
        <TnC2>
          <h3>Consultation</h3>
          <Row>
            <Col xs="12" md="6">
              <p>
                Picking the Right Fit<br/>
                When it comes to 3D printers, nailing the perfect match for your purpose and design is a game-changer for optimal results. 
                The journey to acquiring a 3D printer demands careful consideration of several factors, including size, surface quality, volume, and the very purpose it will serve.<br/>
                <br/>
                Leveraging our extensive background in rapid prototyping and manufacturing, we're equipped to steer you toward the 3D printer that fits like a glove. 
                Rely on our years of experience to guide you confidently, ensuring you make a choice that's spot-on.
              </p>
            </Col>
            <Col xs="12" md="6">
              {/* <PhotoCarousel/> */}
              <img src={Consult3}/>
            </Col>
          </Row>
        </TnC2>
      </Container>
    </Section>
  </Layout>
)

export default ConsultPage

import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";

import { FaPlay, FaArrowRight, FaFacebookSquare, FaInstagram, FaArrowLeft } from "react-icons/fa";

import Layout from "../components/layout"
import SEO from "../components/seo"
import gens3d from "../images/3DGENS_LOGO.png"

import Banner from "../components/Banner";
import Section from "../components/Section";
import BackgroundSection from "../components/BackgroundSection";
import CardBorder from "../components/CardBorder";
import ButtonPlain from "../components/buttons/ButtonPlain";

import lisaP from "../images/lisa-printer.png";
import lisaPPro from "../images/lisapro-printer.png";
import centricP from "../images/photocentric.png";
import renAM from "../images/RenAM-500E.jpg";
import genceP from "../images/3dgence-printer.png";
import gencePD from "../images/3dgence-printer-double.png";
import gencePI from "../images/3dgence-printer-industry.png";
import gence420 from "../images/gence420.jpg";
import gence340 from "../images/gence340.jpg";
import gence421 from "../images/gence421.png";
import gence350 from "../images/gence350.png";
import genceMMS from "../images/genceMMS.png";
import m3max from "../images/printer/m3max.png";
import mega8k from "../images/printer/mega8k.png";

import ultimakerblur from "../images/printer.jpg";
import printerbanner from "../images/banner/page-banner/banner-printers.png";
import printerbannerv2 from "../images/banner3dgensprinter.png";
import printerbannermobile from "../images/banner/page-banner/mobile/banner-printers-mobile.png";
import combed from "../images/combed.png";
import { graphql } from "gatsby";
import CardSimple from "../components/CardSimple";

const PrinterInfoBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly; 
  .flexbox{
    flex: 0 0 33%;
    margin: 0 10px;
  }
  .storydesc{
    font-weight: bold;
  }
  @media only screen and (max-width:800px) {
    flex-wrap: wrap;
    .flexbox{
      flex: 1 1 90%;
    }
  }
`
const PrinterBox = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: 50% 50%;
  gap: 10px;
  
  .imageBox{
    height: 20rem;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

`
const GridLayerBox = styled.div`
  display: grid;
  grid-template: 1fr / 1fr;

  * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
  .itemPrinter{
    z-index: 2;
    padding: 1rem;
    border: 1px solid #1ae99e;
    background: #fff;
    
  }
  .itemSpec{
    z-index: 1;
    padding: 1rem;
    border: 1px solid #1ae99e;
    background: #ccc;
    h5{
      text-align: center;
    }
  }
  :hover{
    .itemPrinter{
      visibility: hidden;
    }
  }
`
const StatisticCorner = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 20px 100px;
  color: #fff;
  background-color: #309CA3;
  p{
    font-size: 18px;
  }
  .boxtext{
    padding: 50px 20px;
    flex: 2;
    text-align: left;
    border-right: 1px solid #fff;
    h5{
      margin-bottom: 10px;
      font-size: 22px;
    }

  }
  .boxnumber{
    padding: 50px 20px;
    flex: 1;
    text-align: center;
    border-right: 1px solid #fff;
    h3{
      font-size: 70px;
    }
  }
  .boxnumber:last-child{
    border: none;
  }

  @media(max-width: 768px) {
    display: none;
  }
`
const TitleFlexBox = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  column-gap: 1em;
`
const TitleFlexBoxContent = styled.div`
  // flex: 1;
  width: 300px;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #323D3E;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  img{
    height: 300px;
    width: 100%;
    object-fit cover;
  }
  a{
    color: unset;
    text-decoration: unset;
    flex: 1;
  }
  p{
    padding: 0 20px;
    font-size: 22px;
    font-family: 'sans', sans-serif;
    margin-bottom: 50px;
  }
  .arrowPos{
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    padding: 0 30px 15px 30px;
    font-size: 24px;
    color: #323D3E;
    margin-top: auto;
    align-self: flex-end;
  }
  :hover{
    background-color: #1EAA9F;
    .arrowPos{
      color: #fff;
    }
  }
`
const titleBoxContent = [
  {
    title : "Metal Printers",
    image : renAM,
    link : "/metalprinter",
  },
  {
    title : "Polymer Printers",
    image : gence420,
    link : "/polyprinter",

  },
]
const printerDetails = [
  {
    image : <img src={renAM}></img>,
    title : "Renishaw RenAM 500",
    description: "Leading gas flow system, precision dynamic control and software for connected digital workflows.",
  },
  {
    image : <img src={gence420}></img>,
    title : "3DGence Industry F420",
    description: "Flexibility and performance with job-specific printing modules and predefined printing profiles.",
  },
  {
    image : <img src={mega8k}></img>,
    title : "PHROZEN SONIC MEGA 8K",
    description: "Breaks every single limitation for commercial 3D printers with now can have both size and quality in one print.",
  },
  {
    image : <img src={lisaPPro}></img>,
    title : "Sinterit Lisa Pro",
    description: "A professional SLS 3D printer with an open environment and access to a wide range of materials.",
  },
]

const printerSpecification = [
  {
    image : gence421,
    title : "3DGence Industry F421",
    description : "",
    specification : <p>
      Technology : FFF - Fused Filament Fabrication <br/><br/>
      Build Volume : 380 x 380 x 420 mm <br/><br/>
      Printhead : 2 with printhead purging system <br/><br/>
      Active Heating Chamber up to 195 °C <br/>
    </p>,
  },
  {
    image : gence340,
    title : "3DGence Industry F340",
    description : "",
    specification : <p>
    Technology : FFF - Fused Filament Fabrication <br/><br/>
    Build Volume : 260 x 300 x 340 mm <br/><br/>
    Printhead : 2 per module <br/><br/>
    Active Heating Chamber up to 85 °C <br/>
  </p>,
  },
  {
    image : gence350,
    title : "3DGence Industry F350",
    description : "",
    specification : <p>
    Technology : FFF - Fused Filament Fabrication <br/><br/>
    Build Volume : 340 x 340 x 350 mm <br/><br/>
    Printhead : 2 with printhead purging system <br/><br/>
    Active Heating Chamber up to 140 °C <br/>
  </p>,
  },
  {
    image : genceMMS,
    title : "3DGence Material Management System",
    description : "",
    specification : <p>
    Automatic Material Preparation <br/><br/>
    Multi Material Storage <br/><br/>
    High Temperature Part Annealing <br/>
  </p>,
  },
  {
    image : lisaPPro,
    title : "Sinterit Lisa Pro",
    description : "",
    specification : <p>
    Technology : SLS - Selective Laser Sintering <br/><br/>
    Build Volume : 110 x 160 x 245 mm <br/><br/>
    Laser System : IR Laser Diode 5 [W] ; λ =808 [nm] <br/><br/>
    Built-in Inert Gas Control System
  </p>,
  },
  {
    image : lisaP,
    title : "Sinterit Lisa",
    description : "",
    specification : <p>
    Technology : SLS - Selective Laser Sintering <br/><br/>
    Build Volume : 150 x 200 x 150 mm <br/><br/>
    Laser System : IR Laser Diode 5 [W] ; λ =808 [nm] <br/>
  </p>,
  },
]

const PrinterPage = ({ data }) => (
  <Layout>
    <SEO title="3DPrinter" />
    <Banner src={printerbannerv2} alt="banner" position="left">
      <h3>
        Bring Your Design to Reality <br/>with 3D Printing Technology
      </h3>
      <p>
        Showcase Your Project with Style
      </p>
    </Banner>
      <StatisticCorner>
        <div className="boxtext">
          <h5>
            From concept to creation in the blink of an eye
          </h5>
          <p>
            3D printing revolutionizes manufacturing overnight. Embrace innovation, accelerate production, 
            and redefine possibilities with our cutting-edge 3D printing solutions.
          </p>
        </div>
        <div className="boxnumber">
          <h3>
            10+
          </h3>
          <p>
            Global Partners<br/>cooperate over last decade
          </p>
        </div>
        <div className="boxnumber">
          <h3>
            50+
          </h3>
          <p>
            3D printing<br/>materials available
          </p>
        </div>
        <div className="boxnumber">
          <h3>
            100+
          </h3>
          <p>
            Production parts produced monthly<br/>by our customers
          </p>
        </div>
      </StatisticCorner>
      <Section>
        <Container>
          <h2 style={{color: "#000"}}>
            3D Printers
          </h2>
          <p>
            We are the authorised distributor for a wide range of 3D printers addressing the diverse technologies used in 3D printing from various reputable manufacturers. 
            The 3D printing technologies range from Fused Filament Fabrication, Daylight Polymer Printing and Selective Laser Sintering. 
            We are currently adding on to our stable of 3D printing solutions offering.
          </p>
          <br/>
          <br/>
          <br/>
          <h2 style={{textAlign: "center"}}>
            Types of 3D Printer
          </h2>
          <TitleFlexBox>
            { titleBoxContent.map((itemContent) => (
              <TitleFlexBoxContent>
              <a href={itemContent.link}>
                <img src={itemContent.image}/>
                <p>{itemContent.title}</p>
                <div className="arrowPos">
                  <FaArrowRight/>
                </div>
              </a>
            </TitleFlexBoxContent>
            ))}
          </TitleFlexBox>
        </Container>
      </Section>
      <BackgroundSection>
        <Container>
          <h3 style={{textAlign: "center"}}>
            Featured 3D Printers
          </h3>
        {/* </Container>
      </BackgroundSection>
      <BackgroundSection image={combed}>
        <Container> */}
          <PrinterInfoBox>
            { printerDetails.map((printer) => (
              <div className="flexbox">
                <CardSimple image={printer.image} title={printer.title} description={printer.description} />
              </div>
            )) }
          </PrinterInfoBox>
        </Container>
      </BackgroundSection>
      {/* <BackgroundSection image={combed} color="#d8d8d8" id="printer">
        <Container>
          <h3 style={{color: "#000"}}>
            3D Printing Products
          </h3>
        <PrinterBox>
            {printerSpecification.map((printer) => (
                <GridLayerBox>
                  <div className="itemPrinter">
                    <h4>
                      {printer.title}
                    </h4>
                    <div className="imageBox">
                      <img src={printer.image}/>
                    </div>
                  </div>
                  <div className="itemSpec">
                    <h5>
                      {printer.title}<br/>
                      Specification
                    </h5>
                    {printer.specification}
                  </div>
                </GridLayerBox>
            ))}
        </PrinterBox>
        </Container>
      </BackgroundSection> */}
  </Layout>
)

export default PrinterPage

export const pageQuery = graphql`
                    query PrinterQuery  {
                      allStrapiPrinters{
                        edges {
                          node {
                            name
                            url
                            image{
                              publicURL
                            }
                          }
                        }
                      }
                    }
`
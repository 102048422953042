/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby"
import fontawesome from "react-fontawesome";
import Helmet from 'react-helmet'

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import "./color.css"
import { FaWhatsapp } from "react-icons/fa";

const Content = styled.div`
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 0;
  h3{
    margin-bottom: 20px;
  }
  p{
    font-size: 16px;
  }
  h4{
    text-align: center;
  }
`
const Sidebutton = styled.div`
    position: fixed;
    top: 20%;
    right: -155px;
    z-index: 10;
    transition: 0.3s;
    padding: 10px;
    width: 200px;
    text-decoration: none;
    font-size: 20px;
    color: #fff;
    background-color: #17a99e;
    border-radius: 5px 0 0 5px;
    :hover{
      text-decoration: none;
      right: 0;
    }
    svg{
      font-size: 24px;
      margin: auto;
    }
    a{
      color: white;
      text-decoration: none;
      :hover{
        text-decoration: none;
        color: #e1e1e1;
      }
    }
    span{
      margin-left: 15px;
    }
`

const googleStructuredData = `
{ 
  "@context" : "http://schema.org",
  "@type" : "Organization",
  "@id": "https://3dgens.com",
  "name" : "3D Gens Sdn Bhd",
  "url" : "https://3dgens.com",
  "image": [
    "https://3dgens.com/static/3dgens-office-full-building.png",
    "https://3dgens.com/static/3d-printing-consultation-and-training.jpg"
  ],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "18 Jln Kerawang U8/108 Perindustrian Tekno Jelutong, Seksyen U8 Shah Alam",
    "addressLocality": "Shah Alam",
    "addressRegion": "Selangor",
    "postalCode": "40150",
    "addressCountry": "MY"
  },
  "openingHours": "Mo,Tu,We,Th,Fr 08:30-17:30",
  "telephone": "+60378327140",
  "openingHoursSpecification": [
    {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday"
      ],
      "opens": "08:30",
      "closes": "17:30"
    }
  ],
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 3.099644,
    "longitude": 101.546841
  },
  "sameAs" : [ 
    "https://www.facebook.com/3dgensmalaysia/",
    "https://www.instagram.com/3dgens/",
    "https://www.linkedin.com/company/3d-gens-sdn-bhd/"
  ] 
}
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
        <script type="application/ld+json">
          {googleStructuredData}
        </script>
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Content>
          
          <main>
          <Sidebutton>
            <a href="https://wa.me/60105583070" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp/> <span>Whatsapp</span>
            </a>
          </Sidebutton>
            {children}
          </main>
        </Content>
        <Footer />
          
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from 'react'
import styled from 'styled-components'
import Media from 'react-media'

import { Container, Row, Col } from "reactstrap";


import TrainingBG from "../images/training/BACKGGROUND1-01.png";


const Section = styled.section`
  padding: 60px 0;
`
const BannerSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 50vh;
  .bannerText{
    flex-basis: 55%;
    background-color: #2a9e9b;
    display: flex;
    align-items: center;
    justify-content: end;
    .textArea{
      margin: 40px;
      color: #fff;
    }
  }
  .bannerImage{
    flex-basis: 45%;
    background-size: cover;
    background-position: 100% 50%;
    background-color: #0f0f0f;
    height: 100%;
  }
`
const BenefitBox = styled.div`

`
const ModuleBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  .text{
    flex-basis: 50%;
    p{
      font-weight: bold;
    }
  }
  .image{
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    img{
      height: 300px;
      object-fit: cover;
      object-position: 50% 35%;
    }
  }
`
const ReturnButton = styled.div`
  text-align: center;
  a{
    border: 1px solid #0f0f0f;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
    background-color: #00c3f3;
    :hover{
      background-color: #0f0f0f;
      border-color: #00c3f3;
      color: #fff;
    }
  }
  `
const RegisterDiv = styled.div`
  h3{
    margin: 0;
  }
  span{
    font-size: 10px;
  }
  p{

  }
  a{
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000;
    padding: 10px 30px;
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
    text-transform: none;
    :hover{
      background-color: #ccc;
      color: #000;      
    }
  }
`

const TainingBody = ({ Title, TitleDesc, Banner, BenefitList, ModuleList01, ModuleListDetails01, ModuleList02, ModuleListDetails02, ModuleList03, ModuleListDetails03, ModuleList04, ModuleListDetails04, ModuleList05, ModuleListDetails05, ModuleImg01, ModuleImg02, ModuleImg03, trainingName, trainingDay, trainingDesc, trainingBrochure, trainingRegister} ) => {
  return (
    <div>
      <BannerSection>
        <div className="bannerText">
          <div className="textArea">
            <h3>
              {Title}
            </h3>
            <p>
              {TitleDesc}
            </p>
          </div>
        </div>
        <div className="bannerImage" style={{backgroundImage: `url(${Banner})`}}/>
      </BannerSection>
      <div style={{backgroundImage:`url(${TrainingBG})`, backgroundRepeat:"repeat"}}>
        <Container>
          <Section>
            <BenefitBox>
              <h3>
                Your Benefits
              </h3>
              {BenefitList === 1 ? (
                {BenefitList}
              ):(
                <ul>
                  {BenefitList.map((item) => (
                    <li>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </BenefitBox>
          </Section>
          <Section>
            <h3>
              Module Content
            </h3>
            <ModuleBox>
              <div className="text">
                <div>
                  {/* {ModuleList} */}
                  <p>
                    Unit 1: {ModuleList01}
                  </p>
                  {ModuleListDetails01 === 1 ? (
                    <div>{ModuleListDetails01}</div>
                    ) : (
                    <ul>
                      {ModuleListDetails01.map((details) => (
                        <li>
                          {details}
                        </li>
                      ))}
                    <br/>
                    </ul>
                  )}
                </div>
                <div>
                  {/* {ModuleList} */}
                  <p>
                    Unit 2: {ModuleList02}
                  </p>
                  {ModuleListDetails02 === 1 ? (
                    <div>{ModuleListDetails02}</div>
                    ) : (
                    <ul>
                      {ModuleListDetails02.map((details) => (
                        <li>
                          {details}
                        </li>
                      ))}
                    <br/>
                    </ul>
                  )}
                </div>
                {ModuleList03 ? (
                  <div>
                    {/* {ModuleList} */}
                    <p>
                      Unit 3: {ModuleList03}
                    </p>
                    {ModuleListDetails03 === 1 ? (
                      <div>{ModuleListDetails03}</div>
                      ) : (
                      <ul>
                        {ModuleListDetails03.map((details) => (
                          <li>
                            {details}
                          </li>
                        ))}
                      <br/>
                      </ul>
                    )}
                  </div>
                ): null }
                {ModuleList04 ? (
                  <div>
                    {/* {ModuleList} */}
                    <p>
                      Unit 3: {ModuleList04}
                    </p>
                    {ModuleListDetails04 === 1 ? (
                      <div>{ModuleListDetails04}</div>
                      ) : (
                      <ul>
                        {ModuleListDetails04.map((details) => (
                          <li>
                            {details}
                          </li>
                        ))}
                      <br/>
                      </ul>
                    )}
                  </div>
                ): null }
                {ModuleList05 ? (
                  <div>
                    {/* {ModuleList} */}
                    <p>
                      Unit 3: {ModuleList05}
                    </p>
                    {ModuleListDetails05 === 1 ? (
                      <div>{ModuleListDetails05}</div>
                      ) : (
                      <ul>
                        {ModuleListDetails05.map((details) => (
                          <li>
                            {details}
                          </li>
                        ))}
                      <br/>
                      </ul>
                    )}
                  </div>
                ): null }

              </div>
              <div className="image">
                <img src={ModuleImg01} />
                <img src={ModuleImg02} />
                {ModuleImg03 ? <img src={ModuleImg03} /> : null}
              </div>
            </ModuleBox>

          </Section>
          {
            trainingName ? (
              <Section>
                <RegisterDiv>
                  <h3>{trainingName}</h3>
                  <span>{trainingDay}-Day Training</span>
                  <p>{trainingDesc}</p>
                  <a href={trainingBrochure} target="_blank" rel="nooperner noreferrer">Brochure</a>
                  <a href={trainingRegister} target="_blank" rel="nooperner noreferrer">Register Here!</a>
                </RegisterDiv>
              </Section>
            ) : null
          }
          
          <Section>
            <ReturnButton>
              <a href='/training/#TrainingCourse'> Return to Page </a>
            </ReturnButton>
          </Section>
        </Container>

      </div>
    </div>
  )
}

export default TainingBody

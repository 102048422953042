import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

import TrainingBG from "../images/training/BACKGGROUND1-01.png";
import Banner from "../images/training/Modules/Module01/img01.jpg";

import img01 from "../images/training/Modules/Module01/img02.jpg";
import img02 from "../images/training/Modules/Module01/img03.jpg";

const Section = styled.section`
  padding: 60px 0;
`
const BannerSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 50vh;
  .bannerText{
    flex-basis: 55%;
    background-color: #2a9e9b;
    display: flex;
    align-items: center;
    justify-content: end;
    .textArea{
      margin: 40px;
      color: #fff;
    }
  }
  .bannerImage{
    flex-basis: 45%;
    background-image: url(${Banner});
    background-size: cover;
    background-position: 100% 50%;
    background-color: #0f0f0f;
    height: 100%;
  }
`
const BenefitBox = styled.div`

`
const ModuleBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  .text{
    flex-basis: 50%;
    p{
      font-weight: bold;
    }
  }
  .image{
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    img{
      height: 300px;
      object-fit: cover;
      object-position: 50% 35%;
    }
  }
`
const ReturnButton = styled.div`
  text-align: center;
  a{
    border: 1px solid #0f0f0f;
    border-radius: 10px;
    padding: 10px 20px;
    text-decoration: none;
    color: #000;
    background-color: #00c3f3;
    :hover{
      background-color: #0f0f0f;
      border-color: #00c3f3;
      color: #fff;
    }
  }
  `
const RegisterDiv = styled.div`
  h3{
    margin: 0;
  }
  span{
    font-size: 10px;
  }
  p{

  }
  a{
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #000;
    padding: 10px 30px;
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
    text-transform: none;
    :hover{
      background-color: #ccc;
      color: #000;      
    }
  }
`

const TrainingBasicPage = () => (
  <Layout>
    <SEO title="Basic 3D Printing Training" description="Basic 3D Printing Training" />
    <BannerSection>
      <div className="bannerText">
        <div className="textArea">
          <h3>
            Basic 3D Printing Training
          </h3>
          <p>
            Master the Art of 3D Printing with our Comprehensive Training Course! <br/>
            Gain a Solid Foundation to Start Your Additive Manufacturing Journey. <br/>
            Uncover the Limitless Possibilities of Additive Manufacturing, <br/>
            Discover the Right Technologies for Your Needs, and Be Equipped to <br/>
            Dive into the World of 3D Printing with Confidence!
          </p>
        </div>
      </div>
      <div className="bannerImage"></div>
    </BannerSection>
    <div style={{backgroundImage:`url(${TrainingBG})`, backgroundRepeat:"repeat"}}>
      <Container>
        <Section>
          <BenefitBox>
            <h3>
              Your Benefits
            </h3>
            <ul>
              <li>
                Understand the trends that drive additive manufacturing.
              </li>
              <li>
                Get a technolpgy overview for polymer and metal AM while learning how to select the right technology.
              </li>
              <li>
                Identify the potential of additive manufacturing in your business and learn how to identify the right parts.
              </li>
              <li>
                Get to know the possibilities and restrictions in design and material usage.
              </li>
            </ul>
          </BenefitBox>
        </Section>
        <Section>
          <h3>
            Module Content
          </h3>
          <ModuleBox>
            <div className="text">
              <div>
                <p>
                  Unit 1: Introduction to Additive Manufacturing (AM).
                </p>
                <ul>
                  <li>
                    Explaination of the fundamental principles of AM processes.
                  </li>
                  <li>
                    Types of AM Technologies.
                  </li>
                  <li>
                    Applications of Additive Manufacturing.
                  </li>
                </ul>
                <br/>
              </div>

              <div>
                <p>
                  Unit 2: Data Preparation & Calibrating
                </p>
                <ul>
                  <li>
                    Introduction to Data Preparation
                  </li>
                  <li>
                    Screeing & Analysis of Parts
                  </li>
                  <li>
                    Preparations of Individual Parts
                  </li>
                </ul>
                <br/>
              </div>

              <div>
                <p>
                  Unit 3: Handling, Operating & Post Processing
                </p>
                <ul>
                  <li>
                    Introduction to 3D Printer-Handling
                  </li>
                  <li>
                    Introduction of quality assurance and post-processing methods
                  </li>
                </ul>
                <br/>
              </div>
            </div>
            <div className="image">
              <img src={img01} />
              <img src={img02} />
            </div>
          </ModuleBox>

        </Section>
        
        <Section>
          <RegisterDiv>
            <h3>Basic 3D Printer Training</h3>
            <span>1-Day Training</span>
            <p>Explore additive manufacturing trends, technology overviews for polymer and metal, 
              and how to choose the right tech. <br/>Identify business potential and 
              understand design and material constraints.</p>
            <a href="https://drive.google.com/file/d/1SFdNwTf2N0q8wQ7_gwWXJhNQ8yeM_C5W/view?usp=drive_link" target="_blank" rel="nooperner noreferrer">Brochure</a>
            <a href="https://forms.gle/2mt8HSTPC82wFEwVA" target="_blank" rel="nooperner noreferrer">Register Here!</a>
          </RegisterDiv>
        </Section>

        <Section>
          <ReturnButton>
            <a href='/training/#iamqs'> Return to Page </a>
          </ReturnButton>
        </Section>
      </Container>

    </div>
  </Layout>
)

export default TrainingBasicPage

import React, { useState } from "react"
import { Button, Form, FormGroup, Label, Input, Container, Row, Col, Alert } from "reactstrap";
import styled from "styled-components";
import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/Banner";
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";
import BackgroundSection from "../components/BackgroundSection";
import ButtonSecondary from "../components/buttons/ButtonSecondary";


import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import Carousel from "nuka-carousel";
import Media from "react-media";
// import Popup from "reactjs-popup";

import metalprint2 from "../images/banner-metalprint-2.png";
import metals01 from "../images/metal/metals01.png";
import metal01 from "../images/metal/metal01.png";
import metal02 from "../images/metal/metal02.png";
import metal03 from "../images/metal/metal03.png";
import metal04 from "../images/metal/metal04.png";
import metal05 from "../images/metal/metal05.png";
import metal06 from "../images/metal/metal06.png";

const BackgroundSectionDiv = styled.div`
  position: relative;
  z-index: 0;
  /* background-color: #00CCFF; */
  height: 100vh;
  width: 100vw;
  @media only screen and (max-width:600px) {
    height: 100%;
  }
`
const Box1 = styled.div`
`
const Box2 = styled.div`
  position: absolute;
  z-index: 2;
  width: 100vw;
  bottom: 50px;
  @media only screen and (max-width:600px) {
    position: relative;
    z-index: 0;
    /* bottom: 10px; */
  }
`

const BannerLike = styled.div`
  background-image: url(${metals01});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
  height: 60vh;
  
`

const FormBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 40%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;
  @media only screen and (max-width:600px) {
    width: 100%;
  }
`
const TitleBox = styled.div`
  flex: 0 0 100%;
  padding: 0 20px;
  @media only screen and (max-width:600px) {
    width: 100%
  }
`
const AlertArea = styled.div`
  flex: 0 0 100%;
  padding: 0 20px;
  @media only screen and (max-width:600px) {
    width: 100%
  }
`
const FormArea = styled.div`
  flex: 0 0 100%;
  form{
    margin: 0;
    }
  }
  @media only screen and (max-width:600px) {
    width: 100%
  }
`
const ButtonCarousel = styled.button`
  padding: 4px 24px;
  margin: 0;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 32px;

  :hover{
    color: #17a99e;
  }
`
const metalImages = [
  {
    image : metal01,
    alt : "metal01",
  },{
    image : metal02,
    alt : "metal02",
  },{
    image : metal03,
    alt : "metal03",
  },{
    image : metal04,
    alt : "metal04",
  },{
    image : metal05,
    alt : "metal05",
  },{
    image : metal06,
    alt : "metal06",
  },
]

const MetalPrintFormPage = () => {

    const [name, setName] = useState('')
    const [email, setEMail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [file, setFile] = useState('')
  
    const [sentMessage, setSentMessage] = useState(false)
    const [error, setError] = useState('')
  
    const submitPrint = event => {
      event.preventDefault()
      setError('')
      console.log(`---`)
      const submissionData = {name, email, phone, message, file}
      console.log(submissionData)
      if (name === '' || email === '' || phone === '' || message === '') {
        setError(`You must fill name, email and message.`)
        return
      }
      fetch(process.env.GATSBY_API_URL+'/metalprints', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, phone, message, file })
      })
        .then(response => {
          console.log(response)
          if (!response.ok) {
            setError(`Error ${response.status}`)
            throw new Error(response.body)
          }
          setSentMessage(true)
          setError('')
        })
        .then(() => {
          setName('')
          setEMail('')
          setPhone('')
          setMessage('')
        })
        .catch(err => {
          console.log(err)
          setError(err)
        })
    }
  
    return (
    <Layout>
      <SEO title="MetalPrint" />
        {/* <Banner classNamed="about-banner" src={metalprint2} alt="banner">
        </Banner> */}
      <BackgroundSectionDiv>
        <Box1>
          <BannerLike/>
        </Box1>
        <Box2>
          <FormBox>
              <TitleBox>
                <div className="title-div">
                  <h3>3D Metal Print</h3>
                  <p>Send us your details at <b>info@3dgens.com</b> or call <b>+60378327140</b>.<br/>We would come back to you as soon as possible.</p>
                  <p></p>
                </div>
              </TitleBox>
              <AlertArea>
                <div className="error-alert">
                  {error === '' ? '' :<Alert color="danger">{error}</Alert>}
                  <Alert style={{display: sentMessage? 'block': 'none'}} color="success">
                  Your request is successfully sent
                  <br/>
                  <ButtonSecondary onClick={e => setSentMessage(false)}>Show Form</ButtonSecondary>
                  </Alert>
                </div>
              </AlertArea>
                <FormArea>
                  <Form name="printForm" style={{ padding: "20px", display: !sentMessage ? 'block' : 'none' }} onSubmit={submitPrint}>
                    <FormGroup>
                      <Input type="text" name="name" id="username" placeholder="Your Name" onChange={e => setName(e.target.value)} value={name} />
                    </FormGroup>
                    <FormGroup>
                      <Input type="email" name="email" id="useremail" placeholder="Your Email" value={email} onChange={e => setEMail(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                      <Input type="text" name="phone" id="userphone" placeholder="Your Phone Number" value={phone} onChange={e => setPhone(e.target.value) }/>
                    </FormGroup>
                    <FormGroup>
                      <Input type="textarea" name="message" id="usermessage" placeholder="Your Message" onChange={e => setMessage(e.target.value)} value={message} />
                    </FormGroup>
                      <ButtonSecondary type="submit" onClick={submitPrint}>Submit</ButtonSecondary>
                  </Form>
                </FormArea>
          </FormBox>
        </Box2>
      </BackgroundSectionDiv>
    </Layout>
    )
  }
  
  export default MetalPrintFormPage
import React from 'react'
import styled from 'styled-components'

const Link = styled.a`
  display: flex;
  height: 100%;
  h6 {
    color: #235551;
  }
  p {
    color: #000;
  }
  &:hover,
  &:active {
    h6 {
      color: #17a99e;
    }
    text-transform: none;
    text-decoration: none;
  }
`

const Link2Web = ({ children, className, href}) => {
  return (
    <Link href={href} className={className} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  )
}

export default Link2Web

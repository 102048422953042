import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";

import Banner from "../components/Banner"

import Layout from "../components/layout"
import SEO from "../components/seo"
import bannertraining from "../images/banner/page-banner/banner-trainings.png";

import exambanner from "../images/academy/exam.jpg"

const Section = styled.section`
  padding: 60px 0;
`
const Image = styled.img`
  width: 500px;
  height: auto;
  display: block;
  margin: 0 auto;
`
const Box = styled.div`
  padding: 5px;
  border: 1px solid #17a99e;
`
const TnC = styled.div`
  padding: 20px 0;
  margin: 0 10px;
`
const ContentText = styled.div`
  text-align: center;
`
const TitleText = styled.div`
  h1{
    font-size: 120px;
    font-weight: lighter;
    margin: 0;
  }
  p{
    font-size: 40px;
  }
`
const ButtonLink = styled.a`
  padding: 10px 30px;
  margin: 5px;
  border: 5px solid #000;
  color: #fff;
  background-color: #17a99e;
  font-weight: bold;
  :hover{
    background-color: #30c2b7;
    color: #fff;
    text-decoration: none;
  }
`

const AcademyPage = () => (
  <Layout>
    <SEO title="SEGI Certified Executive Degree" description="SEGI Certified Executive Degree" />
    <Banner color src={exambanner} classNamed="BannerAcademy" alt="banner" customImageHeight="80vh" customTextBoxWidth="70%">
      <TitleText>
        <h1>
          SEGI Certified Executive Degree in Additive Manufacturing
        </h1>
        <p>
          in 6-8 Months
        </p>
      </TitleText>
    </Banner>
    <Section>
      <Container>
        <ButtonLink href="www.google.com">Register for Free Trial Class {">"}</ButtonLink>
        <ContentText>
          <h3>
            Learn Your Way handling Additive Manufacturing
          </h3>
          <p class="centerText">
            "text of text to text text of text about text though text is text"
          </p>
        </ContentText>
      </Container>
    </Section>
    <Section>
      <Container>
        <ContentText>
          <h3>
            Come and Learn from our industry experts
          </h3>
          <p>
            <b>Course Outline:</b><br/>
            Subject/Modules: 6 Subjects/Modules<br/>
            Duration: 6-8 Month<br/>
            Study Mode: Online Class or Face to Face or Hybrid<br/>
            Target: Min 21 Years Old<br/>
            Certificate: SEGI Certified Certificate<br/>
          </p>
        </ContentText>
      </Container>
    </Section>
    <Section>
      <Container>
        <ContentText>
          <h3>
            Support Online & Offline Study<br/>
            Let You Maintain Work-Study-Life Balance
          </h3>
          <p>
            <b>Week 1</b><br/>
            Online Orientation Course<br/>
            Overiew Learning Outcomes<br/>
            Online Tutorial Support<br/>
            Online Self-Study<br/>
            <br/>
            <b>Week 2</b><br/>
            Online Tutorial Support or Face to Face<br/>
            Online Self Study<br/>
            <br/>
            <b>Week 3</b><br/>
            Online Tutorial Support<br/>
            Online Self-Study<br/>
            <br/>
            <b>Week 4</b><br/>
            Online Tutorial Support<br/>
            Online Self-Study<br/>
            Offline Assessment<br/>
            <br/>
          </p>

          <h3>
            Bonus
          </h3>
          <p>
            Bonus 1: Lifetime Revision<br/><br/>
            Bonus 2: Lifetime Access to videos and lecture notes<br/><br/>
            Bonus 3: Whatsapp Group for better communication between lecturer and students<br/><br/>
            Bonus 4: Waive registration fees RM 200 if registered within the dateline<br/><br/>
          </p>
        </ContentText>
      </Container>
    </Section>
  </Layout>
)

export default AcademyPage

import React from "react"
import { Container, Row, Col } from "reactstrap";
import Slider from "react-slick";
import styled from "styled-components";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Layout from "../components/layout"
import SEO from "../components/seo"
import gens3d from "../images/3DGENS_LOGO.png"

import Banner from "../components/Banner";
import Section from "../components/Section";
import BackgroundSection from "../components/BackgroundSection";
import CardBorder from "../components/CardBorder";
import ButtonPlain from "../components/buttons/ButtonPlain";
import ScannerCarousel from "../components/ScannerCarousel";

import combed from "../images/combed.png";
import { graphql } from "gatsby";
import CardSimple from "../components/CardSimple";

import aoral from "../images/scanner/aoralscanner.jpg";
import eins from "../images/scanner/einscanpro.jpg";
import free from "../images/scanner/freescan.jpg";
import auto from "../images/scanner/autoscan.jpg";
import scanbanner from "../images/scanner/banner-scanner.png"
import scannerbanner from "../images/banner/scannerBanner.png"

import FTrio from "../images/scanner/Trio.png"
import FTrio1line from "../images/scanner/freescantrio1line.jpg"
import FTrio7line from "../images/scanner/freescantrio7lines.jpg"
import FTrio26line from "../images/scanner/freescantrio26lines.jpg"
import FTrio98line from "../images/scanner/freescantrio98lines.jpg"
import trioimg1 from "../images/scanner/trioimg1.jpg"
import trioimg2 from "../images/scanner/trioimg2.png"
import trioimg3 from "../images/scanner/trioimg3.png"
import trioimg4 from "../images/scanner/trioimg4.jpg"
import trioimg5 from "../images/scanner/trioimg5.jpg"


import train1 from "../images/scanner/training1.jpg";
import train2 from "../images/scanner/training2.jpg";
import train3 from "../images/scanner/training3.jpg";
import train4 from "../images/scanner/training4.jpg";
import train5 from "../images/scanner/training5.jpg";
import train6 from "../images/scanner/training6.jpg";

import printerbanner from "../images/banner/page-banner/banner-printers.png";
import { set } from "lodash";

const ScannerInfoBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly; 
  flex-flow: row wrap;
  .flexbox{
    flex: 1;
    margin: 0 10px;
  }
  .storydesc{
    font-weight: bold;
  }
  @media only screen and (max-width:800px) {
    flex-wrap: wrap;
    .flexbox{
      flex: 1 1 90%;
    }
  }
`
const BannerSection = styled.div`
  height: 50vh;
  position: relative;
  .bannerImage{
    background-image: url(${scannerbanner});
    background-size: cover;
    background-color: #0f0f0f;
    height: 100%;
    // position: relative;
  }
  .bannerText{
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`
const Image = styled.img`
  width: 300px;
  height: auto;
  display: block;
  margin: 10px;
`
const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 16px;
`
const Flexbox2 = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 16px;
  .box{
    margin: 0 10px;
  }
`

const scannerDetails = [
  {
    image : <img src={free}></img>,
    title : "FreeScan UE Laser Handheld Scanner",
    description: "Blue laser handheld 3D scanner of the FreeScan UE features of high accuracy and high precision result, ergonomic and lightweight equipment design",
  },
  {
    image : <img src={eins}></img>,
    title : "EinScan Pro HD",
    description: "Multi-functional and modular design of EinScan Pro series handheld 3D scanner, delivers unparalleled performance in capturing high resolution and accuracy.\n It is reliable assistant for designers and engineers who care about high quality 3D modeling.",
  },
  {
    image : <img src={aoral}></img>,
    title : "Aoralscan 3 Intraoral Scanner",
    description: "Aoralscan 3 is an Brand New Design of the Intraoral Scanner which features Fast Speed, High Accuracy, Powerful AI Process and Intuitive Communication.\n It is suitable for various clinical scenarios including acquiring scan bodies and periodontal scans",
  },
  {
    image : <img src={auto}></img>,
    title : "AutoScan-DS-EX Pro",
    description: "Multi-functional 3D dental scanner independantly developed and manufactured by SHINING 3D, specializing in data capturing for dental restoration, orthodontics and implantology.",
  },
]

const trainings = [
  train1,train2,train3,train4,train5,train6,
]
const triousage = [
  trioimg4,trioimg2,trioimg3
]
const freescanTrio = [
  {
    image : FTrio98line,
    title : "98-Line-Laser Mode"
  },
  {
    image : FTrio26line,
    title : "26-Line-Laser Mode"
  },
  {
    image : FTrio7line,
    title : "7-Line-Laser Mode"
  },
  {
    image : FTrio1line,
    title : "Single-Line-Laser Mode"
  },
]

const ScannerPage = ({ data }) => (
  <Layout>
    <SEO title="3DScanner" />
      <BannerSection>
        <div className="bannerImage"></div>
        <div className="bannerText">
          <h3>
            3D Scanner
          </h3>
          <p>
            3D scanning is the process of analyzing a real-world object or environment <br/>
            to collect data on its shape and possibly its appearance (e.g. colour). <br/>
            The collected data can then be used to construct digital 3D models.
          </p>
        </div>
      </BannerSection>
    
      {/* <BackgroundSection color="#eee">
        <Container>
          <h3 style={{color: "#000"}}>
            3D Scanner
          </h3>
          <p>
          3D scanning is the process of analyzing a real-world object or environment to collect data on its shape and possibly its appearance (e.g. colour). The collected data can then be used to construct digital 3D models.
          </p>
        </Container>
      </BackgroundSection> */}

      <BackgroundSection color="#fff">
        <Container>
          <h3 style={{color: "#000"}}>
            Newly Introduced FreeScan Trio: Triple Camera, 98 Laser Lines, Ultra-Fast Scan Rates 
          </h3>
          <Row>
            <Col xs="12" md="8" >
              <p>
                FreeScan Trio, the latest addition to its high-end range of 3D scanning solutions.
                The FreeScan Trio presents a notable development in laser 3D scanning technology, 
                offering marker-free scanning capabilities facilitated by a 98-laser-line mode and a triple, 5MP camera system. <br/>
                Not only but especially for projects involving large objects, scanning without the need for markers significantly reduces preparation time.<br/><br/>

                Moreover, the scanner is able to capture an impressive 3,010,000 points per second, establishing it as the fastest handheld 3D scanner in its class. <br/>
                Complemented by a large field of view of 650 by 580mm, it captures more data in fewer sweeps.<br/><br/>
              </p>
            </Col>
            <Col>
              <img src={FTrio}/>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md="12">
              <p>
                In addition to the 98-laser-line mode, the FreeScan Trio offers a single-line mode, one with 7 parallel lines, and one with 26 crossed lines.<br/> 
                These modes can tackle many different projects in all kinds of industries, conferring the scanner with the versatility that is now customary in all 
                SHINING 3D products. With markers, these modes all achieve an accuracy of up to 0.02mm, and a volumetric accuracy of up to 0.02 mm.<br/><br/>
              </p>
            </Col>
            <Col xs="12" md="12">
              {/* <div>
                <div>
                  <img src={FTrio98line}/>
                  <p>
                    98-Laser-Line Mode
                  </p>
                </div>
              </div> */}
              <Flexbox2>
                { freescanTrio.map((mode) => (
                  <div className="box">
                    <img src={mode.image} />
                    <p style={{textAlign: "center"}}>{mode.title}</p>
                  </div>
                ))}
              </Flexbox2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                  Last but not least, the FreeScan Trio is also equipped with a photogrammetry mode that works with SHINING 3D’s practical, magnetic scale bar, 
                  eliminating the need for extra coded targets. This mode narrows the volumetric accuracy down to 0.02mm + 0.015mm/m.<br/><br/>

                  Whether working with large-scale assemblies or scrutinizing intricate components, the new FreeScan Trio is a reliable and powerful ally for 
                  any engineer or designer.<br/>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Flexbox2>
                {triousage.map((image) => (
                  <div className="box">
                    <img src={image}></img>
                  </div>
                ))}
              </Flexbox2>
            </Col>
          </Row>

        </Container>

      </BackgroundSection>

      <BackgroundSection image={combed}>
        <Container>
          <h3 style={{color: "#000"}}>
            List of Available 3D Scanners
          </h3>
          {/* <ScannerInfoBox>
            { scannerDetails.map((scanner) => (
              <div className="flexbox">
                <CardSimple image={scanner.image} title={scanner.title} description={scanner.description} />
              </div>
            )) }
          </ScannerInfoBox> */}
          <ScannerCarousel/>
        </Container>
      </BackgroundSection>

      <BackgroundSection color="#eee">
        <Container>
            <h3 style={{color: "#000"}}>
              3D Scanning Training
            </h3>
            {/* <p>
              Training on the basis of 3D Scanner are also provided. Training will consist from basic of scanning to the practical usage of it.
            </p> */}
            <Flexbox>
              { trainings.map((image) => (
                <Image src={image}/>
                ))}
            </Flexbox>
        </Container>
      </BackgroundSection>
  </Layout>
)

export default ScannerPage
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby';

const Button = styled.div`
    /* width: fit-content; */
    text-align: center;
    color: #fff;
    background-color: #235551;
    padding: 12px 20px;
    border-radius: 4px;
    cursor: pointer;
  
  &:hover,
  &:active {
    background-color: #17a99e;
    text-decoration:none;
    color: #000;
  }
`

const LinkButtonSecondary = ({ children, className, onClick, type }) => {
  return (
    <Button className={className} onClick={onClick} type={type}>
        {children}
    </Button>
  )
}

export default LinkButtonSecondary

import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";
import { Link, graphql } from "gatsby";

import Banner from "../components/Banner";
import Section from "../components/Section";
import BackgroundSection from "../components/BackgroundSection";
import { FaArrowRight } from "react-icons/fa";
import Layout from "../components/layout"
import SEO from "../components/seo"

import ok3d from "../images/3dokay.jpg";
import gens3d from "../images/3DGENS_LOGO.png"
import skulls from "../images/Skulls.jpg";
import teeth from "../images/teethmodel.jpg";
import plate from "../images/plateimplant.jpg";
import model from "../images/modelonscreen.png";
import meshmodel from "../images/mesh.jpg"
import implantmodel from "../images/implant.jpg"
import printmodel from "../images/modelprint.jpg"
import bgskulls from "../images/background1.jpg"
import screw from "../images/implant-screw.jpg"
import interlink from "../images/interlink-plate.jpg";
import peekmodel from "../images/Xilloc-PEEK-Implant.png";
import meshplate from "../images/meshplate.jpg";
import scan from "../images/scan.jpg"
import genceP from "../images/3dgence-printer.png";
import lisaP from "../images/lisa-printer.png";
import design from "../images/design.png";
import polyprint from "../images/printedproduct.jpg";
import printing from "../images/printing.jpg";
import metal from "../images/engineering.jpg";
// import printer from  "../images/Ultimaker.jpg"
import product from "../images/finished-product.jpg";
import story2 from "../images/a2.jpg";
import story3 from "../images/a4.jpg";
import training from "../images/training.jpg"
import training2 from "../images/training2.jpg"
import training4 from "../images/training.jpeg"
import research2 from "../images/research2.jpg"
import research3 from "../images/research3.jpg"
import research from "../images/research.jpg"

import dynamic from "../images/dynamic.png";
import combedgreen from "../images/combedgreen.png";
import Moment from "react-moment";

const images = [
  gens3d,
  // skulls,
  teeth,
  plate,
  model,
  meshmodel,
  implantmodel,
  printmodel,
  bgskulls,
  screw,
  // interlink,
  peekmodel,
  // meshplate,
  scan,
  // genceP,
  // lisaP,
  design,
  polyprint,
  printing,
  // metal,
  // printer,
  product,
  story2,
  story3,
  training,
  training2,
  training4,
  research,
  research2,
  research3,
  ok3d,
]

const BoxParent = styled.div`
  /* display: flex;
  flex-flow: row;
  flex-wrap: wrap; */
  position: relative;
  line-height: 0;
  column-count: 4;
  column-gap: 0; 
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding: 16px;

  @media only screen and (max-width: 700px){
    column-count: 2;
  }
`
const ImageItem = styled.div`
  width: 100%;
  height: auto;
  img{
    margin-bottom: 0;
    padding: 4px;
    :hover{
      background-color: #fff;
      transform: scale(1.5,1.5);
      box-shadow: inset 0 0 5px #000;
    }
  }
`
const EventBox = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
`
const ItemBox = styled.div`
  background-color: #fff;
  margin: 5px auto;
  margin-bottom: 30px;
  text-align: center;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
  h5{
    /* text-align: left; */
    margin: 10px;
  }
  img{
    width: 250px;
    height: auto;
    object-fit: contain;
    object-position: center;
    margin: 10px;
    :hover{
      background-color: #fff;
      transform: scale(1.5,1.5);
      box-shadow: inset 0 0 5px #000;
    }
  }
  p{
    margin: 10px;
    text-align: right;
    color: #a0a0a0;
  }
`

const GalleryPage = ({data}) => (
  <Layout>
    <SEO title="Gallery" />
      {/* <Banner src={ok3d} alt="banner"></Banner> */}
    <BackgroundSection image={combedgreen}>
      <Container>
        <h3>
          Gallery
        </h3>
        <BoxParent>
          {/* {images.map((image) => (
            <ImageItem>
              <img src={image} />
            </ImageItem>
          ))} */}
        </BoxParent>
        <EventBox>
          {
            data.allStrapiGalleries ? data.allStrapiGalleries.edges.map(document => (
              <ItemBox>
                <h5>
                  {document.node.title}
                </h5>
                {document.node.media.map(image => (
                  <img src={process.env.GATSBY_API_URL+image.url} />
                ))}
                <p>
                  <Moment format="DD/MM/YYYY" date={document.node.createdAt} />
                </p>
              </ItemBox>
            ))
            :
            <div>No DATA</div>
          }
        </EventBox>
      </Container>
    </BackgroundSection>
  </Layout>
)

export default GalleryPage

export const query = graphql`
            query GalleryQuery {
              allStrapiGalleries{
                edges{
                  node{
                    id
                    title
                    media{
                      url
                    }
                    createdAt
                  }
                }
              }
            }
`

import React, { useState } from "react"
import emailjs from "emailjs-com";
import { Form, FormGroup, Label, Input, Container, Row, Col, Alert } from "reactstrap";
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import combed from "../images/combed.png";
import floor from "../images/memphis.png"
import docImg from "../images/doc-img.jpg";

import Banner from "../components/Banner"
import BackgroundSection from "../components/BackgroundSection"
import Section from "../components/Section"
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";
import ButtonSecondary from "../components/buttons/ButtonSecondary";

const LabelCss = styled(Label)`
  text-align: right;
  padding-top: 5px;
`
const FormBox = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 15px;

`
const OfficeDiv = styled.div`
  flex: 1 1 0px;
  margin: 20px;
  padding: 20px 10px;
  // display: flex;
  // flex-direction: row;
  text-align: center;
  background-color: rgba(39, 199, 216, 0.5);
  border: solid 2px transparent;
  border-radius: 15px;
  :hover{
    background-color: rgba(39, 199, 216, 0.3);
    border: solid 2px rgba(39, 199, 216, 1)
  }
  a{
    color: #000;
    text-decoration: none;
  }
`
const OfficeFlex = styled.div`
  display: flex;
  flex-direction: row;
`

const ContactPage = () => {

  const [name, setName] = useState('')
  const [email, setEMail] = useState('')
  const [phone, setPhone] = useState('')
  const [subject, setSubject] = useState('')
  const [message, setMessage] = useState('')

  const [sentMessage, setSentMessage] = useState(false)
  const [error, setError] = useState('')

  function sendEmail(e) {
    const emailValue = {
      name : name,
      email : email,
      phone : phone,
      subject : subject,
      message : message
    }

    emailjs.send('zoho_3dgens_contact', '3dgens_contact_form', emailValue, 'user_xSCMG3TRYMHMDoflfddQM')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }

  const submitContact = event => {
    event.preventDefault()
    setError('')
    console.log(`---`)

    const submissionData = {name, email, phone, subject, message}
    console.log(submissionData)
    if (name === '' || email === '' || phone === '' || message === '') {
      setError(`You must fill name, email, phone number and message.`)
      return
    }
    fetch(process.env.GATSBY_API_URL_CONTACTS, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email, phone, subject, message })
    })
      .then(response => {
        console.log(response)
        if (!response.ok) {
          setError(`Error ${response.status}`)
          throw new Error(response.body)
        }
        setSentMessage(true)
        setError('')
      })
      .then(() => {
        sendEmail()
      })
      .then(() => {
        setName('')
        setEMail('')
        setPhone('')
        setSubject('')
        setMessage('')
      })
      .catch(err => {
        console.log(err)
        setError(err)
      })
  }

  return (
  <Layout>
    <SEO title="Contact" />
    <BackgroundSection image={floor} id="contact">
      <Container>
      <h3>Contact Us</h3>
      <p>For any general questions or inquiries about us, you can email at <b>info@3dgens.com</b> or call <b>+60378327140</b>.</p>
      <p>Also for inquiries related on our engineering technology and capabilities or even 3D Printing Project you want to work out, you can direct email to <b>3dprint@3dgens.com</b> or contact/whatsapp us at <b>+60105583070</b>.</p>
      <p>We will be happy to help you out!</p>
      <FormBox>
      <Row>
        <Col xs="12" md="7">
          {error === '' ? '' :<Alert color="danger">{error}</Alert>}
          <Alert style={{display: sentMessage? 'block': 'none'}} color="success">
            Your message is successfully sent
            <br/>
            <ButtonSecondary onClick={e => setSentMessage(false)}>Show Contact Form</ButtonSecondary>
          </Alert>
          <Form name="contactForm" style={{ padding: "20px", display: !sentMessage ? 'block' : 'none' }} onSubmit={submitContact}>
          <FormGroup>
            <Row>
              <Col xs="3">
                <LabelCss for="username">Name</LabelCss>
              </Col>
              <Col xs="9">
                <Input type="text" name="name" id="username" placeholder="" onChange={e => setName(e.target.value)} value={name} />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs="3">
                <LabelCss for="useremail">Email</LabelCss>
              </Col>
              <Col xs="9">
                <Input type="email" name="email" id="useremail" placeholder="" value={email} onChange={e => setEMail(e.target.value)} />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs="3">
                <LabelCss for="phone">Phone</LabelCss>
              </Col>
              <Col xs="9">
                <Input type="tel" name="phone" id="phonenumber" placeholder="012-3456789" pattern="[0-9]{3}-[0-9]{7}" value={phone} onChange={e => setPhone(e.target.value) }/>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs="3">
                <LabelCss for="emailsubject">Subject</LabelCss>
              </Col>
              <Col xs="9">
                <Input type="text" name="subject" id="emailsubject" placeholder="" value={subject} onChange={e => setSubject(e.target.value) }/>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col xs="3">
                <LabelCss for="usermessage">Message</LabelCss>
              </Col>
              <Col xs="9">
                <Input type="textarea" name="message" id="usermessage" placeholder="" onChange={e => setMessage(e.target.value)} value={message} />
              </Col>
            </Row>
          </FormGroup>
              <ButtonSecondary type="submit" onClick={submitContact}>Submit</ButtonSecondary>
          </Form>
        </Col>
        <Col xs="12" md="5">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248.99890070860255!2d101.54688093986368!3d3.099333261675894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4de9f733304f%3A0x49e7c6ef889ed155!2s18%2C%20Jalan%20Kerawang%20U8%2F108%2C%2040150%20Shah%20Alam%2C%20Selangor!5e0!3m2!1sen!2smy!4v1573790830079!5m2!1sen!2smy" width="100%" height="300" frameBorder="0" style={{border:"0"}} allowFullScreen></iframe>
          <p>
            18 JLN KERAWANG U8/108 PERINDUSTRIAN TEKNO JELUTONG, <br/>
            SEKSYEN U8 40150, SHAH ALAM, <br/>
            40150, <br/>
            SELANGOR <br/>
          </p>
        </Col>
      </Row>
      </FormBox>
        
      </Container>
    </BackgroundSection>

    <Section id="office" >
      <Container>
        <h3>3D Gens Corporate Offices</h3>
        <OfficeFlex>
          <OfficeDiv>
            <a href="https://www.3dgens.com/" target="_blank" rel="noopener noreferrer">
              <div id="office1" className="desc">
                <h4>
                  Headquarters
                </h4>
                <p>
                  18 Jalan Kerawang U8/108, <br/>
                  Perindustrian Tekno Jelutong,<br/>
                  Seksyen U8, 40150, Shah Alam,<br/>
                  Selangor, Malaysia
                </p>
                <p>
                  +603 7832 7140
                </p>
              </div>
            </a>
          </OfficeDiv>
          <OfficeDiv>
            <a href="https://www.3dgens.co.uk/" target="_blank" rel="noopener noreferrer">
              <div id="office2" className="desc">
                <h4>
                  United Kingdom
                </h4>
                <p>
                  3DGENS UK LTD,<br/>
                  Chiswick, <br/>
                  London W4 2QY, UK
                </p>
                <p>
                  +44 75013 69745 
                </p>
              </div>
            </a>
          </OfficeDiv>
          <OfficeDiv>
            <a href="https://www.3dgens.co.id/" target="_blank" rel="noopener noreferrer">
              <div id="office3" className="desc">
                <h4>
                  Indonesia
                </h4>
                <p>
                  GENS TEKNO ADITIF,<br/>
                  Graha Niaga 25th Floor,<br/>
                  Jl. Jend. Sudirman kav. 58,<br/>
                  Jakarta 12190, Indonesia.
                </p>
                <p>
                  +62 852 1189 3743 
                </p>
              </div>
            </a>
          </OfficeDiv>
        </OfficeFlex>
      </Container>
    </Section>

  </Layout>
  )
}

export default ContactPage

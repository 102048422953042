import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components";
import { Container } from "reactstrap";
import { Link } from "gatsby";
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";

import renAM from "../images/RenAM-500E.jpg";
import bannerpoly from "../images/banner/bannerPoly.png"

import gence420 from "../images/gence420.jpg";
import d1000 from "../images/printer/D1000.png"
import crealityk1 from "../images/printer/crealityk1.png"

import prusa from "../images/printer/prusaSL1S.png"
import mega8k from "../images/printer/mega8k.png"
import m3max from "../images/printer/m3max.png"

import sinteritpro from "../images/printer/sinteritPRO.png"
import sinteritnils from "../images/printer/sinteritNILS.png"
import eplus420 from "../images/printer/eplus420.png"

import { FaWhatsapp } from "react-icons/fa";
import ContactBar from "../components/ContactBar";

const BannerSection = styled.div`
  height: 50vh;
  position: relative;
  .bannerImage{
    background-image: url(${bannerpoly});
    background-size: cover;
    background-color: #0f0f0f;
    height: 100%;
    // position: relative;
  }
  .bannerText{
    position: absolute;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`
const Section = styled.div`
  padding: 60px 0;
`
const PrinterFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  column-gap: 1em;
  .printerBox{
    flex: 1;
    border: 1px solid #ccc;
    img{
      text-align: center;
      height: 300px;
      object-fit: cover;
    }
    .textArea{
      padding: 10px;
      .descbox{
        height: 110px;
      }
    }
  }
`
const IntroInfoFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  // column-gap: 1em;
  color: #fff;
  text-align: center;
  .leftbox{
    flex: 1;
    padding: 100px 200px;
    background-color: #323232;
  }
  .rightbox{
    flex: 1;
    padding: 100px 200px;
    background-color: #234678;
  }
  p{
    text-align: center;
  }
`
const LinkButton = styled.a`
  color: inherit;
  text-decoration: none;
  text-transform: none;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  :hover{
    text-decoration: none;
    background-color: #fff;
    color: #000;
  }
`
const InfoFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  column-gap: 1em;
`


const FDMPrinters = [
  {
    name: "3DGENCE INDUSTRY F420",
    image: gence420,
    description: "Flexibility and performance with job-specific printing modules and predefined printing profiles",
    volume: "380 x 380 x 420",
    highlights: <ul>
      <li>Durable and accurate end parts manufacturing</li>
      <li>Complex prototypes with the use of soluble supports and large build volume</li>
      <li>Controlled environment in high-temperature chamber</li>
      </ul>,
  },
  {
    name: "CREATBOT D1000",
    image: d1000,
    description: "One Cubic Meter Industrial Grade Large 3D Printer.",
    volume: "1000 x 1000 x 1000",
    highlights: <ul>
      <li>Size worry-free For High strength prototype to make industrial 3D printing of large-scale objects and prototype as easy as possible</li>
      <li>Easy to maintain and affordable to replace Auto-rising Dual Extruders 420 ℃</li>
      <li>Cost-effective, reliable large 3D printer with smart dual extruder, enclosed chamber, auto-leveling, and camera control for impressive prints</li>
      </ul>,
  },
  {
    name: "CREALITY K1",
    image: crealityk1,
    description: "Fast, in an Epic Way, Crazy 600mm/s*. K1 is 12 times faster than a regular FDM 3D printer.",
    volume: "220 x 220 x 250",
    highlights: <ul>
      <li>Print Right out of the Box, K1 is assembled and calibrated before shipment and offers a boot-up quick guide.</li>
      <li>Nimble Setup for Speed K1 combines the nimble Core XY with a 190g lightweight printhead for less motion inertia. Agile and swift</li>
      <li>32mm³/s Flow in Full Blast, New ceramic heater, encircling the entire hotend. It heats to 200℃ in 40s and melts the filament instantly.</li>
      </ul>,
  },
]
const SLAPrinters = [
  {
    name: "Prusa SL1S",
    image: prusa,
    description: "Original Prusa SL1S produces high-quality prints with even more detail",
    volume: "127 x 80 x 150",
    highlights: <ul>
      <li>Great Print Quality features a new 5.96'' monochrome LCD with a physical resolution of 2560×1620p.</li>
      <li>Fastest Printer Ever with custom-made optics system and completely reworked UV LED panel</li>
      <li>High-Quality Materials and Rigid Body which minimizes vibrations and ensures the best possible printing performance even when printing thin and highly detailed geometry</li>
      </ul>,
  },
  {
    name: "Anycubic Photon M3 Max",
    image: m3max,
    description: "Unleash Your Imagination Equipped with 13.6 inch large screen, building size of 300x298x164mm(HWD), 14.7L print volume, unleash your creativity without limits",
    volume: "300 x 298 x 164",
    highlights: <ul>
      <li>Large Printing Volume that can reach up to 14.7L</li>
      <li>13.6" 7K High Definition Screen that will produce well-defined Details</li>
      <li>Smart Resin Filling no need to worry about resin shortage</li>
      </ul>,
  },
  {
    name: "PHROZEN SONIC MEGA 8K",
    image: mega8k,
    description: "Breaks every single limitation for commercial 3D printers with now can have both size and quality in one print",
    volume: "330 x 185 x 400",
    highlights: <ul>
      <li>8K Perfection Engineered with an industry-leading XY resolution of 43µm and top-grade light uniformity</li>
      <li>Pump & Fill - Automated Resin Feeder ensures your resin vat is always properly filled</li>
      <li>Safer and More Convenient Build Plate with perforated plate reducing peeling force</li>
      </ul>,
  },
]
const SLSPrinters = [
  {
    name: "SINTERIT LISA PRO",
    image: sinteritpro,
    description: "A professional SLS 3D printer with an open environment and access to a wide range of materials",
    volume: "110 x 160 x 245",
    highlights: <ul>
      <li>Open printing system with no pre-defined parameters and only dedicated materials.</li>
      <li>Reliable printer which is trusted by hundreds of users across the world printing thousands of parts</li>
      <li>Top-notch print quality of the 3D printed parts is comparable to that of industrial size machines</li>
      </ul>,
  },
  {
    name: "SINTERIT NILS",
    image: sinteritnils,
    description: "An industrial SLS 3D printer ensuring more automated work and the best ROI on the SLS technology market.",
    volume: "200 x 200 x 330",
    highlights: <ul>
      <li>Innovative automated systems with its Continous Printing and Automatic Powder Dispensing Systems</li>
      <li>Industrial printing speed with the Galvo laser scanning system</li>
      <li>Industrial build size enabling you to print much more at lower prices per part</li>
      </ul>,
  },
  {
    name: "EPLUS3D EP-P420",
    image: eplus420,
    description: "EP-P420 ensures you the capability to print medium & large size parts for customized products and small batch production",
    volume: "420 x 420 x 465",
    highlights: <ul>
      <li>High Performance with elimination of left and right excess material box design, resulting in higher powder utilization</li>
      <li>High precision with 120w laser power, the machine has higher printing speed</li>
      <li>User-Friendly & Intelligent by One-click scanning path generation</li>
      </ul>,
  },
]

const addtionalInfo = [
  {
    title: "Metal Materials",
    description: "Our extensive and versatile portfolio of 3D printing materials addresses the widest range of applications and performance characteristics in additive manufacturing, with plastic, elastomer, composite, wax, metal, bio-compatible and more material types.",
    image: renAM,
    linktitle: "Metal Material",
    link: "/metalmaterial",
  },
  {
    title: "3D Scanning",
    description: "Our extensive and versatile portfolio of 3D printing materials addresses the widest range of applications and performance characteristics in additive manufacturing, with plastic, elastomer, composite, wax, metal, bio-compatible and more material types.",
    image: renAM,
    linktitle: "metal material",
    link: "/metalmaterial",
  },
  {
    title: "Services",
    description: "Our extensive and versatile portfolio of 3D printing materials addresses the widest range of applications and performance characteristics in additive manufacturing, with plastic, elastomer, composite, wax, metal, bio-compatible and more material types.",
    image: renAM,
    linktitle: "metal material",
    link: "/metalmaterial",
  },
]

const PolyPrinterPage = () => {
  
  const email = "3dprint@3dgens.com";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    alert("Email Copied to Clipboard")
  };
  return (
    <Layout>
      <SEO title="3D Polymer Printer" />
      <BannerSection>
        <div className="bannerImage"></div>
        <div className="bannerText">
          <h3>
            3D Polymer Printers
          </h3>
          <p>
           SOLUTIONS FOR PROTOTYPING TO PRODUCTION USING POLYMER
          </p>
        </div>
      </BannerSection>
      <Section style={{paddingTop: 0}}>
        <IntroInfoFlexBox>
          <div className="leftbox">
            <h4>
              Exploring the Possibilities of Polymer 3D Printing!
            </h4>
            <p>
              Delve into the diverse range of options available for engineering-grade materials, elastomers, 
              and composites in polymer 3D printing. Whether you require flexibility, strength, bio-compatibility, 
              or more, the possibilities are endless.
            </p>
            {/* <LinkButton href="/product">
              Learn More
            </LinkButton> */}
          </div>
          <div className="rightbox">
            <h4>
              Harness the Benefits of Polymer 3D Printing!
            </h4>
            <p>
              Leverage plastic 3D printing to fabricate a wide range of items for prototyping, manufacturing, 
              anatomical models, and beyond. Choose from various plastic materials and 3D technologies to achieve 
              the specific characteristics you require.
            </p>
            {/* <LinkButton href="/material">
              Learn More
            </LinkButton> */}
          </div>
        </IntroInfoFlexBox>
      </Section>
      <Container>
        <Section>
          <hr/>
          <h2>
            Fused Deposition Modeling Printers (FDM)
          </h2>
          <h5>
            Efficient and Affordable Digital Manufacturing Solutions
          </h5>
          <p>
            Delivering rapid additive manufacturing technology, our range of FDM printers offers scalable capacity to fulfill 
            current and future requirements. With a selection of advanced materials, these printers provide tool-less options 
            for conventional injection molding or urethane casting processes, enabling direct digital production of precise 
            plastic parts and same-day rapid prototyping.
          </p>
          <PrinterFlexBox>
            {FDMPrinters.map((printer) => (
              <div className="printerBox">
                <img src={printer.image}/>
                <div className="textArea">
                  <h5>{printer.name}</h5>
                  <p className="descbox">{printer.description}</p>
                  {/* <p>Build Volume<br/>{printer.volume}mm</p> */}
                  {/* {printer.highlights} */}
                </div>
              </div>
            ))}
          </PrinterFlexBox>
        </Section>
        <Section>
          <hr/>
          <h2>
            Stereolithography 3D Printers (SLA)
          </h2>
          <h5>
           Setting the Standard for Prototypes, Tools, and Production Parts
          </h5>
          <p>
            Delivering a comprehensive solution with integrated hardware, software, and materials tailored to deliver 
            unparalleled SLA parts quality. Renowned for precision, repeatability, and reliability, SLA printers cater 
            to various applications such as prototyping, end-use part production, casting patterns, molds, tooling, 
            fixtures, and medical models.
          </p>
          <PrinterFlexBox>
            {SLAPrinters.map((printer) => (
              <div className="printerBox">
              <img src={printer.image}/>
              <div className="textArea">
                <h5>{printer.name}</h5>
                <p className="descbox">{printer.description}</p>
                {/* <p>Build Volume<br/>{printer.volume}mm</p> */}
                {/* {printer.highlights} */}
              </div>
            </div>
            ))}
          </PrinterFlexBox>
        </Section>
        <Section>
          <hr/>
          <h2>
            Selective Laser Sintering 3D Printers (SLS)
          </h2>
          <h5>
            Unlock Production-Grade Thermoplastic Parts with SLS Technology
          </h5>
          <p>
            Enhance manufacturing capabilities using SLS 3D printers, which utilize production-grade nylon materials to 
            fabricate robust, intricately designed parts with superior surface finish, resolution, accuracy, and repeatability, 
            all at a low total cost of operations. Specializing in printing thermoplastic materials that offer a comprehensive 
            range of capabilities and isotropic properties, spanning from rigid to elastomeric, high elongation, 
            high impact strength, and high-temperature resistance.
          </p>
          <PrinterFlexBox>
            {SLSPrinters.map((printer) => (
              <div className="printerBox">
              <img src={printer.image}/>
              <div className="textArea">
                <h5>{printer.name}</h5>
                <p className="descbox">{printer.description}</p>
                {/* <p>Build Volume<br/>{printer.volume}mm</p> */}
                {/* {printer.highlights} */}
              </div>
            </div>
            ))}
          </PrinterFlexBox>
        </Section>
      </Container>
      <hr/>
      <Container>
        {/* <Section>
          <InfoFlexBox>
              {addtionalInfo.map((item) => (
                <div>
                  <img src={item.image}/>
                  <h5>
                    {item.title}
                  </h5>
                  <p>
                    {item.description}
                  </p>
                  <LinkButton href={item.link}>
                    {item.linktitle}
                  </LinkButton>
                </div>
              ))}
          </InfoFlexBox>
        </Section> */}
      </Container>

      <ContactBar/>
    </Layout>
  )
}

export default PolyPrinterPage

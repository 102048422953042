import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import gens3d from "../images/3DGENS_LOGO.png"
import youtube from "../images/youtube-thumbnail.jpg";

import Banner from "../components/Banner";
import Section from "../components/Section";
import BackgroundSection from "../components/BackgroundSection";
import CardBorder from "../components/CardBorder";
import ButtonPlain from "../components/buttons/ButtonPlain";
import { Link } from "gatsby";
import { FaArrowRight } from "react-icons/fa";
import Moment from "react-moment";

const StorySection = styled.div`
  min-height: 100vh;
`
const StoryPost = styled.div`
  /* position: relative; */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 250px;
  margin: 40px 0;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  /* border: 1px solid #ccc; */
  .imagebox{
    width: 100%;
    /* height: 100%; */
    text-align: center;
    margin: 10px;
    flex: 0 0 30%;
    background-color: #fff;
    img{
      /* width: 100%; */
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin: 0; 
    }
  }
  .contentbox{
    /* background-color: #fff; */
    padding: 5px;
    margin: 10px;
    flex: 0 0 65%;
  }
  :hover{
    background-color: #17a99e;
  }
  @media only screen and (max-width:800px){
    flex-wrap: wrap;
    /* flex-direction: column; */
    height: auto;
    margin: 40px 0;
    .imagebox{
      flex: 1 1 100%;
      img{
        height: auto;
        width: 100%;
      }
    }
    .contentbox{
      flex: 1 1 100%;
    }
  }

`
const LinkPost = styled(Link)`
  :hover{
    color: none;
    text-decoration: none;
    text-transform: none;
  }
`

const ContentItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .titlebox{
    flex: 1 1 30%;
    h3{
      color: #235551;
      margin: 0;
    }
    span{
      color: #a0a0a0;
    }
  }
  .descriptionbox{
    flex: 1 1 50%;
    p.desc-item{
      /* display: inline-block; */
      color: #000;
      margin: 0;
      white-space: pre-line;
      height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .linkbox{
    flex: 1 1 10%;
    align-self: flex-end;
    .styled-link{
      color: #235551;
      :hover{
        color: #17a99e;
      }
    }
  }
  @media only screen and (max-width:800px){
  }
`


const StoryPage = ({ data }) => (
  <Layout>
    <SEO title="Stories" />
    {/* <Banner src="" alt="banner" position="left"></Banner> */}
      <BackgroundSection>
        <Container>
          <StorySection>
            <h1>
              Stories
            </h1>
            {data.allStrapiStories.edges.map(story => (
                <LinkPost to={`/${story.node.id}`}>
              <StoryPost>
                <div className="imagebox">
                  {story.node.media ? (
                    <img src={story.node.media.publicURL} alt={story.node.id} />
                  ):(
                    <img src={youtube} alt="https://www.youtube.com/embed/1FAQHo7OvQw" />
                    // <iframe className="ytvideo" width="100%" height="100%" style={{display: "block", margin: "0px auto"}} src="https://www.youtube.com/embed/1FAQHo7OvQw" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  )}
                </div>
                <div className="contentbox">
                  <ContentItem>
                    <div className="titlebox">
                      <h3>
                        {story.node.title}
                      </h3>
                      <span>
                        <Moment format="DD/MM/YYYY" date={story.node.createdAt}/>
                      </span>
                    </div>
                    <div className="descriptionbox">
                      <p className="desc-item">
                        {story.node.summary ? (story.node.summary) : (story.node.description)}
                      </p>
                    </div>
                    <div className="linkbox">
                      <Link className="styled-link" to={`/${story.node.id}`}>See More <FaArrowRight/></Link>
                    </div>
                  </ContentItem>
                </div>
              </StoryPost>
                </LinkPost>
            ))}
          </StorySection>
        </Container>
      </BackgroundSection>
  </Layout>
)

export default StoryPage

export const pageQuery = graphql`
                    query StoryQuery  {
                      allStrapiStories(sort: {fields: [createdAt], order: DESC}) {
                        edges {
                          node {
                            id
                            title
                            description
                            summary
                            show
                            createdAt
                            media{
                              publicURL
                            }
                          }
                        }
                      }
                    }
`
import React from 'react'
import styled from 'styled-components'
import Media from 'react-media'

const BannerWrap = styled.div`
  position: relative;
  color: #fff;
  p{
    font-weight: bold;
  }
   img{
    margin: 0;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-color: #ccc;
    object-fit: cover;
   }
  @media only screen and (max-width:800px) {
    img{
      height: 400px;
      width: auto;
    }
    h3{
      font-size: 18px;
    }
    p{
      font-size: 14px;
    }
  }
  .textQuote{
    p{
      margin: 0;
    }
    .author{
      text-align: right;
      font-weight: normal;
      font-size: 16px;
    }
  }
  
`
const BannerContent = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  width: 750px;
  height: auto;
  padding: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  transition: 0.3s;

  @media only screen and (max-width:800px) {
    width: 100%;
  }
  :hover{
    opacity: 0;
  }
`
const BannerContentLeft = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  width: fit-content;
  height: auto;
  padding: 15px;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  text-align: left;

  @media only screen and (max-width:800px) {
    left: 50%;
    width: 100%;
    text-align: center;
  }
`
const BannerContentRight = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  width: 750px;
  height: auto;
  padding: 15px;
  top: 50%;
  right: 20%;
  transform: translate(-50%, -50%);
  text-align: right;

  @media only screen and (max-width:800px) {
    right: 50%;
    width: 100%;
  }
`


const Banner = ({ children, gradient, color, classNamed, src, srcmobile, alt, video, position, customImageHeight, customTextBoxWidth, customImagePosition}) => {
  return (
    <BannerWrap className={classNamed}>
      <Media query="(max-width: 800px)">
      {matches => matches ? (
        srcmobile ? (
          <img src={srcmobile} alt={alt} />
        ):(
          <img src={src} alt={alt} />
        )
      ):(
        <img src={src} alt={alt} style={{height:`${customImageHeight}`,objectPosition:`${customImagePosition}`}}/>
      )
      }
      </Media>
      {color ? <div className="colorbanner"></div> : ""}
      {gradient ? <div className="gradientbanner"></div> : ""}
      {video}
      {children ? (
        position == "left" ? (
          <BannerContentLeft>
            {children}
        </BannerContentLeft>
        ):(
          position == "right" ? (
            <BannerContentRight>
              {children}
            </BannerContentRight>
          ):(
            <BannerContent style={{width:`${customTextBoxWidth}`}}>
              {children}
            </BannerContent>
          )
        )
      ): ""}
    </BannerWrap>
  )
}

export default Banner

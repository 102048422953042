import React, { useState } from "react"
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import styled from "styled-components";
import { FaFileUpload, FaArrowRight } from "react-icons/fa";

import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonSecondary from "../components/buttons/ButtonSecondary";
import LinkhrefButtonSecondary from "../components/buttons/LinkhrefButtonSecondary";

import bgimage from "../images/background2.jpg";
import floor from "../images/memphis.png";


const Section = styled.section`
  padding: 60px 0;
  h3{
    color: #17a99e;
  }
  p{
    color: #fff;
  }
`
const UploadBox = styled.div`
  width: 100%;
  height: 600px;
  border: 1px solid #777;
  position: relative;
`

const Icon = styled.div`
  position: absolute;
  display: block;
  left: 45%;
  top: 40%;
`
const ProtocolContent = styled.div`
  p{
    color: black;
  }
  b{
    color: #d02229;
  }
  ol{
    padding: 10px;
  }
  .linkGuide{
    font-weight: bold;
  }
`

const DentalForm = () => {

  const [name, setName] = useState('')
  const [email, setEMail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [postcode, setPostcode] = useState('')
  const [date, setDate] = useState('')
  const [note, setNote] = useState('')

  const [sentMessage, setSentMessage] = useState(false)
  const [error, setError] = useState('')

  const submitContact = event => {
    event.preventDefault()
    setError('')
    console.log(`---`)
    const submissionData = {name, email, phone, address, postcode, date, note}
    console.log(submissionData)
    if (name === '' || email === '' || phone === '' || address === '' || postcode === '' || date === '') {
      setError(`You must fill name, email, phone, address, postcode and date`)
      return
    }
    fetch(process.env.GATSBY_API_URL+'/dentalscanrequests', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email, phone, address, postcode, date, note })
    })
      .then(response => {
        console.log(response)
        if (!response.ok) {
          setError(`Error ${response.status}`)
          throw new Error(response.body)
        }
        setSentMessage(true)
        setError('')
      })
      .then(() => {
        setName('')
        setEMail('')
        setPhone('')
        setAddress('')
        setPostcode('')
        setDate('')
        setNote('')
      })
      .catch(err => {
        console.log(err)
        setError(err)
      })
  }

  // let today = new Date().toISOString().substr(0, 10)
  // document.querySelector("#servicedate").value = today


  return (
  <Layout>
    <SEO title="DentalForm" />
    <Section style={{backgroundImage: `url(${floor})`, backgroundPosition: 'center', backgroundRepeat: 'repeat'}}>

    <Container>
      <div style={{backgroundColor: "#fff", padding: "30px"}}>
        <h3 style={{textAlign:"center"}}>
          Dental Scanning Request
        </h3>
      <ProtocolContent>

      <p style={{color: "#000"}}>For Help, Contact us at +60378327140</p>
      {/* <LinkhrefButtonSecondary href="http://server.3dgens.com">Proceed <FaArrowRight/></LinkhrefButtonSecondary> */}

      </ProtocolContent>
      {error === '' ? '' :<Alert color="danger">{error}</Alert>}
      <Alert style={{display: sentMessage? 'block': 'none'}} color="success">
        Your message is successfully sent
        <br/>
        <ButtonSecondary onClick={e => setSentMessage(false)}>Show Contact Form</ButtonSecondary>
      </Alert>
      <Form style={{backgroundColor: "#d8d8d8", padding: "20px", display: !sentMessage ? 'block' : 'none' }} onSubmit={submitContact}>
        <Row>
          <Col>
              <FormGroup>
                <Label for="useremail">Name<span style={{color: "red"}}> *</span></Label>
                <Input type="text" name="name" id="username" placeholder="Full Name" onChange={e => setName(e.target.value)} value={name} />
              </FormGroup>
              <FormGroup>
                <Label for="useraddress">Address<span style={{color: "red"}}> *</span></Label>
                <Input type="textarea" name="address" id="useraddress" placeholder="" onChange={e => setAddress(e.target.value)} value={address} />
              </FormGroup>
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label for="useraddress">Postcode<span style={{color: "red"}}> *</span></Label>
                  <Input type="number" name="postcode" id="userpostcode" placeholder="" onChange={e => setPostcode(e.target.value)} value={postcode} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6">
              <FormGroup>
                <Label for="servicedate">Preferred Date<span style={{color: "red"}}> *</span></Label>
                <Input type="date" name="date" id="servicedate" placeholder="" onChange={e => setDate(e.target.value)} value={date} />
              </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="useremail">Email<span style={{color: "red"}}> *</span></Label>
                  <Input type="email" name="email" id="useremail" placeholder="" onChange={e => setEMail(e.target.value)} value={email}/>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="userphone">Phone Number<span style={{color: "red"}}> *</span></Label>
                  <Input type="text" name="phone" id="userphone" placeholder="" onChange={e => setPhone(e.target.value)} value={phone}/>
                </FormGroup>
              </Col>
            </Row>
              <FormGroup>
                <Label for="usernote">Note</Label>
                <Input type="textarea" name="note" id="usernote" placeholder="" onChange={e => setNote(e.target.value)} value={note}/>
              </FormGroup>
          </Col>
        </Row>
        <ButtonSecondary type="submit" onClick={submitContact}>Submit</ButtonSecondary>
      </Form>
      </div>

    </Container>
    </Section>
  </Layout>
)
}

export default DentalForm

import React from 'react';
import { withPrefix } from 'gatsby';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

import Logo3dgens from "../images/3DGENS_LOGO.png"

const cssAnimation = keyframes`
    // 0% {opacity: 100%;};
    // 50% {opacity: 50%;};
    // 100% {opacity: 0%;};
    100%{
        transform: scale(0.5) translate(90%, 90%);
        line-height: 200%;
    }
`

const VideoFrame = styled.div`
    position: relative;
    width: 100%;
    height: 65vh;
`
const ContentDiv = styled.div`
    position: absolute;
    display: inline-block;
    transform: scale(0.7);
    left:70%;
    top:70%;
    img {
        width: 100%;
        height: auto;
        margin: 0;
    }
    p {
        margin: 0;
        color: #EC8C56;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
    }
`

export default ({ src }) => {
    return (
        <VideoFrame>
            <video
            // height="100%"
            // width="100%"
            loop
            muted
            autoPlay
            style={{
                // position: 'absolute',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                height: '100%',
                width: '100%',
                zIndex: '-1',
                objectFit: 'cover',
                objectPosition: 'center'
            }}
            src={withPrefix(src)}
            >
                {/* <source src={withPrefix(src)} type="video/mpeg" /> */}
                <source src={withPrefix(src)} type="video/mp4" />
                Your device does not support playing 'video/mp4' videos
            </video>
            {/* <ContentDiv>
                <img src={Logo3dgens}></img>
                <p>Driving Innovation</p>
            </ContentDiv> */}
        </VideoFrame>
    )
}
import React, { useRef } from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import Moment from "react-moment";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";
import styled from "styled-components";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

const CompanyEvents = [
    {
        title: "Mandibular Reconstruction: 3D Virtual Planning & Simulation",
        date: "13 July 2022",
        time: "8.00AM - 5.00PM",
        venue: "Kherman Shah Learnin Space, Unit Bedah Mulut, Jabatan Sains Klinikal Mulut dan Maksilofasial, Fakulti Pergigian, Universiti Malaya",
        link: "",
    },
    {
        title: "IKBN Wakaf Tapai Exhibiton",
        date: "16 July 2022",
        endDate: "17 July 2022",
        time: "8.00AM - 5PM",
        venue: "IKBN Wakaf Tapai, 21040 Marang, Terengganu",
        link: "",
    },
    {
        title: "Installation, Commissioning & Training Metal Printer Enavision 120 at UTeM",
        date: "25 July 2022",
        endDate: "30 July 2022",
        time: "10.00AM - 5.00PM",
        venue: "Universiti Teknikal Malaysia Melaka, 76100 Durian Tunggal, Melaka",
        link: "",
    },
    {
        title: "Ogos MTDC R2G Kuantan Tour",
        date: "22 August 2022",
        endDate: "23 August 2022",
        time: "9.00AM - 6.00PM",
        venue: "The Zenith Hotel, Jalan Putra Square 6, Putra Square, 25200 Kuantan, Pahang",
        link: "",
    },
    {
        title: "Penang International Neurosurgical Congress",
        date: "26 August 2022",
        endDate: "27 August 2022",
        time: "7.00AM - 6.00PM",
        venue: "E&O Hotel, Georgetown Heritage City, Penang",
        link: "",
    },
]
const SliderBox = styled.div`
    padding: 30px;
    border-radius: 25px;
    // height: 80vh;
    width: 400px;
    background-color: rgba(39, 199, 216, 0.5);
    .slick-track{
        // display: flex !important;
    }
    .slick-track{
        display: flex !important;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;
        height: 70vh !important;
    }
    .slick-slide{
        flex: 1 1 50%;
        height: 320px !important;
        background-color: #39dccf;
        border-radius: 25px;
        padding: 25px;
    }
`
const EventItem = styled.div`
    margin: 10px 0;
`
const ContentBox = styled.div`
    // padding: 25px;
    // border-radius: 25px;
    // background-color: #39dccf;
    display: flex;
    flex-direction: row;
    p{
        margin: 0;
    }
`
const DateBox = styled.div`
    flex: 1 1 25%;
    line-height: 250%;
    text-align: center;
    .dayDate{
        font-size: 60px;
    }
    .monthDate{
        font-size: 30px;
        font-weight: bold;
        text-transform: uppercase;
    }
    hr{
        background-color: #dc3998;
        opacity: 50%;
    }
`
const TextBox = styled.div`
    flex: 1 1 75%;
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    // font-weight: light;
    .eventTitle{
        font-size: 24px;
        font-weight: lighter;
        margin-bottom: 5px;
        line-height: 100%;
    }
    .eventDetail{
        color: #dc3998;
        font-size: 12px;
        font-weight: bold;
    }
`
const SliderButtons = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 10px;
    background-color: #1EAA9F; 
    // background-color: transparent;
    border-radius: 15px;
    button{
        flex: 1;
    }
`
const ButtonSlider = styled.button`
    padding: 4px 24px;
    margin: 0;
    background-color: #1EAA9F; 
    // background-color: #ccc;
    border-radius: 15px;
    color: black;
    border: none;
    font-size: 32px;

    :hover{
        background-color: rgba(50,50,50,0.5);
        color: #17a99e;
    }
`

const VerticalSLider = ({}) => {

    const sliderRef = useRef();

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const prevSlide = () => {
        sliderRef.current.slickPrev();
    };

    const sliderSetting = {
        dots: false,
        arrows: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        adaptiveHeight: false,
    };
    return (
        <>
        <Default>
            <SliderBox>
                <h2 style={{textAlign: "center", marginBottom: "20px"}}>Upcoming Events</h2>
                <Slider {...sliderSetting} ref={sliderRef}>
                    {CompanyEvents.map((eventitem) => ( 
                        <EventItem>
                            <ContentBox>
                            <DateBox>
                                <p className="dayDate">
                                <Moment format="DD" date={eventitem.date} />
                                {/* { eventitem.endDate ? (<> - <Moment format="DD" date={eventitem.endDate} /></>) : ("") } */}
                                </p>
                                <p className="monthDate">
                                <Moment format="MMM" date={eventitem.date} />
                                </p>
                            </DateBox>
                            <TextBox>
                                <p className="eventTitle">{eventitem.title}</p>
                                <p className="eventDetail">
                                    <Moment format="DD" date={eventitem.date} />
                                    { eventitem.endDate ? (<> - <Moment format="DD" date={eventitem.endDate} /></>) : ("") }
                                    <Moment format=" MMMM YYYY" date={eventitem.date} />
                                </p>
                                <p className="eventDetail">{eventitem.time}</p>
                                <p className="eventDetail">@ {eventitem.venue}</p>
                            </TextBox>
                            </ContentBox>
                        </EventItem>
                    ))}
                </Slider>
                <SliderButtons>
                    <ButtonSlider onClick={prevSlide}><FaArrowUp/></ButtonSlider>
                    <ButtonSlider onClick={nextSlide}><FaArrowDown/></ButtonSlider>
                </SliderButtons>
            </SliderBox>
        </Default>
        </>
    )
}

export default VerticalSLider

import React, { useRef } from "react";
import Carousel from "nuka-carousel";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { Link } from "gatsby"

import aoral from "../images/scanner/aoralscanner.jpg";
import eins from "../images/scanner/einscanpro.jpg";
import free from "../images/scanner/freescan.jpg";
import auto from "../images/scanner/autoscan.jpg";
import inspec from "../images/scanner/inspec.png";
import trak from "../images/scanner/FreeScanTrak.png";
import optim from "../images/scanner/OptimScan.png";
import robot from "../images/scanner/RobotScanE0505.png";



import scanbanner from "../images/scanner/banner-scanner.png"

import { FaPlay, FaArrowRight, FaFacebookSquare, FaInstagram, FaArrowLeft } from "react-icons/fa";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

const scannerDetails = [
  {
    image : free,
    title : "FreeScan UE Laser Handheld Scanner",
    description: "Blue laser handheld 3D scanner of the FreeScan UE features of high accuracy and high precision result, ergonomic and lightweight equipment design",
  },
  {
    image : trak,
    title : "FreeScan Trak & FreeTrak Probe",
    description: "The optical tracker is capable of capturing the spatial position of the scanner structure in real time, offering a portable, efficient, and reliable 3D measurement solution. While, the portable wireless CMM probing system ensures reliable measurements in shop floor and any other challenging industrial environments.",
  },
  {
    image : eins,
    title : "EinScan Pro HD",
    description: "Multi-functional and modular design of EinScan Pro series handheld 3D scanner, delivers unparalleled performance in capturing high resolution and accuracy.\n It is reliable assistant for designers and engineers who care about high quality 3D modeling.",
  },
  {
    image : aoral,
    title : "Aoralscan 3 Intraoral Scanner",
    description: "Aoralscan 3 is an Brand New Design of the Intraoral Scanner which features Fast Speed, High Accuracy, Powerful AI Process and Intuitive Communication.\n It is suitable for various clinical scenarios including acquiring scan bodies and periodontal scans",
  },
  {
    image : auto,
    title : "AutoScan-DS-EX Pro",
    description: "Multi-functional 3D dental scanner independantly developed and manufactured by SHINING 3D, specializing in data capturing for dental restoration, orthodontics and implantology.",
  },
  {
    image : inspec,
    title : "AutoScan Inspec",
    description: "AutoScan lnspec is a fully automatic desktop 3D inspection scanner that innovative integrates high-accuracy 3D scanning and 3D inspection",
  },
  {
    image : optim,
    title : "OptimScan-5M",
    description: "OptimScan 5M is the ideal companion for a fully automated, machine driven shopﬂ oor environment. It´s ﬂ exible structure allows to use it on a ﬁ xed tripod as well as in conjunction with robotic systems.",
  },
  {
    image : robot,
    title : "ROBOTSCAN E0505",
    description: "SHINING 3D´s intelligent robot automatic 3D inspection system, RobotScan E0505, integrates the blue light high-accuracy industrial 3D scanning technology with the unique intelligent inspection technology to complete the whole scanning process through “one click”, thus greatly improving the inspection efficiency.",
  },
]

const ButtonCarousel = styled.button`
  flex: 1;
  padding: 4px 24px;
  margin: 0;
  /* background-color: rgba(0,0,0,0.5); */
  background-color: transparent;
  color: black;
  border: none;
  font-size: 32px;

  :hover{
    /* background-color: rgba(50,50,50,0.5) */
    color: #17a99e;
  }
`
const CarouselBox = styled.div`
  // height: 40vh;
  width: 100%;
  margin: 25px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .flexbuttons{
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`
const ContentBox = styled.div`
  // height: 40vh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: all .5s;
  h5{
    margin-top: 5px;
  }
  @media only screen and (max-width: 1024px){
    height: 300px;
  }
  @media only screen and (max-width: 800px){
    height: 200px;
  }
`

const ProductItem = styled.div`
  height: fit-content;
  margin: 0 15px;
  padding: 5px;
  text-align: center;
  .scannerimage{
    width: 250px;
    height: 250px;
    object-fit: contain;
  }
  p{
    text-align: left;
  }
  .link{
    color: #000;
    :hover{
      text-decoration: none;
      // color: #19a99e;
      .innercontent{
        opacity: 1;
      }
    }
  }
`

const ProductCarousel = ({data}) => {
  const carouselRef = useRef();

  const nextCarousel = event => {
    event.preventDefault();
    carouselRef.current.nextSlide();
  }
  const prevCarousel = event => {
    event.preventDefault();
    carouselRef.current.previousSlide();
  }
    return (
      <div>
        <Default>
          <CarouselBox>
            <Carousel 
            ref={carouselRef}
            slidesToShow={3}
            slidesToScroll={1}
            // renderCenterLeftControls={({ previousSlide }) => (<ButtonCarousel onClick={previousSlide}><FaArrowLeft/></ButtonCarousel>)} 
            // renderCenterRightControls={({ nextSlide }) => (<ButtonCarousel onClick={nextSlide}><FaArrowRight/></ButtonCarousel>)} 
            width="100%" 
            // height="100%" 
            dragging={false} 
            pauseOnHover={true}
            withoutControls={true} 
            autoplay={true} 
            wrapAround={true} 
            swiping={true}>
              {scannerDetails.map((scannerDetails, index) => ( 
                <ProductItem>
                <Link className="link" to={scannerDetails.link}>
                  <h5>
                    {scannerDetails.title}
                  </h5>
                  <img src={scannerDetails.image} className="scannerimage" />
                  <p>{scannerDetails.description}</p>
                  {/* <ContentBox className="contentboxclass" style={{padding: 0, borderWidth: 0, boxSizing: "border-box", position: "relative", backgroundImage: `url(${scannerDetails.image})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', }}>
                    <div className="innercontent">
                      <p>
                        {scannerDetails.description}
                      </p>
                    </div>
                  </ContentBox> */}
                </Link>
                </ProductItem>
              ))}
            </Carousel>
            <div className="flexbuttons">
              <ButtonCarousel onClick={prevCarousel}><FaArrowLeft/></ButtonCarousel>
              <ButtonCarousel onClick={nextCarousel}><FaArrowRight/></ButtonCarousel>
            </div>
          </CarouselBox>
        </Default>

        <Mobile>
          <CarouselBox>
            <Carousel 
            ref={carouselRef}
            slidesToShow={1}
            slidesToScroll={1}
            // renderCenterLeftControls={({ previousSlide }) => (<ButtonCarousel onClick={previousSlide}><FaArrowLeft/></ButtonCarousel>)} 
            // renderCenterRightControls={({ nextSlide }) => (<ButtonCarousel onClick={nextSlide}><FaArrowRight/></ButtonCarousel>)} 
            width="100%" 
            // height="100%" 
            dragging={false} 
            pauseOnHover={true}
            withoutControls={true} 
            autoplay={true} 
            wrapAround={true} 
            swiping={true}>
              {scannerDetails.map((scannerDetails, index) => ( 
                <ProductItem>
                <Link className="link" to={scannerDetails.link}>
                  <h5>
                    {scannerDetails.title}
                  </h5>
                  <img src={scannerDetails.image} className="scannerimage" />
                  <p>{scannerDetails.description}</p>
                  {/* <ContentBox className="contentboxclass" style={{padding: 0, borderWidth: 0, boxSizing: "border-box", position: "relative", backgroundImage: `url(${scannerDetails.image})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', }}>
                    <div className="innercontent">
                      <p>
                        {scannerDetails.description}
                      </p>
                    </div>
                  </ContentBox> */}
                </Link>
                </ProductItem>
              ))}
            </Carousel>
            <div className="flexbuttons">
              <ButtonCarousel onClick={prevCarousel}><FaArrowLeft/></ButtonCarousel>
              <ButtonCarousel onClick={nextCarousel}><FaArrowRight/></ButtonCarousel>
            </div>
          </CarouselBox>
        </Mobile>
      </div>
    )
}

export default ProductCarousel
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import gens3d from "../images/3DGENS_LOGO.png"
import { Container, Row, Col } from "reactstrap"
import fb from "../images/facebook.png";
import tw from "../images/twitter.png";
import ig from "../images/instagram.png";
import footerbg from "../images/banner/FOOTERBACKGROUND-10.jpg";
import icon01 from "../images/icon/ICONFOOTER-09.png"
import icon02 from "../images/icon/ICONFOOTER-10.png"
import icon03 from "../images/icon/ICONFOOTER-11.png"
import { FaMobileAlt, FaEnvelope, FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";

const Footerwrap = styled.footer`
// margin-top: 30px;
background-color: #000;  
`
const FooterContent = styled.div`
background-image: url(${footerbg});
margin: 0;
padding-top: 60px;
border-top: 1px solid #aaa;
`
const Logo = styled.div`
  margin-bottom: 30px;
  text-align: center;
`
const LogoFooter = styled.img`
  width: 360px;
  height: auto;
  /* margin-left: 30px; */
  margin-bottom: 0%;
`
const FooterLast = styled.footer`
  padding-top: 5px;
  margin-top: 5px;
  text-align: center;
  font-size: 10px;
  border-top: solid 1px #222;
  color: #bababa;
  background-color: #000;
  p{
    margin: 0;
  }
`
const Subs = styled.div`
  width: 40%;
  margin: 0;
`
const Contact = styled.div`
text-align: center;
margin-bottom: 30px;
h5{
  margin-top: 20px;
  /* padding-bottom: 10px; */
  padding: 10px 0;
  text-align: center;
  color: #fff;
}
p{
  padding: 0 10px;
  text-align: center;
  color: #ccc;
  line-height: 10px;
  font-size: 16px;
  margin: 0;
}
`
const Address = styled.div`
h5{
  /* padding-bottom: 15px; */
  /* color: white; */
}
p{
  /* color: white; */
  text-align: left;
  line-height: 10px;
  font-size: 16px;
}
`
const Navfooter = styled.div`
  h5{
    padding-bottom: 10px;
    color: #fff;
  }
  h3{
    font-size: 20px;
    color: #fff;
    margin-bottom: 10px;
  }
  .footerLink{
    margin-bottom: 5px;
    padding: 0 5px;
    /* width: 40%; */
    flex-grow: 1;
    font-size: 16px;
    text-decoration: none;
    color: #ccc;
  }
  .footerLink:hover{
    text-decoration: none;
    color: #17A99E;
  }
  .addressDiv{
    color: #fff;
    p{
      font-size: 16px;
      line-height: 24px;
    }
  }
`
const LinkFooter = styled.div`
  display: flex;
  flex-flow: column;
  // justify-content: center;
  /* flex-wrap: wrap; */
`
const Social = styled.div`
  border-top: 1px #aaa solid;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  font-size: 24px;
  span{
    margin-left: 10px;
    font-size: 30px;  
    a{
      color: #fff;
      margin-right: 5px;
    }
    a:hover{
      color: #ccc;
      text-decoration: none;
    }
  }
`
const ContactBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  .icon{
    // width: 50px
    text-align: center;
    img{
      width: 40px;
    }
  }
  .text{

  }
`


const Footer = () => (
  <Footerwrap>
    <FooterContent>
    <Container>
      <Row>
        <Col xs="12" md="12">
          <Navfooter>
            <Link to="/">
              <Logo>
                <LogoFooter src={gens3d} />
              </Logo>
            </Link>
          </Navfooter>
        </Col>
        <Col xs="0" md="1" />
        <Col xs="12" md="2">
          <Navfooter>
            <h3>
            Navigation
            </h3>
            <LinkFooter>
              <Link className="footerLink" to="/about">About Us</Link>
              <Link className="footerLink" to="/stories">News</Link>
              <Link className="footerLink" to="/contact">Contact</Link>
            </LinkFooter>
          </Navfooter>
        </Col>
        <Col xs="12" md="2">
          <Navfooter>
            <h3>
              Products
            </h3>
            <LinkFooter>
              <Link className="footerLink" to="/metalprinter">Metal 3D Printers</Link>
              <Link className="footerLink" to="/polyprinter">Polymer 3D Printer</Link>
              <Link className="footerLink" to="/metalmaterial">Metal Metarial</Link>
            </LinkFooter>
          </Navfooter>
        </Col>
        <Col xs="12" md="2">
          <Navfooter>
            <h3>
              Innovations
            </h3>
            <LinkFooter>
              <Link className="footerLink" to="/digital-dentistry">Digital Dentistry</Link>
              <Link className="footerLink" to="/training">Training & Consultation</Link>
              <Link className="footerLink" to="/research">Research & Development</Link>
            </LinkFooter>
          </Navfooter>
        </Col>
        <Col xs="12" md="4">
          <Navfooter>
            <div className="addressDiv">
            <ContactBox>
              <div className="icon">
                <img src={icon01} />
              </div>
              <div className="text">
                <p>
                  No.18, Jalan Kerawang u8/108,<br/>
                  Perindustrian Tekno Jelutong,<br/>
                  Seksyen U8, 40150 Shah Alam,<br/>
                  Selangor Darul Ehsan  
                </p>
              </div>
            </ContactBox>
            <ContactBox>
              <div className="icon">
                <img src={icon02} />
              </div>
              <div className="text">
                <p>
                  +603 7832 7140 (Office)<br/>
                </p>
              </div>
            </ContactBox>
            <ContactBox>
              <div className="icon">
                <img src={icon02} />
              </div>
              <div className="text">
                <p>
                  +601 0558 3070 (Enginnering)<br/>
                </p>
              </div>
            </ContactBox>
            <ContactBox>
              <div className="icon">
                <img src={icon03} />
              </div>
              <div className="text">
                <p>
                  info@3dgens.com<br/>
                </p>
              </div>
            </ContactBox><ContactBox>
              <div className="icon">
                <img src={icon03} />
              </div>
              <div className="text">
                <p>
                  3dprint@3dgens.com<br/>
                </p>
              </div>
            </ContactBox>
            </div>
          </Navfooter>
        </Col>
        <Col xs="0" md="1" />
        <Col xs="12" md="12">
        </Col>
      </Row>
    </Container>
      <div style={{marginTop: "30px"}}>
        <Social>
          Follow us on 
          <span itemtype="https://schema.org/Organization">
            <link itemProp="url" href="https://3dgens.com"/>
            <a itemprop="sameAs" href="https://www.facebook.com/3dgensmalaysia/" target="_blank" rel="noopener noreferrer"><FaFacebookSquare/></a> 
            <a itemprop="sameAs" href="https://www.instagram.com/3dgens/" target="_blank" rel="noopener noreferrer"><FaInstagram/></a> 
            <a itemprop="sameAs" href="https://www.linkedin.com/company/3d-gens-sdn-bhd/" target="_blank" rel="noopener noreferrer"><FaLinkedin/></a>
          </span>
        </Social>
      </div>
    <FooterLast>
      © {new Date().getFullYear()}, 3D Gens
      {` `}
      <p>Knowledge . Technology . Expertise</p>
    </FooterLast>
    </FooterContent>
  </Footerwrap>
)

export default Footer

import React from 'react'
import styled from 'styled-components'
import Media from 'react-media'

const BannerWrap = styled.div`
  img{
    width: 100%;
    height: 50vh;
    object-fit: cover;
    object-position: top;
  }
`


const PageBanner = ({ children, classNamed, src}) => {
  return (
    <BannerWrap className={classNamed}>
      <div className='imageDiv'>
        <img src={src} />
      </div>
      {children ? ( {children} ): ""}
    </BannerWrap>
  )
}

export default PageBanner

import React from 'react'
import styled from 'styled-components'
import { FaArrowRight } from "react-icons/fa";
import { Card, CardBody, CardText } from "reactstrap";

const ContentBox = styled.div`
  border: 1px solid #17a99e;
  padding: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
`
const ContentBoxDisabled = styled.div`
  border: 1px solid #aaa;
  padding: 4px;
  display: flex;
  flex-direction: column;
  height: 100%;
`
const ContentImage = styled.div`

  iframe{
    width: 100%;
    height: 300px;
    /* object-fit: cover; */
    margin: 0;  
  }
  img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin: 0;
  }
  p{
    font-size: 16px;
    font-weight: bold;
    /* text-align: center; */
  }
`
const ContentBody = styled(CardBody)`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100px;
  .storytitle{
    color: #235551;
    h6{
      overflow: hidden;
      min-height: 20px;
      margin: 0;
    }
  }
  .storydesc{
    min-height: 5px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    white-space: pre-line;
  }
  .storycontext{
  }
`

const CardBorder = ({ children, className, image, title, description, disable}) => {
  return (
    <Card className={className} style={{border: "none", marginBottom: "30px"}}>
      {disable ? (
        <ContentBoxDisabled>
          <ContentImage>
            <img src={image}></img>
          </ContentImage>
          <ContentBody style={{flexGrow: 1}}>
            <div className="storytitle">
              <h6 className={CardText}>
                <strong >
                  {title}
                </strong><br />
              </h6>
            </div>  
            <span className="storydesc">
              {description}
            </span>
            {children}
          </ContentBody>
        </ContentBoxDisabled>
      ) : (
        <ContentBox>
          <ContentImage>
            <img src={image}></img>
          </ContentImage>
          <ContentBody style={{flexGrow: 1}}>
            <div className="storytitle">
              <h6 className={CardText}>
                <strong >
                  {title}
                </strong><br />
              </h6>
            </div>  
            <span className="storydesc">
              {description}
            </span>
            {children}
          </ContentBody>
        </ContentBox>
      )}
    </Card>
  )
}

export default CardBorder

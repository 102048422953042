import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TrainingAMBody from "../components/AMTrainingBody"

import Banner from "../images/training/AMModules/AMDesigner/01BANNER-11.png";
import icon from "../images/training/AMModules/AMDesigner/ICONIAMQS-03.png";
import image from "../images/training/AMModules/AMDesigner/img01.jpg";

const titleBody = ( <h2> INTERNATIONAL DESIGNER FOR PB PROCESSES  </h2> )
const longtitleBody = ( <p>DESIGNER FOR PBF PROCESSES (I MAM D-PBF)</p>)
const titleDescBody = ( <p> Increase your competences and skills by acquiring a professional qualification in a growing sector. this qualification will give you advanced knowledge and critical Understanding of the theory, principle and apllicability of metal additive manufacturing design for different processes. </p> )
const insightdetails = [
  "Design Metal AM solutions for PBF Processes ensuring and validating that parts can be made cost-effective and efficiently",
  "Close PBF Processes design projects by verifying requirements for production with engineer as well as process requirements, ensuring liaison with other technical areas to sign of drawings",
  "Contribute to projects in a teaming enviroment cooperation with AM Team"
]
const groupdetails = ( 
  <p>
    This Training programme is ideal for engineers willing to specialise and pursue a career in AM with focus on designing metal AM parts for PBF processes
  </p>
)
const benefitdetails = [
  "Understanding of technical of principles and applicability of metal addtive manufacturing design for PBF Processes",
  "State-Of-The-Art knowledge, skills and competence regarding PBF Additive Manufacturing processes",
  "An international recognised qualification which increases employability in the labour market, through mobility and skills transparency",
]


const TrainingAMDesignerPage = () => (
  <Layout>
    <SEO title="AM Designer" description="AM Designe (I AM D-PBF)" />
    
    <TrainingAMBody
      Banner={Banner}
      Title={titleBody}
      TitleDesc={titleDescBody}
      LongTitle={longtitleBody}
      PositionDetails="These training programmes are ideal for Engineers who are willing to specialise and pursue a career in AM, with focus on designing metal AM parts for different processes"
      PositionDetails2="International Metal AM Designers for PBF Processes are professionals with specific knowledge, skills, autonomy and resbonsibility to design metal AM solutions for PBF Processes."
      PositionImg={image}
      PositionIcon={icon}
      InsightDetails={insightdetails}
      GroupDetails={groupdetails}
      BenefitsDetails={benefitdetails}
    />

  </Layout>
)

export default TrainingAMDesignerPage

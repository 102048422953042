import React, { useState } from "react"
import { Container } from "reactstrap";
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Banner"
import Section from "../components/Section"
// import "./popup.css"

import career from "../images/banner/bannerCareer.png";
import { FaConciergeBell } from "react-icons/fa";
import Popup from "reactjs-popup";
import MarkdownIt from "markdown-it";

const PageHeight = styled.div`
  padding: 60px 0;
  min-height: 80vh;
  h4{
    text-align: left;
  }
`
const CareerBox = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  .boxItem{
    flex: 0 0 33%;
    padding: 10px;
    border: solid 1px #ccc;
    display:flex;
    flex-direction: row;
    .boxIcon{
      flex: 0 0 20%;
      text-align: center;
      font-size: 40px;
    }
    .boxTitle{
      flex: 1 1 50%;
      padding: 5px  ;
      /* display: flex; */
      /* align-content: center; */
      h5{
        margin: 0;
      }
      vertical-align: middle;
    }
    :hover{
      background-color: #17a99e;
      color: #fff;
    }
  }
`
const JobDesc = styled.div`
  /* padding: 20px; */
  h1{
    text-align: center;
    margin-bottom: 20px;
  }
  .modal {
    font-size: 12px;
    display: block;
    position: relative;
    height: fit-content;
    .header {
      background-color: #17a99e;
      color: #fff;
      width: 80%;
      border-bottom: 1px solid gray;
      font-size: 24px;
      text-align: center;
      padding: 10px;
      position: fixed;
    }
    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 10px 10px;
    }
    .close {
      cursor: pointer;
      position: fixed;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: 12%;
      top: 4%;
      font-size: 24px;
      background: #ffffff;
      border-radius: 18px;
      border: 1px solid #cfcece;
      z-index: 1;
    }
    .textDescription{
      padding: 15px 5px;
      padding-top: 60px;
      @media only screen and (max-width: 600px) {
        padding-top: 140px;
      }
    }
    .textRequirement{
      padding: 15px 5px;
    }
    .textInfo{
      padding: 15px 5px;
    }
  }
`

const md = new MarkdownIt()

const CareerPage = ({data}) => {
  // script
  return (
  <Layout>
    <SEO title="Career" />
    <Banner src={career} alt="banner">
    </Banner>
    <PageHeight>
      
      <Container>
        <h3>
          Career Opportunities
        </h3>
        <p>
          As a fast growing group, we are looking for qualified and competent talents for the following positions:
        </p>
        {data.allStrapiCareers.edges.map(career => (
          <Popup 
            className="popup-style"
            trigger={
              <CareerBox>
                <div className="boxItem">
                  <div className="boxIcon">
                    <FaConciergeBell/>
                  </div>
                  <div className="boxTitle">
                    <h5>
                      {career.node.position}
                    </h5>
                  </div>
                </div>
              </CareerBox>
            }
            modal
            closeOnDocumentClick
            contentStyle = {{ width: "80%", height: "95%", overflow: "auto", padding: "0" }}
          >
            {close => (
              <JobDesc>
                <div className="modal">
                <a className="close" onClick={close}>
                  &times;
                </a>
                <div className="header"> {career.node.position} </div>
                <div className="content">
                  <div className="textContent">
                    <div className="textDescription">
                      <h5>Job Description</h5>
                      <div dangerouslySetInnerHTML={{ __html: md.render(career.node.description) }}></div>
                    </div>
                    <div className="textRequirement">
                      <h5>Requirements</h5>
                      <div dangerouslySetInnerHTML={{ __html: md.render(career.node.requirement) }}></div>
                    </div>
                  </div>
                  <div className="textInfo">
                    <p>
                      For more information kindly
                    </p>
                    <p>
                      Contact us at 03-78327140 / 018-3976844(Ms Aida)
                    </p>
                    <p>
                      Our office is located at Bukit Jelutong.
                    </p>
                  </div>
                </div>
                </div>
              </JobDesc>
            )}
          </Popup>
        ))}
      </Container>
    </PageHeight>
  </Layout>
  )
}

export default CareerPage

export const query = graphql`
  query CareerQuery{
    allStrapiCareers{
      edges {
        node {
          id
          position
          description
          requirement
        }
      }
    }
  }
`



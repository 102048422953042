import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled from "styled-components";

import Layout from "../components/layout"
import SEO from "../components/seo"
import gens3d from "../images/3DGENS_LOGO.png"
import pipe from "../images/engineering.jpeg"
import design from "../images/design.png";
import polyprint from "../images/printedproduct.jpg";
import printing from "../images/printing.jpg";
import metal from "../images/engineering.jpg";
import printercloseup from "../images/printer.jpg";
import printed2dim from "../images/printed2dim.png";
import lisaP from "../images/lisa-printer.png";
import genceP from "../images/3dgence-printer.png";
import desktopP from "../images/desktop-printer.jpg";
import scan from "../images/scan.jpg"
import combed from "../images/combed.png";
import dynamic from "../images/dynamic.png";
import ok3d from "../images/3dokay.jpg";
import centric from "../images/photocentric.png";
import eng1 from "../images/engineeringStory1.jpg";
import eng2 from "../images/engineeringStory2.jpg";
import eng3 from "../images/engineeringStory3.jpg";
import eng4 from "../images/engineeringStory4.jpg";
import eng5 from "../images/engineeringStory5.jpg";
import scanObject from "../images/products/scanObject.jpg";
import poliProduct from "../images/products/poliProduct.jpg";
import fdmProduct from "../images/products/fdmmodel.jpg";
import metalProduct from "../images/products/metalProduct.jpg";
import metalProduct2 from "../images/products/metalProduct2.jpg";
import bannerengineering from "../images/banner/page-banner/banner-engineering.png";
import printed from "../images/products/3dPrinting.jpg";

import Banner from "../components/Banner";
import BackgroundSection from "../components/BackgroundSection";
import Section from "../components/Section";
import CardSimple from "../components/CardSimple";
import CardBorder from "../components/CardBorder";
import Link2Web from "../components/Link2Web";
import LinkhrefButtonPrimary from "../components/buttons/LinkhrefButtonPrimary";
import { FaArrowRight, FaFileExcel, FaBoxes, FaCubes } from "react-icons/fa";
import LinkButtonSecondary from "../components/buttons/LinkButtonSecondary";
import ButtonPlain from "../components/buttons/ButtonPlain";

const Desc = styled.div`
  padding: 20px 0;
  margin-bottom: 20px;
  .description{
    height: 300px;
  }
  .capability{
    height: 300px;
  }
  p{
    color: #000;
  }
  @media only screen and (max-width: 600px) {
    margin-bottom: 50px;
    .description{
      height: auto;
    }
    .capability{
      height: auto;
    }
  }
`
const Applications = styled.div`
  padding: 5px;
  img{
    width: 100%;
    height: 250px;
    display: block;
    margin: 0 auto;
    object-fit: cover;
  }
  .title{
    font-weight: bold;
    color: #235551;
  }
  .subtitle{
    font-weight: bold;
  }
`
const MyWeb = styled.div`
  text-align: center;
  color: #fff;
`
const StoryDiv = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;  
img{
  /* width: 100%; */
  flex-shrink: 1;
  height: 400px;
  padding: 5px;
  object-fit: cover;
}
`
const ContentBox = styled.div`
  margin: 4px;
  height: 300px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: #000; */
  /* border: 1px solid #ccc; */
  h5{
    margin-top: 5px;
  }

  &:hover,
  &:active{
    transform: scale( 1.05, 1.05);
    box-shadow: inset 0 0 10px #fff;
  }
  @media only screen and (max-width: 700px){
    height: 200px;
  }
`

const engineerings = [
  {
    title: "3D Printing Services",
    desc: 
    <>
      <p>
      3d gens offers wide range of 3d printers, materials and expertise to customers looking to expedite their product development. 
      Our team is dedicated to finding customised solutions for our clients. 
      Go to market fast with less cost. 
      Get instant online 3d printing pricing through <a className="link-inline" href="https://my3dpm.com/">my3dpm.com</a>.
      </p>
    </>,
    image: <FaCubes/>,
  },
  {
    title: "Small Batch productions",
    desc: 
    <>
      <p>
      Launching a new product nowadays requires a good marketing campaign and that's no news but when it comes to manufacturing it all boils down to flexibility, especially as a newly established business.
      </p>
      <p>
      Take advantage of 3D printing technology to eliminate tooling and molding costs, boost your productivity in record time with minimal expenses compered to traditional manufacturing methods. 
      </p>
    </>,
    image: <FaBoxes/>,
  },
]

const services = [
  {
    image : <img src={scanObject}></img>,
    title : "3D Scanning",
    desc : 
          <>
            <p>
            We provide comprehensive 3d scanning services, inspection and reverse engineering services throughout Malaysia & Indonesia
            </p>
            <p>
            Using the principle of triangulation, points of the surface are captured from different perspectives into a surface. 
            The output is a 3D scan area. 
            The scanned area model can be measured and converted to a solid model using specific surfacing software so that reverse engineering job can be accomplished
            {/* <ul>
              <li>
                Pushing the boundaries of what digital technology can achieve
              </li>
              <li>
                We provide comprehensive 3d scanning services, inspection and reverse engineering services throughout Malaysia & Indonesia
              </li>
              <li>
                Experts in laser scanning, inspection reports, reverse engineering, 3d printing and rapid prototyping
              </li>
            </ul> */}
            </p>
            {/* <p>
              Reverse engineering using 3D Scan data
            </p> */}
          </>,
  },
  {
    image : <img src={design}></img>,
    title : "3D Modelling",
    desc : 
          <>
            {/* <p>
              Let ur Architectural model close the deal for you!! Differentiate Your Business! Build Brand Awareness! Showcase Your Project with Style!! The Details that make the difference!! Made to Impress
            </p> */}
            <p>
              Starting from the 2D sketches or just an idea in your head, we modelled the development including surface, patterns, tiles and etc.
              3D Printing and digital workflow commissioning a model as cheaper then ever before with lead times that start from just a few days
            </p>
            {/* <p>
              Assist in making small changes on CAD designs
            </p> */}
          </>,
  },
  {
    image : <img src={printed}></img>,
    title : "3D Printing ",
    desc : 
          <>
            <p>
              Transform your CAD design to a physical piece in your hands
            </p>
            <p>
            The lead time for a high detail plastic and resin prototype is as fast as 24hr, something that would be just possible with the traditional manufacturing methods. 
            The advantages are not just from an economical point of view, working with a design studio in the early stages is a crucial step to understand which material and processes will be best suited for the product sparing us a lot of challenges down the line
            </p>
          </>,
  }
]

const engineeringstorys = [
  
  {
    image : eng2,
    text : "",
  },
  {
    image : eng3,
    text : "",
  },
  {
    image : eng4,
    text : "",
  },
  {
    image : eng5,
    text : "",
  },
]

const techs = [
  {
    image : fdmProduct,
    title : "Fused Deposition Modeling",
    benefit : 
    <>
      <ul>
        <li>
        Cheaper - FDM technology has proved extremely cost effective for one off prototypes, iterative design and low volume batch productions
        </li>
        <li>
        Quicker - No need to say this is where FDM excels, lead times for prototyping and presentation models are reduced significantly
        </li>
        <li>
        Stronger - This is the technology of choice for jigs and fixtures, manufacturing and industrial applications
        </li>
      </ul>
    </>,
    material : <p><i>PC-ABS</i> - A blend of ABS plastic and polycarbonate for the most demanding applications, recommended for parts subjected to mechanical stress</p>,
  },
  {
    image : poliProduct,
    title : "Selective laser sintering",
    benefit : 
    <>
      <p>
        SLS uses a CO2 laser that lightly fuses nylon-based powder, layer by layer, until thermoplastic parts are created. 
      </p>
      <p>
        SLS provides a fully functional and high resolution print.
      </p>
    </>,
    material : 
    <>
      <p>When selecting a material, consider the mechanical properties, manufacturability characteristics, cosmetic appearance, and cost.</p>
      <ul>
        <li>Thermoplastic</li>
        <li>metal</li>
        <li>elastomers</li>
      </ul>
    </>,
  },
  {
    image : metalProduct2,
    title : "Direct metal laser sintering",
    benefit : 
    <>
      <p>
        DMLS uses fiber laser system that draws onto a surface of atomized metal powder, welding the powder into fully dense metal parts.
      </p>
    </>,
    material : 
    <>
      <p>We offer numerous 3D Printing material options that are regularly updated to meet prototyping and master fabrication needs.</p>
      <ul>
        <li>Aluminium</li>
        <li>Cobalt Chrome</li>
        <li>Inconel</li>
        <li>Stainless Steel</li>
        <li>Titanium</li>
      </ul>
    </>,
  }
]

const postprocess = <>
                      <p>
                        We can offer multiple solutions to make your 3d printed parts look stunning. 
                        Thanks to our experience in the 3D Printing workshop.
                      </p>
                      <ul>
                        <li>
                          <b>Smoothing</b>
                            - This is the best option if you are looking for that mirror like finish keeping costs to a minimum. 
                            Although it is fairly common finishing process for 3d printed parts we suggest using it exclusively on small parts. 
                            For show models and parts where tight tolerances are not critical.
                        </li>
                        <li>
                        <b>Hand Sanding/Priming</b>
                            - The parts are sanded then coat of primer is applied to give the part a smooth professional uniform look. 
                            At this point a topcoat of the chosen colour can be applied to give the part that just out of factory look.
                        </li>
                        <li>
                        <b>Hand Sanding/ Bead blasting</b> 
                        
                        
                          - models are sanded to 600 grit then sand blasted to achieve uniform and even surface, perfect for show models, end user parts and prototypes
                        </li>
                      </ul>
                    </>
    

const printtypes = [
  {
    image :  poliProduct,
    title : "3D Plastic/polymer Printing",
    desc : "Selective laser sintering (SLS) uses a CO2 laser that lightly fuses nylon-based powder, layer by layer, until thermoplastic parts are created. SLS provides a fully functional and high resolution print.",
    cap : "Materials including multiple grades of thermoplastics, metals, and elastomers. When selecting a material, consider the mechanical properties, manufacturability characteristics, cosmetic appearance, and cost.",
  },
  {
    image :  metalProduct2,
    title : "3D Metal Printing",
    desc : "Direct metal laser sintering (DMLS) uses a fiber laser system that draws onto a surface of atomized metal powder, welding the powder into fully dense metal parts.",
    cap : "We offer numerous 3D Printing material options that are regularly updated to meet prototyping and master fabrication needs. Our DMLS selection includes aluminium, cobalt chrome, inconel, stainless steel, and titanium",
  },
]

const EngineeringPage = () => (
  <Layout>
    <SEO title="Engineering" />
    <Banner src={bannerengineering} alt="banner">
      <h3>Engineering Applications</h3>
      <p>
        {/* Creating Creations Beyond Prototyping Technology */}
        Let your Engineering models close the deal for you<br/>
        {/* Differentiate Your Business!  */}
        {/* Build Brand Awareness!<br/> */}
        {/* The Details that make the difference!! Made to Impress!! */}
      </p>
    </Banner>
    <Section>
      <Container>
        <h3>Engineering Applications</h3>
          {engineerings.map((item) => (
            <div style={{display: "flex",}}>
              <div style={{fontSize:"60px", margin: "0 15px"}}>
                {item.image}
              </div>
              <div>
                <h5 style={{color:"#235551"}}>
                  {item.title}
                </h5>
                <p>
                {item.desc}
                </p>
              </div>
            </div>
          ))}
      </Container>
    </Section>
      <Container>
        <h3>Product & Services</h3>
        <Row>
          {services.map((service) => (
            <Col md="4">
              <CardSimple justifytext image={service.image} title={service.title} description={service.desc}></CardSimple>
            </Col>
          ))}
        </Row>
      </Container>
        <Container>
          <h3 style={{paddingTop:"15px"}}>3D Printing Technology</h3>
          <Applications>
            <Row>
              {techs.map((type) => (
                <Col md ="4">
                  <img src={type.image} alt=""></img>
                  <Desc>
                  <p className="title">{type.title}</p>
                  <p className="subtitle">Advantages</p>
                  <p className="description">
                    {type.benefit}
                  </p>
                  <p className="subtitle">Materials</p>
                  <p className="capability">
                    {type.material}
                  </p>
                  </Desc>
                </Col>  
              ))}
            </Row>
            <h5>Post Processing</h5>
            {postprocess}
          </Applications>
        </Container>
    <div style={{backgroundColor: "#d8d8d8", marginTop:"30px"}}>
      <div style={{backgroundImage: `url(${combed})`, padding: "60px 0",}}>
      <StoryDiv>
        {engineeringstorys.map((estory) => (
          <ContentBox style={{padding: 0, borderWidth: 0, boxSizing: "border-box", position: "relative", backgroundImage: `url(${estory.image})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', }}>
          </ContentBox>
            ))}
      </StoryDiv>
      </div>
    </div>
    <div style={{padding: "20px", backgroundColor: "rgb(35, 85, 81)"}}>
      <Container>
        <MyWeb>
          <p>
            Subscribe and Print your own personalize 3D Design.
          </p>
          <LinkhrefButtonPrimary href="https://my3dpm.com/">
            my3DPM <FaArrowRight />
          </LinkhrefButtonPrimary>
        </MyWeb>
      </Container>
    </div>
    
  </Layout>
)

export default EngineeringPage
